<template>
  <div class="checkout-container">
    <h1 class="checkout-header">Process Checkout</h1>
    <div class="checkout-content">
      <div class="left-panel-checkout">
        <div class="address-instruction-container">
          <div class="address-container">
            <p class="address-header">Address form</p>
            <form class="address-form">
              <div class="form-input-group">
                <select id="homeType" v-model="address.homeType" required>
                  <option value="" disabled selected>Select Home Type</option>
                  <option value="Home">Home</option>
                  <option value="Apartment">Apartment</option>
                  <option value="Office">Office</option>
                </select>
              </div>
              <div class="form-input-group">
                <input type="text" id="streetAddress" v-model="address.streetAddress" placeholder="Street Address"
                  required autofocus maxlength="34" />
              </div>
              <div class="form-input-group">
                <input type="text" id="apartmentNumber" v-model="address.apartmentNumber" placeholder="Apt No." required
                  autofocus maxlength="16" />
              </div>
              <div class="form-input-group">
                <input type="text" id="city" v-model="address.city" placeholder="City" required autofocus
                  maxlength="20" />
              </div>
              <div class="form-input-group">
                <input type="text" id="state" v-model="address.state" placeholder="State" required autofocus
                  maxlength="20" />
              </div>
              <div class="form-input-group">
                <input type="text" id="zipCode" v-model="address.zipCode" placeholder="Zip Code" required autofocus
                  maxlength="5" />
              </div>
            </form>
          </div>
          <div class="instruction-container">
            <p class="instruction-header">Check box to add Custom note and picture</p>
            <form class="instruction-form">
              <div class="customization-checkbox">
                <input type="checkbox" id="customizationCheckbox" v-model="showCustomization"
                  @change="handleCustomizationChange" />
                <label for="customizationCheckbox">Customization</label>
              </div>
              <div v-if="showCustomization">
                <div class="upload-container">
                  <label for="imageFile" class="upload-label">
                    <span class="upload-icon">
                      <i class="fas fa-cloud-upload-alt"></i>
                    </span>
                    <span class="upload-text">{{ uploadedImageName || 'Upload Image' }}</span>
                  </label>
                  <input type="file" id="imageFile" ref="imageFile" accept="image/*" @change="handleImageSelection"
                    class="upload-input" required autofocus />
                </div>
                <div>
                  <textarea type="text" id="instruction" v-model="instruction" placeholder="Custom Instruction" required
                    autofocus maxlength="216"></textarea>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="order-summary">
        <p v-if="cartItems.length > 0"><strong>Order Summary:</strong></p>
        <div v-if="successMessage" class="checkout-success-message"><i class="fa-solid fa-circle-check"></i> {{
          successMessage }}</div>
        <div v-if="cartItems.length === 0 && customOrder.price <= 0" class="empty-checkout-message">
          <p><strong>No order summary yet.</strong></p>
          <p>Looks like you have not <br>added anything to your<br> cart yet. Go ahead and <br>explore top categories.</p>
          <button class="continue-shopping-checkout" @click="$router.push({ name: 'main-page' })">Continue
            Shopping</button>
        </div>
        <ul>
          <li v-for="item in cartItems" :key="item.id" class="order-item">
            <div class="details">
              <p><strong>Name:</strong> {{ item.name }}</p>
              <p><strong>Price:</strong> ${{ item.price }}</p>
              <p><strong>Quantity:</strong> {{ item.quantity }}</p>
            </div>
          </li>
          <li class="order-item">
            <div v-if="customOrder.price > 0" class="details">
              <p><strong>Custom Order Details</strong></p>
              <p><strong>Order Name:</strong> {{ customOrder.name }}</p>
              <p><strong>Image:</strong> {{ customOrder.file }}</p>
              <p><strong>Price: </strong> ${{ customOrder.price }}</p>
              <p><strong>Instruction:</strong> {{ customOrder.instructions }}</p>
            </div>
          </li>
        </ul>
        <div v-if="cartItems.length > 0 || customOrder.price > 0" class="cart-total">
          <p>Product Total: ${{ calculateTotal() }}</p>
        </div>
        <div v-if="paymentFailed" class="error-message">
          <p><i class="fa-sharp fa-solid fa-xmark"></i> Payment Failed. Please try again.</p>
        </div>
      </div>

    </div>
    <div class="delivery-toggle">
      <p class="delivery-options">{{ isDelivery ? 'Pickup' : 'Delivery' }}</p>
      <label class="toggle">
        <input type="checkbox" v-model="isDelivery" class="toggle-input" />
        <span class="slider round"></span>
      </label>
    </div>
    <div class="pay-injection">
      <p class="pay-text">Pay with card</p>
      <div>
        <form id="payment-form" @submit.prevent="processPayment">
          <div id="card-element" class="card-element"></div>
          <button type="submit" class="pay-place-order">Place Your Order</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';
import { mapActions } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import he from 'he';
export default {
  name: 'process-checkout',
  data() {
    return {
      cartItems: [],
      address: {
        homeType: '',
        streetAddress: '',
        apartmentNumber: '',
        city: '',
        state: '',
        zipCode: '',
        addressId: 0
      },
      customerOrder: {
        orderType: '',
        orderCost: 0,
        orderStatus: '',
        addressId: 0,
        employeeId: 0,
        orderDate: '',
        orderId: 0,
        userId: 0,
        productId: 0,
      },
      customOrder: {
        file: '',
        price: 0,
        instructions: '',
        name: ''
      },
      isDelivery: false,
      successMessage: '',
      totalAMount: 0,
      stripe: null,
      cardElement: null,
      clientSecret: '',
      uploadedImageName: '',
      maxFileSize: 200 * 1024, //5 * 1024 * 1024, // 5 MB
      file: null,
      instruction: '',
      showCustomization: false,
      paymentFailed: false,
    }
  },
  created() {
    const userId = this.$store.state.user.id;
    const { totalPrice, file, instructions, name } = this.$route.query;

    this.customOrder.file = file ? file.name : '';
    try {
      this.customOrder.price = parseFloat(totalPrice) || 0;
    } catch (error) {
      console.log('Error parsing total price:', error);
      this.customOrder.price = 0;
    }
    this.customOrder.instructions = instructions;
    this.customOrder.name = name;

    AuthService.getCartItems(userId)
      .then((response) => {
        this.cartItems = response.data.map((cartItem) => ({
          ...cartItem,
          userId: userId,
        }));
        this.updateCartItemsCount();
      })
      .catch((error) => {
        console.log('Error fetching cart items:', error);
      });
  },

  mounted() {
    // Load Stripe and initialize the card element
    this.loadStripeClient();
  },
  computed: {
    cartItemsCount() {
      return this.$store.state.cartItemsCount;
    },
    isInputInvalid() {
      return this.address.zipCode.length > 5;
    }
  },
  cartItemsWatcher() {
    return this.cartItems;
  },
  watch: {
    cartItems: {
      handler(newVal) {
        console.log(newVal);
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions(['clearCart']), // Map the submitOrder action from Vuex to a local method
    sanitize(value) {
      if (typeof value !== 'string') {
        return value;
      }

      const encodedValue = value
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;');

      return he.encode(encodedValue);
    },
    handleImageSelection(event) {
      const file = event.target.files[0];
      if (file) {
        if (file.size > this.maxFileSize) {
          this.$refs.imageFile.value = '';
          this.uploadedImageName = '';
          this.showAlert('Your image size is larger than 200KB. Please choose a smaller image.');

          setTimeout(() => {
            this.removeAlertMessage();
          }, 6000);
        } else {
          this.uploadedImageName = file.name;
          this.file = file; // Assign the selected file to this.file
        }
      } else {
        this.uploadedImageName = '';
        this.file = null; // Set this.file to null when no file is selected
      }
    },

    handleCustomizationChange() {
      if (this.showCustomization) {
        this.uploadImage();
      }
    },
    uploadImage() {
      if (!this.file) {
        alert("Be sure to select a file");
        return;
      }
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("userId", this.$store.state.user.id);
      formData.append("instruction", this.instruction);

      fetch(`https://jewelrynepal-88e76a13f73a.herokuapp.com/api/custom-upload?userId=${this.userId}`, {
        method: "POST",
        body: formData
      })
        .then(response => response.text())
        .then(result => {
          this.showSuccessMessage(result);

          this.instruction = '';
          this.file = null;
          this.uploadedImageName = '';

          setTimeout(() => {
            this.removeSuccessMessage();
          }, 10000);
        })
        .catch(error => {
          console.error("Failed to upload image", error);
          alert("Failed to upload image");
        });
    },
    prepareCartProducts() {
      return this.cartItems.map(({ name, price, quantity, type, productId }) => ({
        name: this.sanitize(name),
        price: this.sanitize(price),
        quantity: this.sanitize(quantity),
        type: this.sanitize(type),
        productId: this.sanitize(productId),
      }));
    },
    prepareOrderData() {
      const addressIdInt = parseInt(this.customerOrder.addressId);
      const orderType = this.isDelivery ? 'Pickup' : 'Delivery';
      const orderDate = new Date().toISOString();

      const sanitizedAddress = {};
      for (const key in this.address) {
        sanitizedAddress[key] = this.sanitize(this.address[key]);
      }

      const customerOrder = {
        ...this.customerOrder,
        orderDate,
        addressId: addressIdInt,
        orderCost: this.calculateTotal(),
        orderType,
        userId: this.$store.state.user.id,
      };

      return {
        cartProducts: this.prepareCartProducts(),
        address: sanitizedAddress,
        customerOrder,
      };
    },
    async placeOrder() {
      const addressIdInt = parseInt(this.customerOrder.addressId);
      const userId = this.$store.state.user.id;

      if (isNaN(addressIdInt)) {
        alert('Invalid addressId. Please provide a valid integer.');
        return;
      }

      const orderData = this.prepareOrderData();

      try {
        const response = await AuthService.postOrder(orderData);
        console.log('Order submitted successfully!', response);
        this.successMessage = 'Your order was placed successfully!';
        setTimeout(() => {
          this.successMessage = '';
        }, 10000);
        this.clearForm();

        await AuthService.clearCartWhenCheckout(userId);

        this.clearCart();
        this.cartItems = [];
        this.$store.commit('SET_CART_ITEMS_COUNT', 0);

        await this.fetchCartItems();
      } catch (error) {
        console.log('Error placing order:', error);
      }
    },
    fetchCartItems() {
      const userId = this.$store.state.user.id;

      AuthService.getCartItems(userId)
        .then((response) => {
          this.cartItems = response.data.map((cartItem) => ({
            ...cartItem,
            userId: userId,
          }));
          this.updateCartItemsCount();
        })
        .catch((error) => {
          console.log('Error fetching cart items:', error);
        });
    },
    calculateTotal() {
      const userId = this.$store.state.user.id;
      const userCartItems = this.cartItems.filter((cartItem) => cartItem.userId === userId);
      let total = 0;

      for (const cartItem of userCartItems) {
        total += cartItem.price * cartItem.quantity;
      }

      if (this.customOrder.price > 0) {
        total += this.customOrder.price;
      }

      this.totalAMount = total;
      return total;
    },
    updateCartItemsCount() {
      const userId = this.$store.state.user.id;

      AuthService.getCartItemsCount(userId)
        .then((response) => {
          this.$store.commit('SET_CART_ITEMS_COUNT', response.data);
        })
        .catch((error) => {
          console.log('Error updating cart items count:', error);
        });
    },
    clearForm() {
      this.address = {
        homeType: '',
        streetAddress: '',
        apartmentNumber: '',
        city: '',
        state: '',
        zipCode: '',
        addressId: 0
      };
      this.customerOrder = {
        orderType: '',
        orderCost: 0,
        orderStatus: '',
        addressId: 0,
        employeeId: 0,
        orderDate: '',
        orderId: 0,
        userId: 0,
      };
    },
    async loadStripeClient() {
      // Replace 'your_stripe_publishable_key' with your actual Stripe publishable key
      const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
      this.stripe = stripe;

      // Create a new instance of Elements and mount the card element
      const elements = stripe.elements();
      this.cardElement = elements.create('card');
      this.cardElement.mount('#card-element');
    },
    async processPayment() {
      if (this.showCustomization && !this.file) {
        this.showAlert('Please upload an image.');
        return;
      }

      if (this.showCustomization && this.file && this.instruction === '') {
        this.showAlert('Please provide a custom instruction.');
        return;
      }

      const addressForm = document.getElementById('address-form');
      if (addressForm && !addressForm.checkValidity()) {
        return;
      }

      // Check if the address fields are empty
      const addressFields = ['homeType', 'streetAddress', 'city', 'state', 'zipCode'];
      let hasEmptyFields = false;
      for (const field of addressFields) {
        if (!this.address[field]) {
          hasEmptyFields = true;
          break;
        }
      }

      if (hasEmptyFields) {
        this.showAlert('Please fill in all address fields');
        setTimeout(() => {
          this.removeAlertMessage();
        }, 6000);
        return;
      }

      // Validate the street address format
      const streetAddressRegex = /^[a-zA-Z0-9\s.,-]+$/;
      if (!streetAddressRegex.test(this.address.streetAddress)) {
        this.showAlert('Please enter a valid street address');
        return;
      }

      // Validate the street address format
      const cityRegex = /^[a-zA-Z]+$/;
      if (!cityRegex.test(this.address.city)) {
        this.showAlert('Please enter a valid City name');
        return;
      }
      // Validate the street address format
      const stateRegex = /^[a-zA-Z]+$/;
      if (!stateRegex.test(this.address.state)) {
        this.showAlert('Please enter a valid State name');
        return;
      }

      // Disable the form submit button to prevent multiple submissions
      const submitButton = document.querySelector('#payment-form button');
      submitButton.disabled = true;

      // Create a payment method using the card element
      const { paymentMethod, error } = await this.stripe.createPaymentMethod({
        type: 'card',
        card: this.cardElement,
      });

      if (error) {
        // Handle error and display an error message
        console.error('Payment method error:', error.message);
        submitButton.disabled = false;
        return;
      }

      // Send the payment method to your server to complete the payment
      this.submitPaymentMethod(paymentMethod);
    },

    async submitPaymentMethod(paymentMethod) {
      // Send the payment method and client secret to your server
      // to complete the payment on the server side
      const token = this.$store.state.token;
      const response = await fetch('https://jewelrynepal-88e76a13f73a.herokuapp.com/payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          paymentMethodId: paymentMethod.id,
          amount: this.calculateTotal() * 100,
          userId: this.$store.state.user.id,
          status: 'pending',
          currency: 'USD',
          createdAtTimestamp: new Date().toISOString(),
          updatedAtTimestamp: new Date().toISOString(),
        }),
      });
      if (response.ok) {
        const responseData = await response.json();
        this.clientSecret = responseData.clientSecret;
        this.clearCardDetails();
        this.placeOrder();
        this.handleCustomizationChange();
        // this.$router.push({ name: 'payment-success' });
      } else {
        // Payment failed, handle the error
        console.error('Payment failed:', response.statusText);
        this.paymentFailed = true;
        // Enable the form submit button
        const submitButton = document.querySelector('#payment-form button');
        submitButton.disabled = false;
      }
    },
    showAlert(message) {
      const alertElement = document.createElement('div');
      alertElement.className = 'alert alert-danger';
      alertElement.role = 'alert';
      alertElement.textContent = message;
      const container = document.querySelector('.instruction-container');
      container.insertBefore(alertElement, container.firstChild);
    },
    showSuccessMessage(message) {
      const alertElement = document.createElement('div');
      alertElement.className = 'alert alert-success';
      alertElement.role = 'alert';

      const iconElement = document.createElement('i');
      iconElement.className = 'fas fa-check-circle mr-2';

      const messageElement = document.createElement('span');
      messageElement.textContent = message;

      alertElement.appendChild(iconElement);
      alertElement.appendChild(messageElement);

      const container = document.querySelector('.instruction-container');
      container.insertBefore(alertElement, container.firstChild);
    },
    removeAlertMessage() {
      const alertElement = document.querySelector('.alert-danger');
      if (alertElement) {
        alertElement.remove();
      }
    },
    removeSuccessMessage() {
      const alertElement = document.querySelector('.alert-success');
      if (alertElement) {
        alertElement.remove();
      }
    },
    clearCardDetails() {
      this.cardElement.clear();
      this.clientSecret = '';
    },
  }
}
</script>

<style>
.checkout-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.left-panel-checkout {
  background-color: #ebe7d8;
  width: 100%;
  display: flex;
}

.order-summary {
  width: 100%;
  background-color: #f1f1f1;
  padding: 20px;
}

.checkout-header {
  font-size: 24px;
  margin-top: 70px;
}

.checkout-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
  gap: 1rem;
}

.address-instruction-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 10px;
}

.order-item {
  margin-bottom: 10px;
  background-color: #e4c364;
  border-radius: 4px;
}

.details {
  margin-left: 1rem;
}

.cart-total {
  margin-top: 10px;
}

.address-header,
.instruction-header {
  font-size: 16px;
  font-weight: bold;
}

.address-container,
.instruction-container {
  margin-bottom: 10px;
}

.delivery-toggle {
  margin-bottom: 10px;
}

.form-input-group input {
  width: 100%;
  border: 1px solid #e4c364;
  border-radius: 4px;
  padding: 4px;
  margin-bottom: 4px;
}

.instruction-form input {
  width: 100%;
  padding: 4px;
  margin-bottom: 3px;
  border: 1px solid #e4c364;
  border-radius: 4px;
}

::placeholder {
  padding: 8px;
}

.checkout-header:before {
  content: '';
  position: absolute;
  width: 45px;
  height: 15px;
  border-bottom: 4px solid;
  top: 22px;
  margin-left: -60px;
}

.checkout-header:after {
  content: '';
  position: absolute;
  width: 45px;
  height: 15px;
  border-bottom: 4px solid;
  top: 23px;
  margin-left: 15px;
}

.checkout-header {
  font-family: Muli-Semi-Bold;
  color: #000000;
  font-size: 3rem;
  position: relative;
  text-transform: capitalize;
  text-align: center;
}

.delivery-toggle {
  display: flex;
  align-items: center;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.toggle-input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #e4c364;
  transition: 0.4s;
}

.toggle-input:checked+.slider {
  background-color: #2196f3;
}

.toggle-input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

.toggle-input:checked+.slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.delivery-options {
  margin-right: 1rem;
}

.continue-shopping-checkout {
  padding: 8px 8px;
  font-size: 1rem;
  border-radius: 4px;
  font-weight: bold;
  background-color: #000000;
  color: #e4c364;
  border: none;
  cursor: pointer;
  width: 200px;
  margin: 0.6rem;
  transition: background-color 0.3s;
}

.continue-shopping-checkout:hover {
  background-color: #e4c364;
  color: #000000;
  border: 1px solid #000000;
}

.empty-checkout-message {
  margin-top: 5rem;
  text-align: center;
}

.checkout-success-message {
  text-align: center;
  font-weight: bold;
  color: #078625;
}

.card-element {
  padding: 10px;
  border: 1px solid #e4c364;
  border-radius: 4px;
  width: 400px;
}

.pay-button:hover {
  background-color: #0056b3;
}

.pay-place-order {
  background-color: #000000;
  color: #e4c364;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 4px;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;
  margin-top: 4px;
  margin-bottom: 1rem;
}

.pay-place-order:hover {
  background-color: #e4c364;
  color: #000000;
  border: 1px solid #000000;
}

.alert {
  background-color: #f8d7da;
  color: #721c24;
  padding: 4px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  margin-bottom: 10px;
}

.alert {
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-success {
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
}

.fa-check-circle {
  color: #28a745;
}

.mr-2 {
  margin-right: 0.5rem;
}


.upload-container {
  position: relative;
  display: inline-block;
  width: 100%;
}

.upload-label {
  display: block;
  width: 100%;
  border: 2px dashed #e4c364;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.upload-icon {
  font-size: 24px;
  margin-right: 1rem;
}

.upload-text {
  font-size: 14px;
  font-weight: bold;
}

.upload-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.instruction-form textarea {
  width: 100%;
  border: 1px solid #e4c364;
  border-radius: 4px;
  padding: 10px;
  margin-top: 5px;
}

.input-error {
  border: 1px solid rgb(231, 31, 31);
}

.customization-checkbox {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.customization-checkbox input[type="checkbox"] {
  display: none;
}

.customization-checkbox label {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-weight: bold;
}

.customization-checkbox label:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  border: 2px solid #000000;
  border-radius: 3px;
}

.customization-checkbox label:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 10px;
  height: 10px;
  background-color: #000000;
  border-radius: 2px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.customization-checkbox input[type="checkbox"]:checked+label:before {
  border-color: #02b320;
}

.customization-checkbox input[type="checkbox"]:checked+label:after {
  opacity: 1;
}

.customization-checkbox label:hover:before {
  border-color: #999;
}

.customization-checkbox input[type="checkbox"]:focus+label:before {
  box-shadow: 0 0 0 3px rgba(18, 230, 18, 0.3);
}

.error-message p {
  font-size: 1rem;
  color: rgb(231, 44, 44);
  font-weight: 600;
}

.error-message {
  border: 1px solid rgb(231, 44, 44);
  padding: 5px;
  margin-top: 1rem;
}

@media screen and (max-width: 450px) {

  .checkout-header {
    font-size: 2rem;
    margin-top: 80px;
  }

  .checkout-header:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 15px;
    border-bottom: 4px solid;
    top: 12px;
    margin-left: -14px;
  }

  .checkout-header:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 15px;
    border-bottom: 4px solid;
    top: 12px;
    margin-left: 10px;
  }

  .checkout-content {
    flex-direction: column;
  }

  .pay-text {
    font-weight: 700;
  }

  .pay-injection {
    width: 94%;
  }

  .card-element {
    width: 100%;
    margin-bottom: 5px;
  }
}
</style>