<template>
  <div class="image-slides">
    <carousel-3d :controls-visible="true" :clickable="true" :autoplay="true" :autoplayTimeout="3500" :autoplayHoverPause="true" :perspective="0" :height="114">
      <slide v-for="(slide, i) in slides" :key="i" :index="i">
        <figure>
          <img :src="slide.url">
          <figcaption>
            <button :class="getButtonColor(i)" @click="goToRoute(slide.route)">Go</button>
            <p class="slides-captions">{{ slide.caption }}</p>
          </figcaption>
        </figure>
      </slide>
    </carousel-3d>
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";

export default {
  name: "image-slides",
  components: {
    Carousel3d,
    Slide
  },
  data() {
    return {
      slides: [
        { url: "https://static.malabargoldanddiamonds.com/media/bsimages/desktop.jpg", route: "/", caption: "Big Sales today" },
        { url: "https://www.beyondthelimitstreks.com/uploads/img/Bhaktapur%20Durbar%20Square.jpg", route: "/donations", caption: "Make donations" },
        { url: "https://himalaya-discovery.com/wp-content/uploads/2018/03/Everest-Panorama-Trek-Nepal.jpg", route: "/route3", caption: "New rani haar arrives on 12/01" },
        { url: "https://media.istockphoto.com/id/620721706/photo/view-of-annapurna-and-machapuchare-peak-at-sunrise-from-tadapani.jpg?s=612x612&w=0&k=20&c=7uuKYNd6_p3TtvBqd68h0lbGfQ2fXmE50zVcPHHqBqA=", route: "/route4", caption: "Caption 4" },
        { url: "https://st.depositphotos.com/1516608/3982/i/600/depositphotos_39829423-stock-photo-everest-at-sunset.jpg", route: "/route5", caption: "Caption 5" }
      ],
      buttonColors: ["red", "green", "blue", "yellow", "purple"]
    };
  },
  methods: {
    goToRoute(route) {
      this.$router.push(route);
    },
    getButtonColor(index) {
      return 'button-color-' + (index % this.buttonColors.length);
    }
  }
};
</script>

<style>
.carousel-3d-container .current figure {
  margin: 0;
  width: 100%;
  border: 1px solid #e4c364;
}

.carousel-3d-container .carousel-3d-slide.current figcaption {
  position: absolute;
  background-color: #e4c364;
  color: #000000;
  bottom: 0;
  position: absolute;
  bottom: 0;
  min-width: 100%;
  box-sizing: border-box;
  display: flex;
}

.next[data-v-05517ad0] {
  right: 10px;
  text-align: right;
  color: #17e232;
}

.prev[data-v-05517ad0] {
  left: 10px;
  text-align: left;
  color: #17e232;
}
.carousel-3d-container .carousel-3d-slide.current figcaption button {
  background-color: #ffffff;
  color:#000000;
  font-weight: 600;
  font-size: 10px;
  margin-right: 1rem;
}
.slides-captions {
  font-size: 10px;
  font-weight: 600;
  margin-top: 3px;
}
</style>
