<template>
  <div class="rani-container">
    <h1 class="rani-header">Rani Haar Collections</h1>
    <div class="product-grid">
      <router-link v-for="ranihaar in ranihaars" :key="ranihaar.productId" :to="{ name: 'product-details', params: { id: ranihaar.productId } }" class="product-box">
        <div class="product-image-container">
          <img :src="ranihaar.imageUrl" :alt="ranihaar.name" class="product-image"/>
        </div>
        <p class="product-price">${{ ranihaar.price }}</p>
        <p class="product-name">{{ ranihaar.name }}</p>
        <div class="product-rating">
          <template v-if="ranihaar.averageRating > 0">
            <span class="star" v-for="star in averageRatingStars(ranihaar.averageRating)" :key="star">&#9733; </span>
            ({{ ranihaar.totalRaters }})
          </template>
          <template v-else>
            <span>No ratings yet</span>
          </template>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';

export default {
  data() {
    return {
      ranihaars: [],
    };
  },
  created() {
    AuthService.getranihaar().then((response) => {
      this.ranihaars = response.data;
    });
  },
  methods: {
    averageRatingStars(averageRating) {
      const roundedRating = Math.round(averageRating);
      return Array(roundedRating).fill('');
    },
    // generateUniqueString() {
    //   // Generate a unique random string using a combination of characters and numbers
    //   const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    //   let uniqueString = '';

    //   for (let i = 0; i < 10; i++) {
    //     uniqueString += characters.charAt(Math.floor(Math.random() * characters.length));
    //   }

    //   return uniqueString;
    // },
  },
};
</script>


<style scoped>
.rani-container {
  margin-top: 70px;
}
.product-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 1384px;
  margin: 0 auto;
  padding: 0 20px;
}

.product-box {
  position: relative;
  width: calc(25% - 10px); 
  background-color: #e4c364;
  padding: 16px;
  border-radius: 6px;
  box-sizing: border-box;
  margin-bottom: 20px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: transform 0.2s ease-in-out;
}

.product-box:hover {
  transform: scale(1.03);
  background-color: #f0dada;
  border: 1px solid #e4c364;
}

.product-price {
  font-weight: 800;
  font-size: 26px;
  margin-top: 6px;
  font-weight: bolder;
  color: #000000;
  font-family: Verdana, Geneva, sans-serif;
}

.product-name {
  font-size: 14px;
  margin: 1px;
  color: #000000;
}

.product-image-container {
  display: flex;
  height: 300px;
}

.product-image {
  max-width: 100%;
  height: auto;
}

.product-rating {
  position: absolute;
  bottom: 40px; 
  right: 10px; 
  color: #000000;
}

.star {
  color: #000000;
  font-size: 20px;
}

h1 {
  text-align: center;
  font-size: x-large;
}
.rani-header:before {
    content: '';
    position: absolute;
    width: 45px;
    height: 15px;
    border-bottom: 4px solid;
    top: 22px;
    margin-left: -60px;
}

.rani-header:after {
    content: '';
    position: absolute;
    width: 45px;
    height: 15px;
    border-bottom: 4px solid;
    top: 23px;
    margin-left: 15px;
}

.rani-header {
    font-family: Muli-Semi-Bold;
    color: #000;
    position: relative;
    text-transform: capitalize;
    text-align: center;
    font-size: 3rem;
    margin-bottom: 0.4rem;
}

@media screen and (max-width: 830px) {
  .product-box {
    margin-bottom: 10px;
    width: 300px;
  }

  .product-grid {
    margin-left: 10px;
  }
}

@media screen and (max-width: 450px) {
  .rani-container {
    margin-top: 80px;
  }
  .rani-header {
    font-size: 2rem;
  }

  .rani-header:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 15px;
    border-bottom: 4px solid;
    top: 12px;
    margin-left: -14px;
  }

  .rani-header:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 15px;
    border-bottom: 4px solid;
    top: 12px;
    margin-left: 10px;
  }

  .product-box {
    width: 100%;
    padding: 12px;
  }

  .product-image-container {
    height: 300px;
  }

  .product-price {
    font-size: 14px;
    margin-top: 4px;
  }

  .product-name {
    font-size: 12px;
    margin: 0;
  }

  .product-rating {
    bottom: 16px;
    right: 8px;
  }

  .star {
    font-size: 16px;
  }
}
</style>
