<template>
  <div class="admin-container">
    <h1 class="admin-header">Welcome, {{ name }}</h1>
    <div class="main-profile-container">
      <div class="admin-power-notification">
        <div class="admin-notification">
          <router-link v-bind:to="{ name: 'pending-orders' }">
            <i class="fa-solid fa-bell"></i>
            <span class="notification-badge">{{ totalPendingCount }}</span>
          </router-link>
          <p>Order Request</p>
        </div>
        <div class="admin-power">
          <h1 class="admin-power-header">Choose Options</h1>
          <router-link v-bind:to="{ name: 'pending-orders' }" class="view-orders">View Orders</router-link>
          <router-link v-bind:to="{ name: 'completed-orders' }" class="view-sales-report">View Sales Report <i
              class="fa-sharp fa-solid fa-eye"></i></router-link>
          <router-link v-bind:to="{ name: 'add-product' }" class="add-products">Add New Products <i
              class="fa-solid fa-circle-plus"></i></router-link>
          <router-link v-if="userRole === 'ROLE_SUPER_ADMIN'" v-bind:to="{ name: 'employee-management' }"
            class="emp-management">Employee Management <i class="fa-sharp fa-solid fa-lock"></i></router-link>
          <router-link v-if="userRole === 'ROLE_SUPER_ADMIN'" v-bind:to="{ name: 'send-email' }"
            class="send-email-btn">Send
            Email <i class="fa-solid fa-envelope"></i></router-link>
        </div>
      </div>
      <div class="email-phone">
        <h1 class="email-phone-header">Email and Phone Details</h1>
        <div class="success-message-update" v-if="successMessage !== ''">
          <p>{{ successMessage }}</p>
        </div>
        <div class="error-message-update" v-if="updateError">
          <p>{{ errorMessage }}</p>
        </div>
        <p>Username: <strong>{{ username }}</strong></p>
        <div class="admin-email-container">
          <p v-if="!isEditingEmail" class="admin-email">{{ emailPhone.email }}</p>
          <input v-else v-model="updatedEmail" type="text" class="admin-email-input">
          <button @click="toggleEditingEmail">
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
        </div>
        <div class="admin-phone-container">
          <p v-if="!isEditingPhone" class="admin-phone">{{ emailPhone.phoneNumber }}</p>
          <input v-else v-model="updatedPhone" type="text" class="admin-phone-input">
          <button @click="toggleEditingPhone">
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
        </div>
      </div>

      <div class="profile-picture-container">
        <div class="profile-picture-emp">
          <div v-if="images.length === 0">
            <div class="profile-picture-wrapper">
              <img src="../assets/img/defaultpp.jpg" class="profile-picture" @click="openDialog(image)">
              <p class="name-text">{{ name }} <font-awesome-icon :icon="['fas', 'circle-check']" class="blue-icon" /></p>
              <p class="administrator">Administrator</p>
            </div>
          </div>
          <div v-else class="profile-picture-container">
            <div v-for="image in images" :key="image.id">
              <div class="profile-picture-wrapper">
                <img :src="image" alt="Profile Pic" class="profile-picture" @click="openDialog(image)">
                <p class="name-text">{{ name }} <font-awesome-icon :icon="['fas', 'circle-check']" class="blue-icon" />
                </p>
                <p class="administrator">Administrator</p>
              </div>
            </div>
          </div>
          <div class="emp-details">
            <p>Position: <strong>{{ empDetails.position }}</strong></p>
            <p>Department: <strong>{{ empDetails.department }}</strong></p>
            <p class="emp-id">Employee Id: <strong>{{ empDetails.employeeId }}</strong></p>
          </div>
        </div>
        <div class="admin-login-history-container">
          <div class="login-area">
            <h1 class="admin-login-details-header">Recent Login Details</h1>
            <div v-for="loginHistory in loginHistories" :key="loginHistory.id" class="admin-login-history">
              <p>Address: {{ loginHistory.address }}</p>
              <p>Device Name: {{ loginHistory.deviceName }}</p>
              <p>Login Date: {{ loginHistory.loginDate }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-if="dialogOpen" class="dialog-overlay">
        <div class="dialog-content">
          <button class="close-button" @click="closeDialog"><i class="fa-solid fa-rectangle-xmark"></i></button>
          <div v-for="image in images" :key="image.id">
            <div class="profile-picture-wrapper">
              <img :src="image" alt="Profile Pic" class="profile-picture">
              <p class="name-text">{{ name }} <font-awesome-icon :icon="['fas', 'circle-check']" class="blue-icon" /></p>
              <p class="administrator">Administrator</p>
            </div>
          </div>
          <h2>Profile Picture Options</h2>
          <div class="dialog-buttons">
            <input type="file" @change="changeProfilePicture" accept=".jpg,.jpeg,.png">
            <button @click="removeProfilePicture">Remove</button>
          </div>
        </div>
      </div>
      <div class="admin-info">
        <p><i class="fa-solid fa-triangle-exclamation"></i> Please make changes carefully and every change will have
          immediate effect.</p>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';

export default {
  data() {
    return {
      file: null,
      name: {},
      userId: this.$store.state.user.id,
      images: [],
      profileImage: null,
      dialogOpen: false,
      totalPendingCount: 0,
      empDetails: {},
      loginHistories: [],
      emailPhone: {
        email: '',
        phoneNumber: '',
      },
      username: this.$store.state.user.username,
      isEditingEmail: false,
      isEditingPhone: false,
      updatedEmail: '',
      successMessage: '',
      errorMessage: 'There was problem updating the email',
      updateError: false,
      updatedPhone: '',
    };
  },
  computed: {
    userRole() {
      if (this.$store.state.user && this.$store.state.user.authorities) {
        const authorities = this.$store.state.user.authorities;
        if (authorities.length > 0) {
          return authorities[0].name;
        }
      }
      return null;
    },
  },
  created() {
    AuthService.getNames(this.userId)
      .then((response) => {
        this.name = response.data;
      })
      .catch((error) => {
        console.log('Error while fetching user names:', error);
      });

    this.getEmpDetails();
    this.getEmailPhone();
    this.getLoginHistories();
    this.setCartItemsCount();
    this.pollingInterval = setInterval(this.setCartItemsCount, 5000);
  },
  beforeDestroy() {
    clearInterval(this.pollingInterval);
  },
  methods: {
    async setCartItemsCount() {
      try {
        const response = await AuthService.totalPendingOrder();
        this.totalPendingCount = response.data;
        console.log('Pending Count:', response.data);
      } catch (error) {
        console.log('Error while fetching pending count:', error);
      }
    },
    getEmpDetails() {
      AuthService.getEmpDetails(this.userId)
        .then((response) => {
          this.empDetails = response.data;
          console.log('Employee Details: ', response.data)
        })
        .catch((error) => {
          console.log('Error while fetching user names:', error);
        });
    },
    getEmailPhone() {
      AuthService.getEmailPhone(this.userId)
        .then((response) => {
          this.emailPhone = response.data;
        })
        .catch((error) => {
          console.log('Error while fetching the data: ', error)
        })
    },
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    openDialog(image) {
      console.log("Open dialog with image:", image);
      this.dialogOpen = true;
    },

    closeDialog() {
      this.dialogOpen = false;
    },
    toggleEditingEmail() {
      if (this.isEditingEmail) {
        this.saveUpdatedEmail();
      } else {
        this.updatedEmail = this.emailPhone.email;
      }
      this.isEditingEmail = !this.isEditingEmail;
    },
    saveUpdatedEmail() {
      AuthService.updateUserEmail(this.updatedEmail, this.userId)
        .then((response) => {
          if (response.status === 200) {
            this.successMessage = 'Email updated successfully!';
            this.emailPhone.email = this.updatedEmail;
          }
          setTimeout(() => {
            this.successMessage = '';
          }, 5000);
        })
        .catch((error) => {
          const response = error.response;
          this.updateError = true;
          if (response.status === 400) {
            this.errorMessage = response.data.message;
          } else if (response.status === 500) {
            this.errorMessage = response.data;
          }
          setTimeout(() => {
            this.errorMessage = '';
          }, 5000);
        });
    },

    toggleEditingPhone() {
      if (this.isEditingPhone) {
        this.saveUpdatedPhone();
      } else {
        this.updatedPhone = this.emailPhone.phoneNumber;
      }
      this.isEditingPhone = !this.isEditingPhone;
    },
    saveUpdatedPhone() {
      AuthService.updateUserPhone(this.updatedPhone, this.userId)
        .then((response) => {
          this.successMessage = 'Phone updated successfully!';
          console.log(response.data);
          this.emailPhone.phoneNumber = this.updatedPhone;

          setTimeout(() => {
            this.successMessage = '';
          }, 5000);
        })
        .catch((error) => {
          console.log(`Failed to update email`, error);
        });
    },
    changeProfilePicture(event) {
      this.file = event.target.files[0];
      if (!this.file) {
        alert("Please select a file");
        return;
      }
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("userId", this.userId);

      fetch(`https://jewelrynepal-88e76a13f73a.herokuapp.com/api/profile/upload?userId=${this.userId}`, {
        method: "POST",
        body: formData
      })
        .then(response => response.text())
        .then(result => {
          alert(`${result}`);
          this.file = null;
          this.getImages();
          this.closeDialog();
        })
        .catch(error => {
          console.error("Failed to upload image", error);
          alert("Failed to upload image");
        });
    },

    async removeProfilePicture() {
      try {
        const response = await AuthService.removeProfilePicture(this.userId);
        if (response.status === 200) {
          console.log("Remove profile picture");
          this.images = [];
          this.closeDialog();
        } else {
          console.log('Not Found');
        }

      } catch (error) {
        console.error('Failed to remove profile picture', error);
      }
    },
    getImages() {
      AuthService.getProfilePicture(this.userId)
        .then((response) => {
          this.images = response.data;
          console.log('Profile Picture:', response.data)
        })
        .catch(error => {
          console.error("Failed to get images", error);
        });
    },
    getLoginHistories() {
      AuthService.getLoginHistory(this.userId)
        .then((response) => {
          this.loginHistories = response.data
        });
    },
  },
  mounted() {
    this.getImages();
  }
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.admin-container {
  margin-top: 70px;
  background-color: #ffffff;
  margin-bottom: 2rem;
}

.main-profile-container {
  display: grid;
  overflow: hidden;
  grid-auto-columns: 1fr 1fr;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    "order-alert profile"
    "admin-power profile"
    "info info";
  align-items: flex-start;
  position: relative;
  max-width: 850px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 1%;
  gap: 20px;
}

.admin-header {
  font-family: Muli-Semi-Bold;
  color: #000;
  position: relative;
  text-transform: capitalize;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 0.4rem;
  margin-top: 0;
}

.notification-badge {
  background-color: red;
  color: white;
  font-size: 12px;
  padding: 1px 6px;
  border-radius: 50%;
  margin-left: -1rem;
  position: absolute;
}

.admin-notification {
  justify-content: center;
  text-align: center;
  color: #000000;
  margin-top: 1rem;
  grid-area: order-alert;
}

.admin-notification i {
  font-size: 3rem;
}

.profile-picture-container {
  text-align: center;
  grid-area: profile;
}

.profile-picture {
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  margin: 0.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;
  margin-top: 1rem;
}

.profile-picture:hover {
  transform: scale(1.1);
}

.profile-picture p {
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
}

.profile-picture-wrapper {
  position: relative;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
}

.dialog-content {
  background-color: white;
  padding: 2rem;
  border-radius: 4px;
  text-align: center;
  position: relative;
}

.dialog-buttons {
  margin-top: 1rem;
}

.dialog-buttons button {
  background-color: #fd0606;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 0.5rem;
}

.dialog-buttons button:hover {
  background-color: #45a049;
}

.close-button {
  position: absolute;
  top: 2px;
  right: 10px;
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  font-size: 20px;
  color: #000;
  cursor: pointer;
}

.blue-icon {
  color: #e4c364;
}

.name-text {
  color: #000000;
  font-weight: bold;
  margin-top: 0;
}

.administrator {
  color: rgb(107, 116, 124);
  margin-bottom: 1rem;
  font-size: 1rem;
}

.admin-power {
  text-align: center;
  display: grid;
  grid-area: admin-power;
  width: 100%;
  justify-content: center;
  gap: 8px;
  margin-top: 1rem;
}

.admin-power .view-orders {
  width: 300px;
}

.view-orders {
  border: 1px solid #000000;
  background-color: #000000;
  border-radius: 4px;
  color: #e4c364;
  padding: 6px;
  font-weight: 600;
}

.view-orders:hover {
  background-color: #e4c364;
  color: #000000;
  padding: 6px;
}

.view-sales-report {
  border: 1px solid #000000;
  background-color: #25bd69;
  border-radius: 4px;
  color: #000000;
  padding: 6px;
  font-weight: 600;
}

.view-sales-report:hover {
  background-color: #e4c364;
  color: #000000;
  padding: 6px;
}

.add-products {
  border: 1px solid #000000;
  background-color: #6341dd;
  border-radius: 4px;
  color: #ffffff;
  padding: 6px;
  font-weight: 600;
}

.add-products:hover {
  background-color: #e4c364;
  color: #000000;
  padding: 6px;
}

.emp-management {
  border: 1px solid #000000;
  background-color: #db1313;
  border-radius: 4px;
  color: #ffffff;
  padding: 6px;
  font-weight: 600;
}

.send-email-btn {
  border: 1px solid #000000;
  background-color: #220c0c;
  border-radius: 4px;
  color: #ffffff;
  padding: 6px;
  font-weight: 600;
}

.emp-management:hover {
  background-color: #e4c364;
  color: #000000;
  padding: 6px;
}

.admin-info {
  grid-area: info;
  text-align: center;
  margin-top: 3rem;
}

.admin-info p {
  font-weight: 600;
  font-size: 1rem;
}

.emp-details p {
  font-size: 1rem;
}

.emp-details h1 {
  font-size: 1rem;
  font-weight: 800;
}

.fa-bell {
  color: #000000;
}

.admin-login-details-header,
.admin-power-header {
  font-family: Muli-Semi-Bold;
  color: #000;
  position: relative;
  text-transform: capitalize;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.admin-login-history-container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  justify-content: center;
}

.admin-login-history-container p {
  color: #4f4f4f;
  margin-top: 0;
  font-size: 12px;
}

.admin-login-history {
  margin-bottom: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 4px;
}

.login-area {
  margin: 10px;
}

.profile-picture-emp {
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 1%;
  padding: 10px;
}

.admin-power-notification {
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 1%;
  padding: 18px;
}

.email-phone {
  background-color: #ffffff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  padding: 11px;
  text-align: center;
  border-radius: 2%;
  width: 100%;
}

.email-phone p {
  font-size: 1rem;
}

.email-phone-header {
  font-family: Muli-Semi-Bold;
  color: #000;
  position: relative;
  text-transform: capitalize;
  text-align: center;
  font-size: 1rem;
  font-weight: 700;
}

.admin-email-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-email {
  margin-right: 10px;
}

.admin-email-input {
  width: 250px;
  margin-right: 10px;
  height: 30px;
}

.success-message-update p {
  color: #4CAF50;
  font-size: 1rem;
  font-weight: 800;
}

.error-message-update p {
  color: #fd0606;
  font-size: 1rem;
  font-weight: 800;
}
.admin-phone-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.admin-phone {
  margin-right: 10px;
}

.admin-phone-input {
  width: 250px;
  margin-right: 10px;
  height: 30px;
}

@media screen and (max-width: 830px) {
  .main-profile-container {
    margin-left: 1.4rem;
  }
}

@media screen and (max-width: 450px) {
  .main-profile-container {
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      "profile"
      "header"
      "order-alert"
      "admin-power"
      "info";
    justify-items: center;
    width: 90%;
  }

  .profile-picture-container {
    grid-area: profile;
    margin-right: 10px;
  }

  .admin-power {
    grid-area: admin-power;
  }

  .admin-header {
    font-size: 2rem;
  }

  .admin-notification {
    margin-top: 1rem;
  }

  .admin-info {
    margin-top: 20px;
  }

  .admin-info p {
    margin-right: 20px;
  }

  .dialog-buttons {
    width: 90%;
  }

  .login-history-container {
    width: 100%;
    margin-left: 0;
  }

  .admin-power-notification {
    height: 100%;
    margin-left: -12px;
  }

  .email-phone {
    margin-right: 12px;
  }

  .dialog-buttons {
    display: flex;
  }

  .dialog-content {
    width: 90%;
  }

  .dialog-buttons button {
    margin-right: -1.4rem;
  }

  .admin-container {
    margin-top: 80px;
  }

  .error-message-update p {
    font-size: 12px;
}
}
</style>