<template>
  <div class="user-profile-container" @click="handleOutsideClick">
    <div class="profile-main-container">
      <div class="profile-left-container">
        <div class="my-profile-header">
          <h1>My Profile </h1>
        </div>
        <div v-if="images.length === 0">
          <div class="profile-profile-picture-wrapper">
            <img src="../assets/img/defaultpp.jpg" class="profile-profile-picture" @click="openDialog(image)">
            <p class="name-text">{{ name }} <font-awesome-icon :icon="['fas', 'circle-check']" class="blue-icon" /></p>
          </div>
        </div>
        
        <div v-else class="profile-profile-picture-container">
          <div v-for="image in images" :key="image.id">
            <div class="profile-profile-picture-wrapper">
              <img :src="image" alt="Profile Pic" class="profile-profile-picture" @click="openDialog(image)">
              <p class="name-text">{{ name }} <font-awesome-icon :icon="['fas', 'circle-check']" class="black-icon" /></p>
            </div>
          </div>
        </div>
        <div class="profile-email-phone">
          <h1 class="profile-email-phone-header">Email and Phone Details</h1>
          <p v-if="!isEditing">{{ emailPhone.email }}</p>
          <input v-else v-model="updatedEmail" type="text">
          <button @click="toggleEditing">
            <i class="fa-solid fa-pen-to-square"></i>
          </button>
          <p>{{ emailPhone.phoneNumber }}</p>
          <p>Username: {{ username }}</p>
        </div>        
      </div>
      <div class="right-profile-container"> 
        <div class="login-history-container"> 
          <h1 class="login-details-header">Recent Login Details</h1>
          <div v-for="loginHistory in loginHistories" :key="loginHistory.id" class="login-history-profile">
            <p>Address: {{loginHistory.address}}</p>
            <p>Device Name: {{loginHistory.deviceName}}</p>
            <p>Login Date: {{loginHistory.loginDate}}</p>
          </div>
        </div>
        <div class="purchase-history" ref="purchaseHistoryContainer"> 
          <div class="purchase-history-header">
            <h1 class="purchase-history-title">Recent Purchase History</h1>
          </div>
         
            <div v-for="purchaseHistory in purchaseHistories" :key="purchaseHistory.id" class="purchase-history-name">
              <p @click="showOrderDetails(purchaseHistory)">{{ purchaseHistory.productName }}</p>
            </div>
          <div v-if="selectedOrder" class="order-details-profile">
            <div class="order-detail-profile-left">
              <h2 class="order-detail-profile-header">Order Details</h2>
              <p><strong>Product Name:</strong> {{ selectedOrder.productName }}</p>
              <p><strong>Order Cost:</strong> {{ selectedOrder.orderCost }}</p>
              <p><strong>Order Status:</strong> {{ selectedOrder.orderStatus }}</p>
              <p><strong>Order Date:</strong> {{ selectedOrder.orderDate }}</p>
              <p><strong>Order Type:</strong> {{ selectedOrder.orderType }}</p>
              <p><strong>roduct Quantity:</strong> {{ selectedOrder.quantity }}</p>
              <p><strong>Product Type:</strong> {{ selectedOrder.productType }}</p>
              <p><strong>Product Price:</strong> {{ selectedOrder.price }}</p>
            </div>
            
            <div class="order-profile-image-right">
              <img class="profile-product-image" :src="selectedOrder.imageUrl">
            </div>
            
          </div>
          </div>
        </div>
    </div>
    <div v-if="dialogOpen" class="dialog-overlay">
      <div class="dialog-content">
        <button class="close-button" @click="closeDialog"><i class="fa-solid fa-rectangle-xmark"></i></button>
        <div v-for="image in images" :key="image.id">
          <div class="profile-profile-picture-wrapper">
            <img :src="image" alt="Profile Pic" class="profile-profile-picture">
            <p class="name-text">{{ name }} <font-awesome-icon :icon="['fas', 'circle-check']" class="blue-icon" /></p>
          </div>
        </div>
        <h2>Profile Picture Options</h2>
        <div class="dialog-buttons">
          <input type="file" @change="changeProfilePicture" accept=".jpg,.jpeg,.png">
          <button @click="removeProfilePicture">Remove</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';
export default {
  data(){
    return{
      loginHistories: [],
      purchaseHistories: [],
      userId: this.$store.state.user.id,
      name: {},
      file: null,
      images: [],
      dialogOpen: false,
      profileImage: null,
      selectedOrder: null,
      emailPhone: {
        email: '',
        phoneNumber: '',
      },
      username: this.$store.state.user.username,
      isEditing: false,
      updatedEmail: ''
    }
  },
  created(){
    this.getEmailPhone();
    this.getLoginHistories();
    this.getPurchaseHistories();
    this.getusername();
    
  },
  methods: {
    getLoginHistories(){
      AuthService.getLoginHistory(this.userId)
      .then((response)=> {
        this.loginHistories = response.data
      });
    },
    getPurchaseHistories(){
      AuthService.getPurchaseHistory(this.userId)
      .then((response)=> {
        this.purchaseHistories = response.data
      })
    },
    getusername(){
      AuthService.getNames(this.userId)
      .then((response) => {
        this.name = response.data;
      })
      .catch((error) => {
        console.log('Error while fetching user names:', error);
      });
    },
    showOrderDetails(order) {
      this.selectedOrder = order;
    },
    handleFileChange(event) {
      this.file = event.target.files[0];
    },
    openDialog(image) {
      console.log("Open dialog with image:", image);
      this.dialogOpen = true;
    },

    closeDialog() {
      this.dialogOpen = false;
    },

    handleOutsideClick(event) {
      const purchaseHistoryContainer = this.$refs.purchaseHistoryContainer;
      if (purchaseHistoryContainer && !purchaseHistoryContainer.contains(event.target)) {
        this.hideOrderDetails();
      }
    },

    hideOrderDetails() {
      this.selectedOrder = null;
    },
    toggleEditing() {
    if (this.isEditing) {
      // Save the updated email
      this.saveUpdatedEmail();
    } else {
      // Enable editing mode
      this.updatedEmail = this.emailPhone.email;
    }
    // Toggle the editing state
    this.isEditing = !this.isEditing;
  },
  saveUpdatedEmail() {
    // Call your API or service to update the email
    AuthService.updateUserEmail(this.updatedEmail, this.userId)
      .then((response) => {
        console.log('Email updated successfully', response);
        // Optionally, you can update the email displayed in the UI with the updated value
        this.emailPhone.email = this.updatedEmail;
      })
      .catch((error) => {
        console.log('Failed to update email', error);
        // Handle error cases, such as displaying an error message to the user
      });
  },

    getEmailPhone() {
      AuthService.getEmailPhone(this.userId)
        .then((response) => {
          this.emailPhone = response.data;
        })
        .catch((error) => {
          console.log('Error while fetching the data: ', error)
        })
    },
    changeProfilePicture(event) {
      this.file = event.target.files[0];
      if (!this.file) {
        alert("Please select a file");
        return;
      }
      const formData = new FormData();
      console.log('Role',this.$store.state.user.authorities[0])
      formData.append("file", this.file);
      formData.append("userId", this.userId);

      fetch(`https://jewelrynepal-88e76a13f73a.herokuapp.com/api/profile/upload?userId=${this.userId}`, {
        method: "POST",
        body: formData
      })
        .then(response => response.text())
        .then(result => {
          alert(`${result}`);
          console.log(result);
          this.file = null;
          this.getImages();
          this.closeDialog();
        })
        .catch(error => {
          console.error("Failed to upload image", error);
          alert("Failed to upload image");
        });
    },

    async removeProfilePicture() {
      try {
        const response = await AuthService.removeProfilePicture(this.userId);
        if (response.status === 200) {
          console.log("Remove profile picture");
          this.images = [];
          this.closeDialog();
        } else {
          console.log('Not Found');
        }

      } catch (error) {
        console.error('Failed to remove profile picture', error);
      }
    },
    getImages() {
      AuthService.getProfilePicture(this.userId)
        .then((response) => {
          this.images = response.data;
          console.log('Profile Picture:', response.data)
        })
        .catch(error => {
          console.error("Failed to get images", error);
        });
    },
   },
   mounted() {
      this.getImages();
   }
  }

</script>

<style>
.user-profile-container {
  margin-top: 75px;
  display: flex;
  justify-content: center;
  background-color: #f5f5f5;
}
.profile-main-container {
  margin-top: 1rem;
  display: flex;
  flex-direction:row;
  width: 900px;
  justify-content: center;
  background-color: #ffffff;

  margin-bottom: 1rem;
  border-radius: 1%;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
.profile-left-container{
  display: flex;
  justify-content: center;
  width: 400px;
  font-size: 1rem;
  flex-direction: column;

}
.right-profile-container {
  display: flex;
  justify-content: center;
  background-color: #ffffff;
  width: 500px;
  margin-top: 1rem;
  flex-direction: column;
}
.login-history-container p {
  font-size: 1rem;
}
.login-history-container {
display: flex;
flex-direction: column;
background-color: #f5f5f5;
border-radius: 3%;
margin: 10px;
}
.purchase-history{
  display: flex;
  flex-direction: column;
  background-color: #f5f5f5;
  margin: 10px;
  border-radius: 3%;
}
.login-details-header,
.purchase-history-header {
  text-align: center;
  background-color: #e9e9e9;
  padding: 10px;
  text-decoration-style: underline;
  text-decoration-color: black;
  text-decoration-thickness: 2px;
  align-self: flex-start;
  width: 100%;
  font-family: Muli-Semi-Bold;
  margin-bottom: 10px;
}

.login-history-container,
.purchase-history {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-button-container {
  margin-right: 10px;
}

.search-button {
  padding: 6px 10px;
  background-color: #4285f4;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.purchase-history-title {
  flex-grow: 1;
  margin: 0;
  text-align: center;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  cursor: pointer;
}

.dialog-content {
  background-color: white;
  padding: 2rem;
  border-radius: 4px;
  text-align: center;
  position: relative;
}

.dialog-buttons {
  margin-top: 1rem;
}

.dialog-buttons button {
  background-color: #fd0606;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 0.5rem;
}

.dialog-buttons button:hover {
  background-color: #45a049;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 20px;
  width: 24px;
  height: 24px;
  border: none;
  background: none;
  font-size: 20px;
  color: #000;
  cursor: pointer;
}

.blue-icon {
  color: rgb(16, 129, 221);
}

.profile-profile-picture-container {
  text-align: center;
  grid-area: profile;
  background-color: #f5f5f5;
  margin: 10px;
}

.profile-profile-picture {
  width: 360px;
  height: 200px;
  object-fit: cover;
  margin: 0.5rem;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  cursor: pointer;
}

/*.profile-picture:hover {
  transform: scale(1.1);
}*/

.profile-profile-picture p {
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
}

.profile-profile-picture-wrapper {
  position: relative;
}
.purchase-history-name{
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  width: 90%;
  margin-bottom: 10px;
  background-color: #fff;
  

}
.purchase-history-name:hover {
  background-color: #e9e9e9;
  border: 1px solid #e6e1d2;
}

.purchase-history-name p {
  font-size: 1rem;
  padding: 5px;
}
.order-details-profile{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 6px;
  width: 90%;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.order-details-profile img {
  display: flex;
  justify-content: flex-end;
  align-items: right;
  width: 200px;
  height: 190px;
}
.order-details-profile p{ 
  font-size: 12px;
}

.order-detail-profile-header{ 
  font-weight: 800;
}
.login-history-profile p{
  font-size: 12px;
}
.login-history-profile {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 6px;
  margin-bottom: 10px;
  width: 90%;
  background-color: #ffffff;
}
.profile-email-phone{
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  width: 96%;
  margin-left: 10px;
}
.profile-email-phone p {
  font-size: 14px;
  text-align: center;
  

}
.profile-email-phone-header {
  background-color: #e9e9e9;
  font-family: Muli-Semi-Bold;
  
}
.my-profile-header {
  background-color: #e9e9e9;
  font-family: Muli-Semi-Bold;
  margin-left: 10px;
  margin-right: 10px;
}

@media screen and (max-width: 830px) {
  .profile-main-container {
    width: 800px;
   }
  
}

@media screen and (max-width: 780px) {
  .profile-main-container{
    flex-direction:column;

  }
  .login-history-profile{
    width: 90%;
  }
  .purchase-history{
    width: 97%;
    margin-left: 6px;
  }
 
 .login-history-container{
  width: 97%;
  margin-left: 6px;
 }
 .right-profile-container {
  width: 750px;
}
.profile-left-container {

  width: 750px;
}
.user-profile-container {
  width: 99%;
  margin-left: 22px;
}
}

@media screen and (max-width: 450px) {
  .profile-main-container{
    flex-direction:column;

  }
  .login-history-profile{
    width: 90%;
  }
  .purchase-history{
    width: 97%;
    margin-left: 6px;
  }
 
 .login-history-container{
  width: 97%;
  margin-left: 6px;
 }
 .right-profile-container {
  width: 370px;
}
.profile-left-container {

  width: 370px;
}
.user-profile-container {
  width: 96%;
  margin-left: 7px;
}
}

</style>