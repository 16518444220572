import axios from 'axios';

export default {

  login(user) {
    return axios.post('/login', user)
  },

  register(user) {
    return axios.post('/register', user)
  },
  getranihaar(){
    return axios.get('/api/rani-haar')
  },
  getNecklace(){
    return axios.get('/api/necklace')
  },
  getBraceletsData(){
    return axios.get(`api/bracelet`)
  },
  getMangalSutraData() {
    return axios.get(`/api/mangal-sutra`)
  },
  getRingsData() {
    return axios.get(`api/rings`)
  },
  getProudctDetails(id){
    return axios.get(`/api/product-details/${id}`)
  },
  getReviews(id){
    return axios.get(`/api/reviews?productId=${id}`)
  },
  postReviews(body){
    return axios.post(`/api/reviews`, body)
  },
  addToCart(cartData){
    return axios.post(`/api/cart/add-to-cart`, cartData);
  },
  getCartItems(id){
    return axios.get(`/api/cart/get-cart-by-user-id?userId=${id}`);
  },
  removeItemfromCart(itemId, userId) {
    return axios.delete(`/api/cart/removeitems?itemId=${itemId}&userId=${userId}`);
  },
  getCartItemsCount(userId){
    return axios.get(`/api/cart/item-count?userId=${userId}`)
  },
  getLiveGoldRates(){
    return axios.get(`/api/gold-rates`);
  },
  getExchangeRate(){
    return axios.get('/api/exchange-rates');
  },
  postOrder(orderdata) {
    return axios.post('/api/cart/checkout', orderdata);
  },
  clearCartWhenCheckout(userId) {
    return axios.delete(`/api/cart/clear-cart/${userId}`);
  },
  postPayment(){
    return axios.post('/payment');
  },
  uploadImage(userId, imageData){
    return axios.post(`/upload?userId=${userId}`, imageData);
  },
  removeProfilePicture(userId){
    return axios.delete(`/images/removed/${userId}`);
  },
  getNames(userId){
    return axios.get(`/names?userId=${userId}`);
  },
  getOrders(){
    return axios.get('/api/order/pending-orders');
  },
  trackOrder(userId){
    return axios.get(`/api/order/track-order?userId=${userId}`);
  },
  completedOrders(){
    return axios.get(`/api/order/completed-orders`);
  },
  totalPendingOrder(){
    return axios.get(`/api/cart/pending-count`);
  },
  postLikeItems(postData){
    return axios.post(`/api/liked-product` , postData)
  },
  getCountLikedItem(userId) {
    return axios.get(`/api/liked-product/{userId}?userId=${userId}`)
  },
  postProductData(productData) {
    return axios.post(`/api/product`, productData)
  },
  getLikeProductLists(userId){
    return axios.get(`/api/liked-product-list?userId=${userId}`)
  },
  removeLikeItem(itemId, userId){
    return axios.delete(`/api/remove-product?id=${itemId}&userId=${userId}`)
  },
  getProductRecommendations(){
    return axios.get(`/api/products/recommendations`);
  },
  getAddress(orderId){
    return axios.get(`/api/address?orderId=${orderId}`);
  },
  getEmployeeName(userId){
    return axios.get(`/api/employee-names?userId=${userId}`)
  },
  getEmployeeList(){
    return axios.get(`/api/employee-list`)
  },
  updateEmployeeRole(employeeId, updateRole){
    return axios.put(`/api/employee/update-role?employeeId=${employeeId}&updateRole=${updateRole}`)
  },
  addEmployee(empData){
    return axios.post(`/api/employee/add-employee`, empData)
  },
  getUserList(){
    return axios.get(`/api/employee/user-list`)
  },
  getEmpDetails(userId){
    return axios.get(`/api/employee/profile-details?userId=${userId}`)
  },
  getProfilePicture(userId){
    return axios.get(`/api/profile-picture/${userId}`)
  },
  forgotPassword(email){
    return axios.post(`/api/forgot-password?email=${email}`)
  },
  resetPassword(passwordData){
    return axios.post(`/api/reset-password`, passwordData)
  },
  sendEmails(emailData){
    return axios.post(`/api/send-emails`, emailData)
  },
  getLoginHistory(userId){
    return axios.get(`/api/login-history?userId=${userId}`)
  },
  getPurchaseHistory(userId){
    return axios.get(`/api/purchase-history?userId=${userId}`)
  },
  getEmailPhone(userId) {
    return axios.get(`/api/email-phone?userId=${userId}`)
  },
  updateUserEmail(updateEmail, userId){
    return axios.put(`/api/update-user-email?email=${updateEmail}&userId=${userId}`)
  },
  searchProduct(searchQuery) {
    return axios.get('/api/product/search', {
      params: {
        name: searchQuery,
        productType: searchQuery,
        goldPurity: searchQuery,
        gender: searchQuery
      }
    });
  }  
}