<template>
    <div class="review-container">
        <h2 class="review-header">Reviews</h2>
        <div class="flex-container">
            <div class="reviews-form">
                <header>Leave your reviews</header><br>
                <form @submit.prevent="submitReview">
                    <div>
                        <label for="rating">Rating:</label>
                        <select id="rating" v-model="review.rating" required>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                    <div>
                        <label for="reviewText">Review:</label>
                        <textarea id="reviewText" v-model="review.reviewText" required></textarea>
                    </div>
                    <button type="submit">Submit Review</button>
                </form>
            </div>
            <div class="previous-reviews">
                <header>Previous reviews</header>
                <div class="previous-reviews-container">
                    <div v-for="reviewed in reviews" :key="reviewed.productId" class="review">
                        <p class="reviewers-name">{{ reviewed.firstName }}</p>
                        <div class="review-details">
                            <div class="review-rating">
                                <span v-for="star in reviewed.rating" :key="star" class="star">&#9733;</span>
                            </div>
                            <p class="reviewers-create-date">Created at: {{ formatDate(reviewed.createdAt) }}</p>
                            <p class="reviewers-isverified">{{ reviewed.isVerified ? 'Verified' : 'Not Verified' }}</p>
                        </div>
                        <p class="review-text">{{ reviewed.reviewText }}</p>
                    </div>
                </div>
                <button v-if="showViewMoreButton" @click="showAllReviews" class="view-more-button">View More</button>
            </div>
        </div>
    </div>
</template>
  
<script>
import AuthService from '@/services/AuthService';

export default {
    data() {
        return {
            review: {
                rating: null,
                reviewText: ''
            },
            reviews: [],
            displayCount: 3,
        };
    },
    computed: {
        showViewMoreButton() {
            return this.reviews.length > 2;
        },
    },
    created() {
        const productId = parseInt(this.$route.params.id);
        if (isNaN(productId)) {
            console.error('Invalid productId:', this.$route.params.id);
            return;
        }
        AuthService.getReviews(productId)
            .then(response => {
                this.reviews = response.data.slice(0, this.displayCount);
            })
            .catch(error => {
                console.error(error);
            });
    },
    methods: {
        formatDate(date) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            return new Date(date).toLocaleDateString(undefined, options);
        },
        showAllReviews() {
            const productId = parseInt(this.$route.params.id);
            AuthService.getReviews(productId)
                .then(response => {
                    this.reviews = response.data;
                    this.displayCount = this.reviews.length;
                })
                .catch(error => {
                    console.error(error);
                });
        },
        submitReview() {
            this.review.rating = +this.review.rating;

            const productId = parseInt(this.$route.params.id);
            const userId = this.$store.state.user.id;
            const firstName = this.$store.state.user.username;
            const createdAt = new Date();

            this.review.productId = productId;
            this.review.userId = userId;
            this.review.firstName = firstName;
            this.review.createdAt = createdAt;

            AuthService.postReviews(this.review)
                .then(response => {
                    const newReview = {
                        rating: this.review.rating,
                        firstName: this.review.firstName,
                        createdAt: this.review.createdAt,
                        isVerified: response.data.isVerified,
                        reviewText: this.review.reviewText
                    };
                    this.reviews.unshift(newReview);
                    console.log('Response:', response.data);
                    this.review.rating = null;
                    this.review.reviewText = '';
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }
};
</script>

  
<style scoped>
.review-container {
    margin-top: 60px;
    margin-bottom: 1rem;
}

.flex-container {
    display: flex;
    justify-content: space-between;
    max-width: 52%;
    margin: 0 auto;
}

.reviews-form {
    width: 48%;
}

.previous-reviews {
    width: 52%;
}

form {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 80%;
    margin: 0 auto;
}

label {
    font-weight: bold;
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
}

input,
select,
textarea {
    margin-bottom: 1rem;
    padding: 8px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
    width: 100%;
    transition: border-color 0.3s;
}

input:focus,
select:focus,
textarea:focus {
    outline: none;
    border-color: #4caf50;
}

button[type="submit"] {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 4px;
    font-weight: bold;
    background-color: #000000;
    color: #e4c364;
    border: none;
    cursor: pointer;
    width: 84%;
    transition: background-color 0.3s;
}

button[type="submit"]:hover {
    background-color: #e4c364;
    color: #000000;
    border: 1px solid #000000;
}

.reviewers-create-date,
.reviewers-isverified {
    color: #888;
    font-size: 0.9rem;
}

.previous-reviews-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

.review {
    width: 100%;
    margin-bottom: 0.6rem;
    padding: 1rem;
    border: 1px solid #ffc107;
    text-align: center;
    transition: box-shadow 0.3s;
}

.review:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.reviewers-name {
    font-weight: bold;
    margin-bottom: 0.5rem;
    font-size: 1.1rem;
    color: #000000;
}

.review-details {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.review-rating {
    font-size: 1.2rem;
}

.star {
    color: #ffc107;
}

.review-text {
    margin-bottom: 0;
    color: #000000;
    font-size: 1rem;
}

header {
    text-align: center;
    font-size: 1.6rem;
    margin-bottom: -1.6rem;
    color: #000000;
}

.review-header:before {
    content: '';
    position: absolute;
    width: 45px;
    height: 15px;
    border-bottom: 4px solid;
    top: 22px;
    margin-left: -60px;
}

.review-header:after {
    content: '';
    position: absolute;
    width: 45px;
    height: 15px;
    border-bottom: 4px solid;
    top: 23px;
    margin-left: 15px;
}

.review-header {
    font-family: Muli-Semi-Bold;
    color: #000;
    position: relative;
    text-transform: capitalize;
    text-align: center;
    font-size: 3rem;
}

.view-more-button {
    background-color: #000000;
    color: #e4c364;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 4px;
    font-weight: bold;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s;
    margin-bottom: 1rem;
}

.view-more-button:hover {
    background-color: #e4c364;
    color: #000000;
    border: 1px solid #000000;
}

@media screen and (max-width: 450px) {
  .review-header {
    font-size: 2rem;
  }

 .review-header:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 15px;
    border-bottom: 4px solid;
    top: 12px;
    margin-left: -14px;
  }

  .review-header:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 15px;
    border-bottom: 4px solid;
    top: 12px;
    margin-left: 10px;
  }

  .flex-container {
    display: flex;
    flex-direction: column;
    max-width: fit-content;
  }

  .reviews-form {
    width: 100%;
  }

  .previous-reviews {
    width: 100%;
    margin-top: 1rem;
}
}
</style>
  