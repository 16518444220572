<template>
  <div class="password-reset-container">
    <form class="password-reset-form" @submit.prevent="resetPassword">
      <label for="newPassword">New Password</label>
      <input type="password" id="newPassword" v-model="newPassword" required>

      <label for="confirmNewPassword">Confirm New Password</label>
      <input type="password" id="confirmNewPassword" v-model="confirmNewPassword" required>

      <div v-if="passwordsDoNotMatch" class="error-message-reset">
        Passwords do not match
      </div>

      <div v-if="validationErrors.length > 0" class="error-message-reset">
        <ul>
          <li v-for="error in validationErrors" :key="error">
            <span class="bullet-point">&#8226;</span> {{ addDotToErrorMessage(error) }}
          </li>
        </ul>
      </div>

      <div v-if="successMessage" class="success-message">
        {{ successMessage }}
      </div>

      <button type="submit">Reset Password</button>
    </form>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';

export default {
  data() {
    return {
      newPassword: '',
      confirmNewPassword: '',
      passwordsDoNotMatch: false,
      validationErrors: [],
      successMessage: ''
    };
  },
  methods: {
    resetPassword() {
      if (this.newPassword !== this.confirmNewPassword) {
        this.passwordsDoNotMatch = true;
        return;
      }

      this.passwordsDoNotMatch = false;
      this.validationErrors = [];
      this.successMessage = '';

      const resetPasswordDto = {
        resetToken: this.$route.query.token,
        newPassword: this.newPassword,
        confirmNewPassword: this.confirmNewPassword
      };

      AuthService.resetPassword(resetPasswordDto)
        .then(response => {
          if (response.status === 200) {
            this.$router.push('/login');
            this.successMessage = 'Password reset successfully';
            this.newPassword = '';
            this.confirmNewPassword = '';
          }
        })
        .catch(error => {
          const response = error.response;
          this.validationErrors = [];
          if (response.status === 400) {
            if (response.data && response.data.message) {
              this.validationErrors.push(response.data.message);
            } else if (response.data && response.data.errors) {
              this.validationErrors = response.data.errors;
            } else {
              this.validationErrors.push('Bad Request: Validation Errors');
            }
          }
        });
    },
    addDotToErrorMessage(error) {
      return error + ".";
    }
  }
};
</script>

  
<style scoped>
.password-reset-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.password-reset-form {
  max-width: 320px;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
  border: 2px solid #000000;
  background-color: #e4c364;
  color: #000000;
}

.password-reset-form label {
  margin-bottom: 8px;
}

.password-reset-form input {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.password-reset-form button {
  width: 100%;
  padding: 8px;
  background-color: #000000;
  color: #e4c364;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.password-reset-form button:hover {
  background-color: #e4c364;
  color: #000000;
  border: 1px solid #000000;
}

.error-message-reset {
  font-size: 12px;
  margin-bottom: 10px;
  color: #ee0d0d;
  width: 100%;
}
</style>
  