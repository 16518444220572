<template>
  <div class="help-tag">
    <div class="pop-up-info" v-if="showPopUp">
      <div class="pop-up-icon">
        <i class="fa-sharp fa-solid fa-phone" @click="callNumber"></i>
      </div>
      <div class="pop-up-icon">
        <i class="fa-brands fa-facebook-messenger" @click="openMessenger"></i>
      </div>
      <div class="pop-up-icon">
        <i class="fa-brands fa-whatsapp" @click="openWhatsApp"></i>
      </div>
      <div class="pop-up-icon">
        <i class="fa-solid fa-envelope" @click="sendEmail"></i>
      </div>
    </div>
    <p class="help-tag-p" @click="togglePopUp">Help?</p>
  </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        showPopUp: false
      };
    },
    methods: {
      togglePopUp() {
        this.showPopUp = !this.showPopUp;
      },
      callNumber() {
        window.location.href = 'tel:412-819-8691';
      },
      openMessenger() {
        window.open('https:/m.me/jewellerynepal', '_blank');
      },
      openWhatsApp() {
      window.open('https://wa.me/14128198691', '_blank');
      },
      sendEmail() {
        window.location.href = 'mailto:info@jewelrynepal.com';
      }
    }
  };
  </script>  

<style>
.help-tag {
    position: fixed;
    bottom: 10px;
    right: -38px;
    z-index: 12;
    width: 90px;
    cursor: pointer;
    color: #e4c364;
    text-align: center;
    padding: 5px;
}

.help-tag-p {
  font-size: 10px;
  font-weight: 700;
  background-color: #000000;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #e4c364;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  border: 2px solid #e4c364;
  transition: transform 0.3s;
}

.help-tag-p:hover {
  transform: scale(1.1);
}

.pop-up-info {
  display: flex;
  flex-direction: column; 
  align-items: center; 
  margin-right: 10px;
}

.pop-up-icon {
  margin-bottom: 10px; 
  margin-right: 2rem;
}

.pop-up-icon i {
    font-size: 28px;
}

.fa-phone {
    color: #f50404;
}

.fa-facebook-messenger {
    color: #277eff;
}

.fa-whatsapp {
    color: rgb(42 179 24);;
}

.pop-up-icon i {
  font-size: 28px;
  transition: transform 0.3s;
}

.pop-up-icon i:hover {
  transform: scale(1.5);
}
</style>