var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.isAuthenticated && _vm.orderTracks.length > 0)?_c('div',{staticClass:"horizontal timeline track-order-container"},[_c('div',{staticClass:"steps"},[_c('h1',{staticClass:"tracker-header"},[_vm._v("Order Tracker")]),_vm._l((_vm.orderTracks),function(order){return _c('div',{key:order.orderId,staticClass:"order-details"},[_c('span',{staticClass:"order-name-id"},[_vm._v(_vm._s(order.name)+", Order No: "),_c('strong',[_vm._v("#"+_vm._s(order.orderId))])])])}),_vm._l((_vm.orderStatuses.slice(0, 4)),function(status,index){return _c('div',{key:status,staticClass:"step",class:{
      current: _vm.orderTracks[0]?.orderStatus === status, 
      'step-pending': index === 0,
      'step-in-progress': index === 1,
      'step-ready': index === 2,
      'step-complete': index === 3
    }},[_c('span',{staticClass:"order-status"},[_vm._v(_vm._s(_vm.getStatusCaption(status)))])])})],2),_c('div',{staticClass:"line"})]):_c('div',[_c('img',{staticClass:"sales-ad-image",attrs:{"src":"https://images.fineartamerica.com/images/artworkimages/mediumlarge/2/golden-mountain-sunrise-panorama-fotovoyager.jpg"}}),_vm._m(0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sales-ad-text"},[_c('p',{staticClass:"animate__heartBeat"},[_vm._v("We are coming Soon!")])])
}]

export { render, staticRenderFns }