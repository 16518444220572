<template>
  <div class="product-details-container">
    <h1 class="product-detail-header">Product Details</h1>
    <div class="product-details-grid">
      <div class="image-container">
        <div class="product-detail-image-container">
          <div class="xzoom-container">
            <div id='lens'></div>
            <div id='slideshow-items-container' ref="itemRef">
              <img class='slideshow-items active' :src="productDetail.imageUrl">
              <img class='slideshow-items'
                src='https://images.unsplash.com/photo-1539341029274-a659646db47c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80'>
              <img class='slideshow-items'
                src='https://images.unsplash.com/photo-1512486130939-2c4f79935e4f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=80'>
            </div>
            <div id='result'></div>
            <!-- <div class='row'>
              <img class='slideshow-thumbnails active' :src="productDetail.imageUrl">
              <img class='slideshow-thumbnails' src='https://images.unsplash.com/photo-1539341029274-a659646db47c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=100&q=80'>
              <img class='slideshow-thumbnails' src='https://images.unsplash.com/photo-1512486130939-2c4f79935e4f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=100&q=80'>
            </div> -->
          </div>
        </div>
      </div>
      <div class="product-details-details">
        <p class="productDetail-name"><strong>Name:</strong> {{ productDetail.name }}</p>
        <p class="productDetail-goldPurity"><strong>Gold Purity:</strong> {{ productDetail.goldPurity }}</p>
        <p class="productDetail-stoneWeight"><strong>Stone Weight:</strong> {{ productDetail.stoneWeight }}</p>
        <p class="productDetail-productType"><strong>Product Type:</strong> {{ productDetail.productType }}</p>
        <p class="productDetail-quantity"><strong>In Stock:</strong> {{ productDetail.quantity }}</p>
        <p class="productDetail-gender"><strong>Gender:</strong> {{ productDetail.gender }}</p>
        <p class="productDetail-netWeight"><strong>Net Weight:</strong> {{ productDetail.netWeight }}</p>
        <p class="productDetail-length"><strong>Length:</strong>{{ productDetail.length }}</p>
        <p class="productDetail-width"><strong>Width:</strong> {{ productDetail.width }}</p>
        <p class="productDetail-stockStatus"><strong>Stock Status:</strong> {{ productDetail.stockStatus }}</p>
        <p class="productDetail-instruction"><strong>Description:</strong> {{ productDetail.description }}</p>
        <p class="productDetail-price">
          <strong>Price:</strong>
          <span class="price-dollar"> $</span>
          <span class="price-integer">{{ Math.floor(productDetail.price) }}</span>
          <span class="price-decimal">.{{ (productDetail.price % 1).toFixed(2).slice(-2) }}</span>
        </p>
      </div>
    </div>
    <div v-if="itemAlreadyAddedToLike" class="wishlist-alert" ref="wishlistAlert">
      <p><i class="fa-solid fa-triangle-exclamation"></i> Item already added to wishlist</p>
    </div>
    <div class="bottom-bar">
      <button class="add-to-checkout" @click="addToCartAndRedirect">Buy Now</button>
      <button class="add-to-cart-button" @click="addProductTocart">Add to Cart</button>
      <button class="add-to-fav-button" @click="addProductToLike">Add to Wishlist</button>
      <button @click="$router.push({ name: 'reviews', params: { productId: productDetail.id } })">
        <template v-if="productDetail.averageRating > 0">
          <span class="star" v-for="star in averageRatingStars(productDetail.averageRating)" :key="star">&#9733;</span>
          ({{ productDetail.totalRaters }})
          <p class="review-text">Reviews</p>
        </template>
        <template v-else>
          <span>No ratings yet</span>
        </template>
      </button>
    </div>
    <div>
      <p class="custom-alert"><i class="fa-sharp fa-solid fa-circle-exclamation"></i> Customization can be done at the
        checkout.</p>
    </div>
  </div>
</template>
  
<script>
import AuthService from '@/services/AuthService';
import router from '@/router';

import $ from 'jquery';
import 'jquery.easing';
import 'jquery-ui-dist/jquery-ui';

import { eventBus } from '@/main';

export default {
  data() {
    return {
      productDetail: {},
      id: 0,
      itemAlreadyAddedToLike: false,
      currentIndex: 0
    };
  },
  created() {
    const id = this.$route.params.id;
    AuthService.getProudctDetails(id).then((response) => {
      this.productDetail = response.data;
    });
  },
  mounted() {
    this.initImageZoom();
    this.initSlideChange();
  },
  beforeDestroy() {
    // Deactivate the code here
    $('.slideshow-thumbnails').off('hover');
    $(document).off('mousemove');
  },
  computed: {
    cartItemsCount() {
      return this.$store.state.cart.cartItemsCount;
    }
  },
  methods: {
    animateProductToCart() {
      var cart = $('.cart-container-count');
      var imgtodrag = $(this.$refs.itemRef).find('img').eq(0);

      if (imgtodrag.length) {
        var imgclone = imgtodrag
          .clone()
          .offset({
            top: imgtodrag.offset().top,
            left: imgtodrag.offset().left
          })
          .css({
            opacity: '0.5',
            position: 'absolute',
            height: '400px',
            width: '400px',
            'z-index': '100'
          })
          .appendTo($('body'))
          .animate(
            {
              top: cart.offset().top + 10,
              left: cart.offset().left + 10,
              width: 25,
              height: 25,
            },
            1000,
            'easeInOutExpo',
            function () {
              $(this).detach();
            }
          )
          .addClass('flying-img');

        setTimeout(() => {
          cart.effect('shake', {
            times: 2
          }, 200);
        }, 1500);

        imgclone.animate(
          {
            width: 0,
            height: 0
          },
          function () {
            $(this).detach();
          }
        );
      }
      eventBus.$emit('itemAddedToCart');
    },
    averageRatingStars(averageRating) {
      const roundedRating = Math.round(averageRating);
      return Array(roundedRating).fill('');
    },
    async addProductTocart() {
      if (this.$store.state.user.id) {

        //this.animateProductToCart();
        if (!this.isSmallScreen()) {
          this.animateProductToCart();
        }

        console.log("Product clicked:", this.productDetail.name);
        const itemName = this.productDetail.name;
        const itemPrice = this.productDetail.price;
        const existingItem = this.$store.state.cart.cartItems.find(item => item.name === itemName);
        const id = this.$route.params.id;

        const userId = this.$store.state.user.id;

        const cartData = {
          itemsList: [{
            type: this.productDetail.productType,
            name: itemName,
            quantity: 1,
            price: itemPrice,
            productId: id
          }],
          userId: userId
        };
        const response = await AuthService.addToCart(cartData);
        response.data;
        if (existingItem) {
          const updatedItem = { ...existingItem };
          updatedItem.quantity = 1;

          const updatedCartItems = this.$store.state.cart.cartItems.map(item => {
            if (item.name === itemName) {
              return updatedItem;
            }
            return item;
          });

          this.$store.commit('CLEAR_CART');
          updatedCartItems.forEach(item => {
            this.$store.commit('ADD_TO_CART', item);
          });

          this.$store.commit('setCartItemsCount', userId);
        } else {
          const item = {
            type: this.productDetail.productType,
            name: itemName,
            quantity: 1,
            price: this.productDetail.price,
            productId: parseInt(this.$route.params.id)
          };

          // const count = this.$store.state.cart.cartItems.length;
          this.$store.commit('ADD_TO_CART', item);
          this.$store.dispatch('setCartItemsCount', userId);
        }
      }
      else {
        sessionStorage.setItem('redirectURL', this.$route.path);
        router.push({ name: 'login' });
      }
    },
    animateProductToLike() {
      var cart = $('.like-container-count');
      var imgtodrag = $(this.$refs.itemRef).find('img').eq(0);

      if (imgtodrag.length) {
        var imgclone = imgtodrag
          .clone()
          .offset({
            top: imgtodrag.offset().top,
            left: imgtodrag.offset().left
          })
          .css({
            opacity: '0.5',
            position: 'absolute',
            height: '400px',
            width: '400px',
            'z-index': '100'
          })
          .appendTo($('body'))
          .animate(
            {
              top: cart.offset().top + 10,
              left: cart.offset().left + 10,
              width: 25,
              height: 25
            },
            1000,
            'easeInOutExpo'
          );

        setTimeout(() => {
          cart.effect('shake', {
            times: 2
          }, 200);
        }, 1500);

        imgclone.animate(
          {
            width: 0,
            height: 0
          },
          function () {
            $(this).detach();
          }
        );
      }
      eventBus.$emit('itemAddedToCart');
    },
    async addProductToLike() {
      if (this.$store.state.user.id) {
        const itemName = this.productDetail.name;
        const itemPrice = this.productDetail.price;
        const existingItem = this.$store.state.like.likeItems.find(item => item.name === itemName);

        if (existingItem) {
          this.itemAlreadyAddedToLike = true;
          this.showWishlistAlert();
          this.animateWishlistAlert();
          return;
        }
        //this.animateProductToLike();
        if (!this.isSmallScreen()) {
          this.animateProductToLike();
        }

        const userId = this.$store.state.user.id;
        const likeData = {
          type: this.productDetail.productType,
          name: itemName,
          quantity: 1,
          price: itemPrice,
          userId: userId
        };
        const response = await AuthService.postLikeItems(likeData);
        response.data;
        if (existingItem) {
          const updatedItem = { ...existingItem };
          updatedItem.quantity = 1;

          const updatedLikeItems = this.$store.state.like.likeItems.map(item => {
            if (item.name === itemName) {
              return updatedItem;
            }
            return item;
          });

          updatedLikeItems.forEach(item => {
            this.$store.commit('ADD_TO_LIKE', item);
          });

          this.$store.commit('setLikeItemsCount', userId);
        } else {
          const item = {
            type: this.productDetail.productType,
            name: itemName,
            quantity: 1,
            price: this.productDetail.price
          };

          this.$store.commit('ADD_TO_LIKE', item);
          this.$store.dispatch('setLikeItemsCount', userId);
        }
      }
      else {
        sessionStorage.setItem('redirectURL', this.$route.path);
        router.push({ name: 'login' });
      }
    },
    isSmallScreen() {
      return window.innerWidth <= 768;
    },
    hideWishlistAlert() {
      this.itemAlreadyAddedToLike = false;
    },
    showWishlistAlert() {
      this.itemAlreadyAddedToLike = true;
      setTimeout(this.hideWishlistAlert, 5000);
    },
    animateWishlistAlert() {
      const wishlistAlert = this.$refs.wishlistAlert;
      wishlistAlert.classList.add('vibrate');
      setTimeout(() => {
        wishlistAlert.classList.remove('vibrate');
      }, 1000);
    },
    async addToCartAndRedirect() {
      if (this.$store.state.user.id) {
        await this.addProductTocart();
        this.$router.push({ name: 'checkout' });
      } else {
        sessionStorage.setItem('redirectURL', this.$route.path);
        this.$router.push({ name: 'login' });
      }
    },
    //Image zoom lens
    initImageZoom() {
      const img = $('.slideshow-items.active');
      const result = $('#result');
      const lens = $('#lens');

      result.width(img.innerWidth());
      result.height(img.innerHeight());
      lens.width(img.innerWidth() / 2);
      lens.height(img.innerHeight() / 2);

      result.offset({
        top: img.offset().top,
        left: img.offset().left + img.outerWidth() + 10
      });

      const cx = img.innerWidth() / lens.innerWidth();
      const cy = img.innerHeight() / lens.innerHeight();

      result.css('backgroundImage', 'url(' + img.attr('src') + ')');
      result.css('backgroundSize', img.width() * cx + 'px ' + img.height() * cy + 'px');

      lens.mousemove((e) => {
        this.moveLens(e);
      });

      img.mousemove((e) => {
        this.moveLens(e);
      });

      lens.on('touchmove', () => {
        this.moveLens();
      });

      img.on('touchmove', () => {
        this.moveLens();
      });
    },
    moveLens(e) {
      const img = $('.slideshow-items.active');
      const lens = $('#lens');
      const result = $('#result');

      let x = e.clientX - lens.outerWidth() / 2;
      let y = e.clientY - lens.outerHeight() / 2;

      if (x > img.outerWidth() + img.offset().left - lens.outerWidth()) {
        x = img.outerWidth() + img.offset().left - lens.outerWidth();
      }

      if (x < img.offset().left) {
        x = img.offset().left;
      }

      if (y > img.outerHeight() + img.offset().top - lens.outerHeight()) {
        y = img.outerHeight() + img.offset().top - lens.outerHeight();
      }

      if (y < img.offset().top) {
        y = img.offset().top;
      }

      lens.offset({ top: y, left: x });
      result.css('backgroundPosition', '-' + (x - img.offset().left) + 'px -' + (y - img.offset().top) + 'px');
    },

    initSlideChange() {
      $('.slideshow-thumbnails').hover((e) => {
        const index = $(e.target).index();
        this.changeSlide(index);
      });

      $(document).mousemove((e) => {
        const x = e.clientX;
        const y = e.clientY;

        const imgx1 = $('.slideshow-items.active').offset().left;
        const imgx2 = $('.slideshow-items.active').outerWidth() + imgx1;
        const imgy1 = $('.slideshow-items.active').offset().top;
        const imgy2 = $('.slideshow-items.active').outerHeight() + imgy1;

        if (x > imgx1 && x < imgx2 && y > imgy1 && y < imgy2) {
          $('#lens').show();
          $('#result').show();
          this.imageZoom($('.slideshow-items.active'), $('#result'), $('#lens'));
        } else {
          $('#lens').hide();
          $('#result').hide();
        }
      });
    },
    imageZoom(img, result, lens) {
      if (!this.isSmallScreen()) {
        result.width(img.innerWidth());
        result.height(img.innerHeight());
        lens.width(img.innerWidth() / 2);
        lens.height(img.innerHeight() / 2);

        result.offset({ top: img.offset().top, left: img.offset().left + img.outerWidth() + 10 });

        const cx = img.innerWidth() / lens.innerWidth();
        const cy = img.innerHeight() / lens.innerHeight();

        result.css('backgroundImage', 'url(' + img.attr('src') + ')');
        result.css('backgroundSize', img.width() * cx + 'px ' + img.height() * cy + 'px');

        lens.mousemove((e) => {
          this.moveLens(e);
        });
        img.mousemove((e) => {
          this.moveLens(e);
        });
        lens.on('touchmove', () => {
          this.moveLens();
        });
        img.on('touchmove', () => {
          this.moveLens();
        });
      }
    },
    changeSlide(elm) {
      $('.slideshow-items').removeClass('active');
      $('.slideshow-items').eq(elm).addClass('active');
      $('.slideshow-thumbnails').removeClass('active');
      $('.slideshow-thumbnails').eq(elm).addClass('active');
    }
  },
};
</script>
  
<style>
.cart-icon {
  position: relative;
}

.product-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
}

.bottom-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6px;
  gap: 1rem;
}

.bottom-bar button {
  width: 110px;
  padding: 5px;
  height: 3.4rem;
  font-size: 1rem;
  border-radius: 4px;
  background-color: #000000;
  color: #e4c364;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.bottom-bar button:hover {
  background-color: #e4c364;
  color: #000000;
  border: 1px solid #000000;
}

.router-link {
  text-decoration: none;
}

.product-details-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.image-container {
  width: 25%;
}

.product-detail-image-container {
  display: flex;
  justify-content: center;
  align-items: start;
  width: 450px;
}

.product-image {
  width: 100%;
  height: auto;
}

.product-details-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.3rem;
  color: #000000;
  width: 450px;
}

.wishlist {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cart {
  display: flex;
  flex-direction: column;
  align-items: center;
}

p {
  font-size: 1.2rem;
}

.product-detail-header:before {
  content: '';
  position: absolute;
  width: 45px;
  height: 15px;
  border-bottom: 4px solid;
  top: 22px;
  margin-left: -60px;
}

.product-detail-header:after {
  content: '';
  position: absolute;
  width: 45px;
  height: 15px;
  border-bottom: 4px solid;
  top: 23px;
  margin-left: 15px;
}

.product-detail-header {
  font-family: Muli-Semi-Bold;
  color: #000;
  position: relative;
  text-transform: capitalize;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 0.4rem;
}

.custom-alert {
  margin-top: 8px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 1.3rem;
}

.productDetail-price {
  font-size: 1.2rem;
}

.price-dollar {
  font-size: 1.6rem;
  margin: 2px;
}

.price-integer {
  font-size: 2.7rem;
  font-weight: 600;
}

.price-decimal {
  font-size: 2rem;
}

.flying-img {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5;
  animation: fly 0.8s 1;
  -webkit-animation: fly 0.8s 1;
}

@keyframes fly {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes fly {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

.vibrate {
  animation: vibrate 0.3s linear infinite;
}

@keyframes vibrate {
  0% {
    transform: translateX(-2px) translateY(-2px) rotate(0deg);
  }

  25% {
    transform: translateX(2px) translateY(2px) rotate(0deg);
  }

  50% {
    transform: translateX(-2px) translateY(-2px) rotate(0deg);
  }

  75% {
    transform: translateX(2px) translateY(2px) rotate(0deg);
  }

  100% {
    transform: translateX(-2px) translateY(-2px) rotate(0deg);
  }
}

.wishlist-alert {
  background-color: #f8f8f8;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  align-items: center;
}

.wishlist-alert p {
  margin: 0;
  padding-left: 10px;
  font-size: 14px;
  color: #333;
}

.wishlist-alert i {
  color: #f44336;
  font-size: 18px;
}

.wishlist-alert:hover {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.slideshow-items {
  width: 500px;
}

.slideshow-thumbnails {
  width: 100px;
}

#slideshow-items-container {
  display: inline-block;
  position: relative;
}

#lens {
  background-color: rgba(233, 233, 233, 0.4)
}

#lens,
#result {
  position: absolute;
  display: none;
  z-index: 1;
}

.slideshow-items {
  display: none;
}

.slideshow-items.active {
  display: block;
}

.slideshow-thumbnails {
  opacity: 0.5;
}

.slideshow-thumbnails.active {
  opacity: 1;
}

#lens,
.slideshow-items,
.slideshow-thumbnails,
#result {
  border: solid var(--light-grey-2) 1px;
}

.row .slideshow-thumbnails {
  margin: 5px;
  border: 1px solid #e4c364;
}

span.star {
  font-size: 14px;
}

@media screen and (max-width: 830px) {
  .product-detail-image-container {
  width: 350px;
  margin-left: 16px;
}

.product-details-details {
  width: 360px;
}
}

@media screen and (max-width: 450px) {

  .product-detail-header {
    font-size: 2rem;
  }

  .product-details-container {
    margin-top: 80px;
  }
  .product-detail-header:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 15px;
    border-bottom: 4px solid;
    top: 12px;
    margin-left: -14px;
  }

  .product-detail-header:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 15px;
    border-bottom: 4px solid;
    top: 12px;
    margin-left: 10px;
  }

  .product-details-grid {
    grid-template-columns: 1fr;
  }

  .image-container {
    width: 100%;
    order: 1;
  }

  .product-details-details {
    order: 2;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-start;
    gap: 0.3rem;
    color: #000000;
    width: 400px;
    margin-left: 3rem;
  }

  .product-detail-image-container {
    max-width: 100%;
  }

  .xzoom-container {
    max-width: 100%;
  }

  .productDetail-name {
    font-size: 18px;
  }

  .productDetail-price {
    font-size: 16px;
  }

  .productDetail-price .price-dollar {
    font-size: 14px;
  }

  .productDetail-price .price-integer {
    font-size: 18px;
  }

  .productDetail-price .price-decimal {
    font-size: 14px;
  }

  .productDetail-name,
  .productDetail-price,
  .productDetail-price .price-dollar,
  .productDetail-price .price-integer,
  .productDetail-price .price-decimal {
    text-align: center;
  }

  .productDetail-buttons {
    display: flex;
    justify-content: center;
  }

  .productDetail-buttons button {
    margin: 0 5px;
  }

  .bottom-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 6px;
    gap: 5px;
  }

  .bottom-bar button {
    width: 90px;
    padding: 5px;
    height: 3.4rem;
    font-size: 1rem;
    border-radius: 4px;
    background-color: #000000;
    color: #e4c364;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .custom-alert {
    text-align: center;
  }

  p.review-text {
    font-size: 14px;
  }

  span.star {
    font-size: 10px;
  }

  #slideshow-items-container {
    margin-left: 2.4rem;
    margin-right: 2.4rem;
  }
}
</style>
  