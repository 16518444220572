import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import cart from './cart'
import like from './like'
import AuthService from '@/services/AuthService'

Vue.use(Vuex)

/*
 * The authorization header is set for axios when you login but what happens when you come back or
 * the page is refreshed. When that happens you need to check for the token in local storage and if it
 * exists you should set the header so that it will be attached to each request
 */
const currentToken = localStorage.getItem('token') 
const currentUser = JSON.parse(localStorage.getItem('user')); 

if(currentToken != null) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${currentToken}`;
}

export default new Vuex.Store({
  modules: {
    cart,
    like,
  },
  state: {
    token: currentToken || '',
    user: currentUser || {},
    cartItemsCount: 0,
    likeItemsCount: 0,
  },
  mutations: {
    async SET_AUTH_TOKEN(state, token) {
      state.token = token;
      localStorage.setItem('token', token);
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  
      try {
        await this.dispatch('setCartItemsCount', state.user);
      } catch (error) {
        console.log('Error while fetching cart items count:', error);
      }
    },
    SET_USER(state, user) {
      state.user = user;
      localStorage.setItem('user',JSON.stringify(user));
      this.dispatch('setCartItemsCount'); // Dispatch the action after setting the user data
      this.dispatch('setLikeItemsCount'); // Dispatch the action after setting the user data

    },
    LOGOUT(state) {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      state.token = '';
      state.user = {};
      state.cartItemsCount = 0;
      state.likeItemsCount = 0;
      axios.defaults.headers.common = {};
    },
    SET_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count;
    },
    SET_LIKE_ITEMS_COUNT(state, count) {
      state.likeItemsCount = count;
    },
  },
  actions: {
    async setCartItemsCount({ commit, rootState }) {
      try {
        let userId = rootState.user.id;
        if( !userId) {
          userId = 1;
        }
        const countResponse = await AuthService.getCartItemsCount(userId);
        const count = countResponse.data;
        commit('SET_CART_ITEMS_COUNT', count);
      } catch (error) {
        console.log('Error while fetching cart items count:', error);
      }
    },
    async setLikeItemsCount({ commit, rootState }) {
      try {
        let userId = rootState.user.id;
        if( !userId) {
          userId = 1;
        }
        const countResponse = await AuthService.getCountLikedItem(userId);
        const count = countResponse.data;
        commit('SET_LIKE_ITEMS_COUNT', count);
      } catch (error) {
        console.log('Error while fetching Like items count:', error);
      }
    },
  },  
})
