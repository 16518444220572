<template>
  <div id="register" class="text-center">
    <div class="image-container-register">
      <router-link class="banner" to="/">
        <img class="banner" src="../assets/img/bannerimg.png">
      </router-link>
    </div>
    <div class="form-container">
      <form @submit.prevent="register" id="register-form">
        <h1 class="register-header">Create Account</h1>
        <div class="alert" v-if="registrationErrors">
          {{ registrationErrorMsg }}
        </div>
        <div class="form-input-group">
          <input type="text" id="username" v-model="user.username" placeholder="Username*" required autofocus />
        </div>
        <div class="form-input-group">
          <input type="text" id="firstName" v-model="user.firstName" placeholder="First Name*" required autofocus />
        </div>
        <div class="form-input-group">
          <input type="text" id="lastName" v-model="user.lastName" placeholder="Last Name*" required autofocus />
        </div>
        <div class="form-input-group">
          <vue-tel-input v-model="user.phoneNumber" v-bind="bindProps"></vue-tel-input>
        </div>
        <!-- <div class="form-input-group">
          <div class="phone-input-group">
            <select id="countryCode" v-model="user.countryCode" required>
              <option value="" disabled selected>Country Code</option>
              <option value="+1">+1 - US</option>
              <option value="+44">+44 - UK</option>
              <option value="+977">+977 - Nepal</option>
              <option value="+61">+61 - Australia</option>
              <option value="+91">+91 - India</option>
            </select>
            <input type="tel" id="phoneNumber" v-model="user.phoneNumber"
              placeholder="Phone Number*" required
              autofocus maxlength="16" @input="handlePhoneNumberInput" />

          </div>
        </div> -->

        <div class="form-input-group">
          <input type="email" id="email" v-model="user.email" placeholder="Email*" required autofocus />
        </div>
        <div class="form-input-group">
          <input type="password" id="password" v-model="user.password" placeholder="Password*" required />
        </div>
        <div class="form-input-group">
          <input type="password" id="confirmPassword" v-model="user.confirmPassword" placeholder="Confirm Password*"
            required />
        </div>
        <button class="signup" type="submit">Create Account</button>
        <p class="login-link"><router-link :to="{ name: 'login' }">Already have an account? Log in.</router-link></p>
      </form>
    </div>
  </div>
</template>

<script>

import authService from '../services/AuthService';




export default {
  name: 'registerUser',
  data() {
    return {
      user: {
        username: '',
        password: '',
        email: '',
        firstName: '',
        lastName: '',
        countryCode: '',
        phoneNumber: '',
        confirmPassword: '',
        role: 'user',
      },
      selectedCountryCode: '',
      registrationErrors: false,
      registrationErrorMsg: 'There were problems registering this user.',
      bindProps: {
        mode: "international",
        defaultCountry: "US",
        disabledFetchingCountry: false,
        disabled: false,
        disabledFormatting: false,
        placeholder: "Enter a phone number",
        required: true,
        enabledCountryCode: true,
        enabledFlags: true,
        preferredCountries: ["AU", "BR"],
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: "off",
        name: "telephone",
        maxLen: 16,
        wrapperClasses: "",
        inputClasses: "",
        dropdownOptions: {
          disabledDialCode: false
        },
        inputOptions: {
          showDialCode: false
        }
      }
    };
  },
 
  // computed: {
  
  //   formattedPhoneNumber() {
  //   //   const phoneNumber = this.user.phoneNumber;
  //   //   const numericPhoneNumber = phoneNumber.replace(/\D/g, '');

  //   //   if (numericPhoneNumber.length === 0) {
  //   //     // this.user.phoneNumber = '';
  //   //     // return;
  //   //     return '';
  //   //   }

  //   //   const countryCode = this.selectedCountryCode;
  //   //   const areaCode = numericPhoneNumber.substring(0, 3);
  //   //   const firstPart = numericPhoneNumber.substring(3, 6);
  //   //   const secondPart = numericPhoneNumber.substring(6, 10);

  //   //   return `${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
  //   //   // this.user.phoneNumber = `${countryCode} (${areaCode}) ${firstPart}-${secondPart}`;
  //   // },
  // },

  methods: {
  //   mounted() {
  //   $("#mobile_code").intlTelInput({
  //     initialCountry: "NP",
  //     separateDialCode: true,
  //     utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.0.4/js/utils.js"
  //   });
  // },
    // handlePhoneNumberInput() {
    //   const numericPhoneNumber = this.user.phoneNumber.replace(/\D/g, '');

    //   // const countryCode = this.selectedCountryCode;
    //   const areaCode = numericPhoneNumber.substring(0, 3);
    //   const firstPart = numericPhoneNumber.substring(3, 6);
    //   const secondPart = numericPhoneNumber.substring(6, 10);

    //   const formattedPhoneNumber = `(${areaCode}) ${firstPart}-${secondPart}`;
    //   this.user.phoneNumber = formattedPhoneNumber;
    // },

    // updateSelectedCountryCode() {
    //   if (this.user.countryCode === 'NPL') {
    //     this.selectedCountryCode = '+977';
    //   } else if (this.user.countryCode === 'US') {
    //     this.selectedCountryCode = '+1';
    //   } else if (this.user.countryCode === 'UK') {
    //     this.selectedCountryCode = '+44';
    //   } else if (this.user.countryCode === 'AUS') {
    //     this.selectedCountryCode = '+61';
    //   } else if (this.user.countryCode === 'IN') {
    //     this.selectedCountryCode = '+91';
    //   } else {
    //     this.selectedCountryCode = '';
    //   }

    //   // Format the phone number if it is already entered
    //   if (this.user.phoneNumber && this.user.countryCode) {
    //     const numericPhoneNumber = this.user.phoneNumber.replace(/\D/g, '');
    //     const formattedPhoneNumber = this.formatPhoneNumber(numericPhoneNumber);

    //     this.user.phoneNumber = formattedPhoneNumber;
    //   }
    // },
    register() {
      if (this.user.password !== this.user.confirmPassword) {
        this.registrationErrors = true;
        this.registrationErrorMsg = 'Password and Confirm Password do not match.';
      } else {
        // Sanitize inputs before sending to the server
        const sanitizedUser = {
          username: this.sanitizeString(this.user.username),
          password: this.user.password, // Server-side validation will handle password complexity requirements
          confirmPassword: this.user.confirmPassword,
          email: this.sanitizeString(this.user.email),
          firstName: this.sanitizeString(this.user.firstName),
          lastName: this.sanitizeString(this.user.lastName),
          phoneNumber: this.selectedCountryCode + this.user.phoneNumber,
          role: this.user.role,
        };

        // Phone number validation
        // const countryCodes = {
        //   US: /^\(\d{3}\) \d{3}-\d{4}$/,
        //   UK: /^\(\d{3}\) \d{3}-\d{4}$/,
        //   NPL: /^\(\d{3}\) \d{3}-\d{4}$/,
        //   AUS: /^\(\d{3}\) \d{3}-\d{4}$/,
        //   IN: /^\(\d{3}\) \d{3}-\d{4}$/,
        // };

        // const selectedCountryCode = this.user.countryCode || 'US';

        // if (!(selectedCountryCode in countryCodes)) {
        //   this.registrationErrors = true;
        //   this.registrationErrorMsg = 'Invalid country code.';
        //   return;
        // }

        // const phoneNumberRegex = countryCodes[selectedCountryCode];
        // if (!phoneNumberRegex.test(this.user.phoneNumber)) {
        //   this.registrationErrors = true;
        //   this.registrationErrorMsg = 'Invalid phone number format.';
        //   return;
        // }

        authService
          .register(sanitizedUser)
          .then((response) => {
            if (response.status === 201) {
              this.$router.push({
                path: '/login',
                query: { registration: 'success' },
              });
            }
          })
          .catch((error) => {
            const response = error.response;
            this.registrationErrors = true;
            if (response.status === 400) {
              if (response.data && response.data.message) {
                this.registrationErrorMsg = response.data.message;
              } else {
                this.registrationErrorMsg = 'Bad Request: Validation Errors';
              }
            }
          });
      }
    },
    sanitizeString(value) {
      const encodedValue = value
        .replace(/&/g, '&amp;')
        .replace(/</g, '&lt;')
        .replace(/>/g, '&gt;')
        .replace(/"/g, '&quot;')
        .replace(/'/g, '&#39;');

      return encodedValue;
    },
    clearErrors() {
      this.registrationErrors = false;
      this.registrationErrorMsg = 'There were problems registering this user.';
    },
  },
};
</script>


<style scoped>
.register-header {
  font-size: 2rem;
  text-align: center;
  margin-top: -2rem;
  color: #000000;
}

#register {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(172, 0, 22, 1);
  margin: 0;
  padding: 0;
}

#register-form {
  background-color: #e4c364;
  padding: 2rem;
  margin: 0 auto;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 50px;
  width: 50%;
  border: 2px solid #000000;
}

label {
  margin-right: 0.5rem;
  color: #000000;
}

.image-container-register {
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: center;
}

.form-container {
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: start;
}

.banner {
  width: 90%;
  height: auto;
  display: flex;
  justify-content: end;
}

.signup {
  margin-top: 8px;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 4px;
  font-weight: bold;
  background-color: #000000;
  color: #e4c364;
  border: none;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"] {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 6px;
}

.signup:hover {
  background-color: #e4c364;
  color: #000000;
  border: 1px solid;
  border-collapse: #000000;
}

.alert {
  text-align: center;
  font-size: 0.8rem;
  color: rgb(164, 36, 36);
}

.login-link {
  margin-top: 0.2rem;
  text-align: center;
  color: #000000;
  margin-bottom: -1.3rem;
}

label {
  color: #000000;
  font-size: 0.9rem;
}

.phone-input-group {
  display: flex;
  align-items: start;
}

#countryCode {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 10px;
}

#phoneNumber {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

@media screen and (max-width: 450px) {
  #register {
    flex-direction: column;
    align-items: center;
  }

  .image-container-register {
    justify-content: left;
    align-items: center;
    width: 100%;
    margin-top: 8rem;
  }

  .form-container {
    width: 90%;
    justify-content: center;
    align-items: center;
  }

  #register-form {
    width: 100%;
    margin-bottom: 10rem;
  }
}

@media screen and (max-width: 380px) {
  #register {
    flex-direction: column;
    align-items: center;
  }

  .image-container-register {
    justify-content: left;
    align-items: center;
    width: 100%;
    margin-top: 4rem;
  }

  .form-container {
    width: 90%;
    justify-content: center;
    align-items: center;
  }

  #register-form {
    width: 100%;
    margin-bottom: 4rem;
  }
}
</style>
