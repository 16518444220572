<template>
    <div class="add-product-container">
        <h1 class="add-product-header">Add Products</h1>
        <div class="product-alert" v-if="addingProductErrors">
            {{ productErrorMessage }}
        </div>
        <div class="success-message-product">
            <p>{{ successMessage }}</p>
        </div>
        <form @submit.prevent="submitProduct" class="product-form">
            <div class="form-input-column-left">
                <label for="name">Product Name: </label>
                <div class="form-input">
                    <input type="text" id="name" v-model="name" required autofocus />
                </div>
                <label for="description">Product Description:</label>
                <div class="form-input">
                    <input type="text" id="description" v-model="description" required autofocus />
                </div>
                <label for="gender">Gender:</label>
                   <select id="gender" v-model="gender" required autofocus>
                       <option value="" disabled selected>Select Gender</option>
                       <option value="Male">Male</option>
                       <option value="Female">Female</option>
                       <option value="Male/Female">Male/Female</option>
                   </select>
                <label for="goldPurity">Gold Purity:</label>
                    <select id="goldPurity" v-model="goldPurity" required autofocus>
                        <option value="" disabled selected>Select Gold Type</option>
                        <option value="24K">24K</option>
                        <option value="22K">22K</option>
                        <option value="18K">18K</option>
                    </select>
                <div class="form-input">
                    <label for="imageUpload">Select Image: </label>
                    <input type="file" multiple="multiple" id="imageUpload" @change="handleImageUpload"
                        accept="image/jpeg, image/png, image/jpg" />
                    <output id="preview">
                        <div v-for="(preview, index) in imagePreviews" :key="index" class="image-preview-container">
                            <img :src="preview" class="image-preview" alt="Preview" />
                            <button class="preview-remove-button" @click="removeImage(index)"><i class="fa-sharp fa-solid fa-xmark"></i></button>
                        </div>
                    </output>
                </div>
                <label for="length">Length:</label>
                <div class="form-input">
                    <input type="text" id="length" v-model="length" required autofocus />
                </div>
                <label for="netWeight">Net Weight:</label>
                <div class="form-input">
                    <input type="text" id="netWeight" v-model="netWeight" required autofocus />
                </div>
            </div>
            <div class="form-input-column-right">
                <label for="price">Price:</label>
                <div class="form-input">
                    <input type="text" id="price" v-model="price" required autofocus />
                </div>
                <label for="quantity">In Stock:</label>
                <div class="form-input">
                    <input type="number" id="quantity" v-model="quantity" required autofocus />
                </div>
                <label for="stockStatus">Stock Status:</label>
                <div class="form-input">
                    <input type="text" id="stockStatus" v-model="stockStatus" required autofocus />
                </div>
                <label for="stoneWeight">Stone Weight:</label>
                <div class="form-input">
                    <input type="text" id="stoneWeight" v-model="stoneWeight" required autofocus />
                </div>
                <label for="width">Width:</label>
                <div class="form-input">
                    <input type="text" id="width" v-model="width" required autofocus />
                </div>
                <label for="availability">Product Type:</label>
                <div class="form-input">
                    <select id="productType" v-model="productType" required>
                        <option value="" disabled selected>Select Product Type</option>
                        <option value="Rani Haar">Rani Haar </option>
                        <option value="Rings">Rings</option>
                        <option value="Necklace">Necklace</option>
                        <option value="Bracelets">Bracelets</option>
                        <option value="Mangalsutra">Mangalsutra</option>
                    </select>
                </div>
                <label for="availability">Product Availability:</label>
                <div class="radio-input">
                    <input type="radio" id="availability-true" value="true" v-model="availability" />
                    <label for="availability-true">Available</label>
                </div>
                <div class="radio-input">
                    <input type="radio" id="availability-false" value="false" v-model="availability" />
                    <label for="availability-false">Not Available</label>
                </div>
            </div>
            <button type="summit" class="add-product-btn">Add Product</button>
        </form>
    </div>
</template>
  
<script>
import axios from 'axios';
import AuthService from '@/services/AuthService';
export default {
    data() {
        return {
            availability: true,
            description: '',
            gender: '',
            goldPurity: '',
            length: 0,
            name: '',
            netWeight: 0,
            price: 0,
            productType: '',
            quantity: 0,
            stockStatus: '',
            stoneWeight: 0,
            userId: 0,
            width: 0,
            successMessage: '',
            addingProductErrors: false,
            productErrorMessage: 'There were problems adding this product.',
            imageUpload: null,
            imagePreviews: [],
            imageUrl1: '',
            imageUrl2: '',
            imageUrl3: '',
        }
    },
    methods: {
        handleImageUpload(event) {
            const maxFileSize = 200 * 1024;
            const files = event.target.files;

            for (let i = 0; i < files.length; i++) {
                const file = files[i];
                if (file.size > maxFileSize) {
                    alert("File size exceeds the allowed limit of 200KB");
                    this.imageUpload = null;
                    return;
                }
            }

            // Clear the existing previews
            this.imagePreviews = [];

            // Generate previews for the selected images
            for (let i = 0; i < files.length; i++) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.imagePreviews.push(e.target.result);
                };
                reader.readAsDataURL(files[i]);
            }

            this.imageUpload = Array.from(files);
        },

        submitProduct() {
            const productData = {
                availability: this.availability,
                description: this.description,
                gender: this.gender,
                goldPurity: this.goldPurity,
                length: parseFloat(this.length),
                name: this.name,
                netWeight: parseFloat(this.netWeight),
                price: parseFloat(this.price),
                productType: this.productType,
                quantity: this.quantity,
                stockStatus: this.stockStatus,
                stoneWeight: parseFloat(this.stoneWeight),
                userId: this.$store.state.user.id,
                width: parseFloat(this.width)
            };

            AuthService.postProductData(productData)
                .then(response => {
                    this.clearForm();
                    this.successMessage = 'Product added successfully!';

                    const formData = new FormData();

                    for (let i = 0; i < this.imageUpload.length; i++) {
                        if (this.imageUpload[i]) {
                            formData.append('file', this.imageUpload[i]);
                        }
                    }

                    axios.post('https://jewelrynepal-88e76a13f73a.herokuapp.com/api/image/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        params: {
                            productId: response.data.productId
                        }
                    })
                        .then(response => {
                            console.log(response);
                            this.successMessage = 'Product added successfully!';

                            const imageUrls = response.data.urls;
                            this.imageUrl1 = imageUrls[0];
                            this.imageUrl2 = imageUrls[1];
                            this.imageUrl3 = imageUrls[2];
                        })
                        .catch(error => {
                            console.error('Unable to upload the image', error.response.data);
                        });
                })
                .catch(error => {
                    const response = error.response;
                    this.addingProductErrors = true;
                    if (response.status === 400) {
                        if (response.data && response.data) {
                            this.productErrorMessage = response.data;
                        } else {
                            this.productErrorMessage = "Bad Request: Validation Errors";
                        }
                    }
                });
        },
        removeImage(index) {
            this.imagePreviews.splice(index, 1);
            this.imageUpload.splice(index, 1);
            this.updateImageCount();
        },

        updateImageCount() {
            const count = this.imageUpload.length;
            const inputElement = document.getElementById('imageUpload');
            if (count > 0) {
                inputElement.files = this.imageUpload;
                inputElement.value = count + (count === 1 ? ' file' : ' files');
            } else {
                inputElement.value = ''; // Clear the value if no files selected
            }
        },
        clearForm() {
            this.availability = true;
            this.description = '';
            this.gender = '';
            this.goldPurity = '';
            this.length = 0;
            this.name = '';
            this.netWeight = 0;
            this.price = 0;
            this.productType = '';
            this.quantity = 0;
            this.stockStatus = '';
            this.stoneWeight = 0;
            this.width = 0;
        }
    }
}
</script>
  
<style>
.add-product-container {
    margin-top: 70px;
}

.product-alert {
    font-size: 1rem;
    font-weight: 800;
    margin-left: 4.6rem;
}

.radio-input {
    display: flex;
    align-items: center;
}

.radio-input input[type="radio"] {
    margin-right: 5px;
}

h1 {
    text-align: center;
    font-size: 24px;
    margin-bottom: 10px;
}

.product-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: 4rem;
    margin-right: 4rem;
}

.product-form label {
    font-weight: 600;
}

.form-input-column-left,
.form-input-column-right {
    flex: 1 1 50%;
    padding: 0 0.5rem;
    box-sizing: border-box;
}

.form-input {
    margin-bottom: 15px;
}

input[type="text"],
select,
input[type="number"] {
    padding: 8px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
}

select {
    width: 100%;
}

.add-product-btn {
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 4px;
    font-weight: bold;
    background-color: #000000;
    color: #e4c364;
    border: none;
    cursor: pointer;
    width: 30%;
    margin: 1rem;
    transition: background-color 0.3s;
}

.add-product-btn:hover {
    background-color: #e4c364;
    color: #000000;
    border: 1px solid #000000;
}

.success-message-product {
    margin-left: 4.6rem;
}

.success-message-product p {
    color: #4CAF50;
    font-size: 1rem;
    font-weight: 800;
}

.image-preview {
    display: flex;
    width: 150px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 10px;
    border: 1px solid #e4c364;
    margin: 5px;
}

.preview-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}

.preview-container::after {
    content: "";
    flex-grow: 9999;
}

#preview {
    display: flex;
}

.form-input {
    margin-bottom: 10px;
}

.image-preview-container {
  position: relative;
}

.image-container {
  position: relative;
  display: inline-block;
}

#preview .preview-remove-button {
    position: absolute;
    top: 6px;
    right: 7px;
    border: none;
    color: #ff0000;
    cursor: pointer;
    margin: 1px;
}

#imageUpload {
    color: #6f1094;
    font-weight: 700;
}

.add-product-header {
    font-weight: 700;
}

@media screen and (max-width: 450px) {
  .add-product-container {
    margin-top: 82px;
  }

  .product-form {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .form-input-column-left,
  .form-input-column-right {
    flex: 1 1 100%;
    padding: 0;
  }

  .add-product-btn {
    width: 100%;
  }
}
</style>