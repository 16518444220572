<template>
    <form @submit.prevent="placeOrder">
        <div class="custom-purchase-container">
            <div class="custom-purchase-main">
                <h1 class="custom-purchase-header">Custom Order</h1>
                <select id="productType" v-model="productType" required>
                    <option value="" disabled selected>Select Product Type</option>
                    <option value="Rani Haar">Rani Haar</option>
                    <option value="Rings">Rings</option>
                    <option value="Necklace">Necklace</option>
                    <option value="Bracelets">Bracelets</option>
                    <option value="Mangalsutra">Mangalsutra</option>
                    <option value="Choker">Choker</option>
                </select>
                <div class="input-container">
                    <input type="number" v-model="grams" @input="calculatePrice" class="grams-input" required :min="1"
                        autofocus>
                    <span class="input-label">/gram</span>
                </div>
                <p class="design-fee">Design Fee: <strong>${{ getDesignFee }}</strong></p>
                <p class="result-text">Total Price: ${{ totalPrice }}</p>
                <input type="text" v-model="customOrderName" placeholder="Name your order" class="custom-order-name"
                    required autofocus>
                <div class="upload-container">
                    <input type="file" class="file-input-custom" accept="image/*" required @change="handleFileInputChange">
                    <textarea class="custom-note" v-model="instructions"
                        placeholder="What design do you like? Please add your note here." required></textarea>
                    <button type="submit" class="custom-submit-button">Place Order</button>
                </div>
                <p class="details-text">We only deal with 24K gold. For more details, please give us a call.</p>
            </div>
        </div>
    </form>
</template> 
  
<script>
import AuthService from '@/services/AuthService';

export default {
    data() {
        return {
            grams: 0,
            totalPrice: 0,
            instructions: '',
            customOrderName: '',
            productType: '',
            file: null,
            goldRate: {
                ask: 0,
                bid: 0
            }
        };
    },
    created() {
        this.getLiveGoldRates();
    },
    computed: {
        getDesignFee() {
            let designFee = 0;
            switch (this.productType) {
                case 'Rings':
                    designFee = 200;
                    break;
                case 'Rani Haar':
                    designFee = 700;
                    break;
                case 'Necklace':
                    designFee = 400;
                    break;
                case 'Bracelets':
                    designFee = 250;
                    break;
                case 'Mangalsutra':
                    designFee = 350;
                    break;
                case 'Choker':
                    designFee = 350;
                    break;
                default:
                    designFee = 0;
                    break;
            }
            return designFee;
        },
    },
    methods: {
        calculatePrice() {
            if (this.grams > 0) {
                const pricePerGram = this.goldRate.ask;
                const totalPriceWithoutDesignFee = this.grams * (pricePerGram / 31.6);

                let designFee = 0;
                switch (this.productType) {
                    case 'Rings':
                        designFee = 200;
                        break;
                    case 'Rani Haar':
                        designFee = 700;
                        break;
                    case 'Necklace':
                        designFee = 400;
                        break;
                    case 'Bracelets':
                        designFee = 250;
                        break;
                    case 'Mangalsutra':
                        designFee = 350;
                        break;
                    case 'Choker':
                        designFee = 350;
                        break;
                    default:
                        designFee = 0;
                        break;
                }

                const totalPriceWithDesignFee = totalPriceWithoutDesignFee + designFee;
                this.totalPrice = totalPriceWithDesignFee.toFixed(2);
            } else {
                this.totalPrice = 0;
            }
        },
        placeOrder() {
            const totalPrice = this.totalPrice;
            const file = this.file;
            const instructions = this.instructions;
            const name = this.customOrderName;

            this.$router.push({
                name: 'checkout',
                query: {
                    totalPrice,
                    file,
                    instructions,
                    name,
                }
            });
        },
        handleFileInputChange(event) {
            const file = event.target.files[0];
            if (file) {
                this.file = file;
            } else {
                this.file = null;
            }
        },
        getLiveGoldRates() {
            AuthService.getLiveGoldRates()
                .then((response) => {
                    const goldRateDto = response.data;

                    this.goldRate.spreadProfile = goldRateDto.spreadProfile;
                    this.goldRate.bidSpread = goldRateDto.bidSpread;
                    this.goldRate.askSpread = goldRateDto.askSpread;
                    this.goldRate.bid = goldRateDto.bid;
                    this.goldRate.ask = goldRateDto.ask;

                    this.calculatePrice();
                })
                .catch((error) => {
                    if (error.response.status === 429) {
                        this.errorMessage = "Too many requests. Please try again later.";
                    } else {
                        this.errorMessage = "An error occurred while fetching the gold rate.";
                    }
                });
        }
    }
};
</script>  
  
<style>
.custom-purchase-container {
    margin-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.custom-purchase-main {
    background-color: #f2f2f2;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    margin: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-purchase-header {
    font-size: 28px;
    font-weight: 700;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.input-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.grams-input {
    height: 40px;
    width: 120px;
    border: 1px solid #e4c364;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
}

.input-label {
    margin-left: 8px;
    font-size: 16px;
}

.result-text {
    font-size: 24px;
    font-weight: bold;
}

.upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.file-input-custom {
    padding: 8px 16px;
    background-color: #2196f3;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
}

.custom-submit-button {
    padding: 12px 24px;
    background-color: #000000;
    color: #e4c364;
    border: none;
    border-radius: 4px;
    font-size: 18px;
    cursor: pointer;
    width: 100%;
    font-weight: 700;
    text-transform: uppercase;
    transition: background-color 0.3s, color 0.3s;
}

.custom-submit-button:hover {
    background-color: #e4c364;
    color: #000000;
    border: 1px solid #000000;
}

.details-text {
    font-size: 14px;
    color: #888;
    margin-top: 10px;
}

.custom-note {
    margin-bottom: 10px;
    height: 120px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    resize: vertical;
}

.design-fee {
    font-size: 18px;
    margin-bottom: 10px;
}

.custom-order-name {
    margin-top: 20px;
    padding: 8px;
    width: 100%;
    border-color: #e4c364;
    border-radius: 4px;
    font-size: 16px;
}

select {
    height: 40px;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 8px;
    font-size: 16px;
    margin-bottom: 20px;
}

option {
    color: #000;
}

option.placeholder {
    color: #888;
}

.option-placeholder:before {
    content: attr(value);
    color: #ccc;
}

.option-placeholder[value=""] {
    display: none;
}

.option-placeholder[value=""][disabled] {
    display: block;
    color: #888;
}

.option-placeholder[value=""][selected] {
    display: block;
    color: #888;
}

.custom-purchase-main input {
    border-color: #000000;
}

.custom-purchase-main input:focus {
    border-color: #e4c364;
    outline: none;
}

.custom-purchase-main textarea:focus {
    border-color: #e4c364;
    outline: none;
}

@media screen and (max-width: 480px) {
    .custom-purchase-main {
        padding: 10px;
    }

    .custom-purchase-header {
        font-size: 24px;
    }

    .grams-input {
        height: 40px;
        width: 100px;
        font-size: 14px;
    }

    .input-label {
        font-size: 14px;
    }

    .result-text {
        font-size: 18px;
        margin-bottom: 15px;
    }

    .file-input-custom {
        padding: 6px 12px;
        font-size: 14px;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .custom-submit-button {
        padding: 10px 20px;
        font-size: 16px;
    }

    .details-text {
        font-size: 12px;
    }

    .custom-note {
        margin-bottom: 8px;
        height: 80px;
        font-size: 14px;
    }

    .design-fee {
        font-size: 16px;
        margin-bottom: 8px;
    }

    .custom-order-name {
        margin-top: 15px;
        padding: 6px;
        font-size: 14px;
    }

    select {
        height: 40px;
        font-size: 14px;
        margin-bottom: 10px;
    }
}
</style>  