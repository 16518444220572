<template>
    <div class="email-sender">
        <h1 class="email-header">Email Sender</h1>
        <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
        <form @submit.prevent="sendEmail" class="email-form">
            <div class="form-group">
                <label for="sender">Sender:</label>
                <input type="email" id="sender" v-model="senderEmail" required>
            </div>
            <div class="form-group">
                <label for="subject">Subject:</label>
                <input type="text" id="subject" v-model="subject" required>
            </div>
            <div class="form-group">
                <label for="message">Message:</label>
                <textarea id="message" v-model="message" required></textarea>
            </div>
            <button type="submit" class="send-button">Send Email</button>
        </form>
    </div>
</template>
  
<script>
import AuthService from '@/services/AuthService';

export default {
    data() {
        return {
            senderEmail: 'info@jewelrynepal.com', 
            subject: '',
            message: '',
            successMessage: '',
            errorMessage: '',
        };
    },
    methods: {
        sendEmail() {
            const emailData = {
                from: this.senderEmail, 
                subject: this.subject,
                message: this.message,
            };

            AuthService.sendEmails(emailData)
                .then(() => {
                    this.successMessage = 'Email sent successfully';
                    this.clearForm();
                })
                .catch((error) => {
                    console.error('Error sending email:', error);
                    this.errorMessage = 'Failed to send email';
                });
        },
        clearForm() {
            this.senderEmail = ''; 
            this.recipientEmail = '';
            this.subject = '';
            this.message = '';
        },
    },
};
</script>

  
<style>
.email-sender {
    margin-top: 75px;
    display: flex;
    margin-bottom: 1rem;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.email-header {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 10px;
}

.email-form {
    display: flex;
    flex-direction: column;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 4px;
}

.form-group {
    margin-bottom: 20px;
}

label {
    font-weight: bold;
    margin-bottom: 5px;
}

input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.send-button {
    padding: 8px 16px;
    background-color: #000000;
    color: #e4c364;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 700;
}

.send-button:hover {
    background-color: #e4c364;
    color: #000000;
    border: 1px solid #000000;
}

.success-message,
.error-message {
    margin-top: 10px;
    padding: 10px;
    font-weight: bold;
    text-align: center;
}

.success-message {
    color: green;
    background-color: #e7f3e7;
    width: 400px;
    margin-bottom: 1rem;

}

.error-message {
    color: red;
    background-color: #f9e8e8;
    width: 400px;
    margin-bottom: 1rem;
}

#message {
    height: 200px;
}

@media screen and (max-width: 450px) {
    .email-form {
        max-width: 360px;
    }

    .email-sender {
        margin-top: 80px;
    }
}
</style>