<template>
  <div class="map-container">
    <div class="g-map">
      <GmapMap
        :center="center"
        :zoom="zoom"
        style="width: 100%; height: 400px"
      >
        <GmapMarker
          :position="markerPosition"
          :clickable="true"
          :draggable="false"
        ></GmapMarker>
      </GmapMap>
    </div>
  </div>
</template>

<script>
export default {
  name: "g-map",
  data() {
    return {
      center: { lat: 40.36903, lng: -79.98390 }, 
      zoom: 14,
      markerPosition: { lat: 40.36903, lng: -79.98390 } 
    };
  }
};
</script>

<style scoped>
.map-container {
  margin-top: 11rem;
}
.g-map {
  margin: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
