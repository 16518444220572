import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import axios from 'axios'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheckCircle, faLock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import * as VueGoogleMaps from 'vue2-google-maps';

export const eventBus = new Vue();

import '@fortawesome/fontawesome-svg-core/styles.css';
import VueTelInput from 'vue-tel-input'

Vue.config.productionTip = false;
library.add(faCheckCircle, faLock );
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueTelInput);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: 'places',
  }
});

axios.defaults.baseURL = process.env.VUE_APP_REMOTE_API;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
