<template>
    <div class="page-not-found">
      <div class="container">
        <h1 class="oops">Oops!</h1>
        <div class="four-o-four-container">
          <h2 class="four-o-four">404</h2>
          <span class="page-not-found-text">page not found</span>
        </div>
        <p class="page-not-found-info">The page you're looking for cannot be found.</p>
        <router-link v-bind:to="{ name: 'main-page' }" class="page-not-found-button">Go back to Home</router-link>
      </div>
    </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style>
  .page-not-found {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background: linear-gradient(to bottom, #2c3e50, #34495e);
    font-family: 'Arial', sans-serif;
    color: #fff;
  }
  
  .container {
    text-align: center;
  }
  
  .oops {
    font-size: 4rem;
    margin-bottom: 10px;
    font-weight: 700;
  }
  
  .four-o-four-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .four-o-four {
    font-size: 96px;
    margin-right: 10px;
    font-weight: 700;
  }
  
  .page-not-found-text {
    font-size: 20px;
    font-weight: 400;
  }
  
  .page-not-found-info {
    font-size: 24px;
    margin-bottom: 30px;
  }
  
  .page-not-found-button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    text-decoration: none;
    background-color: #e4c364;
    color: #000000;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .page-not-found-button:hover {
    background-color: #2980b9;
    color: #fff;
    border: 1px solid #e4c364;
  }
  
  @media screen and (max-width: 830px) {
    .page-not-found {
      width: 105%;
    }
  
    .page-not-found-button {
      width: 94%;
      margin-left: 12px;
    }
  
    .page-not-found-info {
      font-size: 21px;
      margin-bottom: 30px;
      text-align: center;
      margin-right: 10px;
    }

    .page-not-found-text {
      font-size: 14px;
    }
  }
  </style>
  