import { render, staticRenderFns } from "./RingPage.vue?vue&type=template&id=6467fa98&scoped=true&"
import script from "./RingPage.vue?vue&type=script&lang=js&"
export * from "./RingPage.vue?vue&type=script&lang=js&"
import style0 from "./RingPage.vue?vue&type=style&index=0&id=6467fa98&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6467fa98",
  null
  
)

export default component.exports