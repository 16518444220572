<template>
    <div class="employee-container">
        <h1 class="employee-header">Employee Management</h1>
        <div v-if="successMessage" class="success-message">
            <i class="fa-sharp fa-solid fa-circle-check"></i> {{ successMessage }}
        </div>
        <div class="employee-section">
            <div class="add-employee-section">
        <div class="add-employee-form">
            <h1 class="form-emp-header">Add New Employee</h1>
            <form class="employee-form" @submit.prevent="addNewEmployee">
                <div class="form-group">
                    <label for="position">Position</label>
                    <input id="position" v-model="empPosition" type="text" class="form-control" required autofocus>
                </div>
                <div class="form-group">
                    <label for="department">Department</label>
                    <input id="department" v-model="empDepartment" type="text" class="form-control" required autofocus>
                </div>
                <div class="form-group">
                    <label for="userId">Select User ID</label>
                    <multiselect v-model="empUserId" :options="filteredUserList" :multiple="false" track-by="userId"
                        label="displayName" placeholder="Search User" @input="handleUserSelect" @search-change="searchUser">
                    </multiselect>
                    <div v-if="!empUserId" class="userId-validation-error">{{ selectUserIdMsg }}</div>
                </div>
                <button type="submit" class="add-employee-btn">Add Employee</button>
            </form>
        </div>
        </div>
        <div class="table-section">
            <h1 class="employee-table-header">List of Employees</h1>
        <table class="employee-table">
            <thead>
                <tr>
                    <th>Employee Name</th>
                    <th>Position</th>
                    <th>Department</th>
                    <th>Employee Role</th>
                    <th>Update Employee Role</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="employee in employeeLists" :key="employee.id">
                    <td>{{ employee.firstName }} {{ employee.lastName }}</td>
                    <td>{{ employee.position }}</td>
                    <td>{{ employee.department }}</td>
                    <td><strong>{{ employee.role }}</strong></td>
                    <td>
                        <div class="update-container">
                            <select v-model="employee.role">
                                <option value="ROLE_USER">ROLE_USER</option>
                                <option value="ROLE_ADMIN">ROLE_ADMIN</option>
                                <option value="ROLE_SUPER_ADMIN">ROLE_SUPER_ADMIN</option>
                            </select>
                            <button @click="updateRole(employee.employeeId, employee.role)">Update</button>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        </div>
    </div>
</template>  
  
<script>
import AuthService from '@/services/AuthService'
import Multiselect from 'vue-multiselect';

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            employeeLists: [],
            successMessage: '',
            empPosition: '',
            empDepartment: '',
            empUserId: '',
            userList: [],
            filteredUserList: [],
            searchQuery: '',
            selectUserIdMsg: '',
        }
    },
    created() {
        this.getEmployee(),
            this.getUserList();
    },
    methods: {
        getEmployee() {
            AuthService.getEmployeeList()
                .then((response) => {
                    this.employeeLists = response.data
                })
                .catch((error) => {
                    console.log('Error while fetching data', error)
                })
        },
        getUserList() {
            AuthService.getUserList()
                .then(response => {
                    this.userList = response.data;
                })
                .catch(error => {
                    console.log('Error while fetching user list', error);
                });
        },
        updateRole(employeeId, newRole) {
            AuthService.updateEmployeeRole(employeeId, newRole)
                .then((response) => {
                    console.log('Role updated successfully', response);
                    this.successMessage = 'Employee role updated successfully';
                    this.scheduleMessageClear();
                })
                .catch((error) => {
                    console.log('Error while updating role', error);
                    this.successMessage = 'Failed to update employee role';
                    this.scheduleMessageClear();
                });
        },
        addNewEmployee() {
            if(!this.empUserId){
                this.selectUserIdMsg = "Please select a user ID.";
                this.scheduleSelectUserIdClear();
                return;
            }
            const empData = {
                position: this.empPosition,
                department: this.empDepartment,
                userId: this.empUserId
            };
            AuthService.addEmployee(empData)
                .then((response) => {
                    console.log('Employee added successfully', response);
                    this.successMessage = 'Employee added successfully';
                    this.scheduleMessageClear();

                    this.empPosition = '';
                    this.empDepartment = '';
                    this.empUserId = '';
                })
                .catch((error) => {
                    console.log('Error while adding employee', error);
                    this.successMessage = 'Failed to add employee';
                    this.scheduleMessageClear();
                });
        },
        scheduleMessageClear() {
            setTimeout(() => {
                this.successMessage = '';
            }, 5000);
        },
        scheduleSelectUserIdClear() {
            setTimeout(() => {
                this.selectUserIdMsg = '';
            }, 3000);
        },
        searchUser(query) {
            if (query) {
                this.filteredUserList = this.userList.filter((user) => {
                    const fullName = `${user.firstname} ${user.lastname}`;
                    return fullName.toLowerCase().includes(query.toLowerCase());
                }).map((user) => {
                    return {
                        ...user,
                        displayName: `${user.firstname} ${user.lastname} - ${user.userId}`
                    };
                });
            } else {
                this.filteredUserList = this.userList.map((user) => {
                    return {
                        ...user,
                        displayName: `${user.firstname} ${user.lastname} - ${user.userId}`
                    };
                });
            }

            console.log('Filtered User List:', this.filteredUserList);
        },
        handleUserSelect(user) {
            this.empUserId = user.userId;
            console.log('Selected User:', user);
        },
    },
}
</script>
  
<style>
.employee-container {
  margin: 2rem;
  margin-right: 2rem;
  margin-top: 85px;
}

.employee-section {
   display: flex;
   flex-direction: row;
   justify-content: center;
}

.employee-table::-webkit-scrollbar-thumb {
  height: 5px;
  background-color: #000000;
}

.employee-table::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.employee-table::-webkit-scrollbar {
  height: 5px;
}

.employee-table::-webkit-scrollbar-thumb:hover {
  background-color: #000000;
}

.employee-table::-webkit-scrollbar:vertical {
  display: none;
}

.employee-header {
    font-size: 24px;
    font-weight: bold;
}

.form-emp-header {
    font-size: 1.4rem;
    font-weight: bold;
}

.employee-table {
    width: 100%;
    border-collapse: collapse;
    width: max-content;
}

.employee-table th {
    background-color: #f2f2f2;
    padding: 10px;
    text-align: left;
    border: 1px solid #e4c364;
}

.employee-table td {
    padding: 10px;
    border: 1px solid #e4c364;
}

.update-container {
    display: flex;
    align-items: center;
}

.update-container select {
    margin-right: 10px;
}

.update-container button {
    padding: 10px 14px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    cursor: pointer;
}

.update-container button:hover {
    background-color: #45a049;
}

.success-message {
    margin-top: 10px;
    color: rgb(15, 172, 15);
    font-weight: bold;
    animation: fadeOut 5s ease;
}

.userId-validation-error {
    color: rgb(252, 6, 6);
    font-weight: 700;
}

@keyframes fadeOut {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}

.add-employee-form {
    margin: 20px;
}

.employee-form {
    width: 300px;
    padding: 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.form-group {
    margin-bottom: 20px;
}

label {
    font-weight: bold;
}

input[type="text"],
select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.add-employee-btn {
    padding: 8px 16px;
    background-color: #000000;
    color: #e4c364;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: 600;
}

.add-employee-btn:hover {
    background-color: #e4c364;
    color: #000000;
    border: 1px solid #000000;
}

.employee-table-header {
    font-size: 1.4rem;
    font-weight: 800;
}

.table-section {
    margin-top: 20px;
}

@media screen and (max-width: 830px) {
    .employee-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 1rem;
   }

   .table-section {
    width: 104%;
    overflow: scroll;
    scrollbar-color: #d5ac68 #f1db9d;
    scrollbar-width: thin;
  }
}

@media screen and (max-width: 450px) {

  .employee-form {
    width: auto;
    max-width: 100%;
  }

  .form-group {
    margin-bottom: 10px;
  }

  .add-employee-btn {
    width: 100%;
  }

  .add-employee-form {
    margin: 0;
  }

  .employee-section {
   display: flex;
   flex-direction: column;
  }

  .table-section {
    overflow: scroll;
    scrollbar-color: #d5ac68 #f1db9d;
    scrollbar-width: thin;
  }
}

</style>
  