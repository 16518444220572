<template>
  <div id="app" @click="handleClickOutside">
    <div class="wrapper">
      <nav class="navbar"
        v-if="!isLoginPage && !isRegisterPage && !isSmallScreen && !isResetLink && !isPasswordReset && !isPageNotFound">
        <header>
          <div class="header-container">
            <div class="logo">
              <router-link to="/">
                <img src="./assets/img/banner.png" alt="Logo">
              </router-link>
            </div>
            <div class="search">
              <div class="search-wrapper">
                <input type="text" placeholder="Search..." v-model="searchQuery" ref="searchInput">
                <button v-if="searchQuery" ref="clearButton" class="clear-search" @click="clearSearch">&#x2716;</button>
              </div>
              <search-results-dialog v-if="showResults" :filteredProducts="filteredProducts"
                @clear-search="clearSearch" />
            </div>
            <ul class="nav-list">
              <li v-if="userRole === 'ROLE_ADMIN' || userRole === 'ROLE_SUPER_ADMIN'">
                <router-link :to="{ name: 'adminpage' }">
                  <div class="admin-container">
                    <i class="fa-solid fa-user-gear"></i>
                  </div>
                </router-link>
              </li>
              <li v-if="userRole === 'ROLE_USER'">
                <router-link :to="{ name: 'user-profile' }">
                  <div class="admin-container">
                    <i class="fa-solid fa-user"></i>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'liked-products' }">
                  <div class="like-container-count">
                    <i class="fa fa-heart"></i>
                    <span v-if="likeItemsCount > 0" class="like-item-count">{{ likeItemsCount }}</span>
                  </div>
                </router-link>
              </li>
              <li>
                <router-link :to="{ name: 'cart' }">
                  <div class="cart-container-count">
                    <i class="fa fa-shopping-cart" ref="cartIcon"></i>
                    <span v-if="cartItemsCount > 0" class="cart-item-count">{{ cartItemsCount }}</span>
                  </div>
                </router-link>
              </li>
              <li>
                <div class="login-logout">
                  <router-link v-if="$store.state.token === ''" :to="{ name: 'login' }">Login/Register</router-link>
                  <router-link v-if="$store.state.token !== ''" v-bind:to="{ name: 'logout' }">Logout</router-link>
                </div>
              </li>
            </ul>
          </div>
        </header>
        <div class="under-test-note">This website is under testing, payment will not be accepted at this time.</div>
      </nav>
      <nav
        v-else-if="!isLoginPage && !isRegisterPage && isSmallScreen && !isResetLink && !isPasswordReset && !isPageNotFound">
        <div class="logo">
          <router-link to="/">
            <img src="./assets/img/banner.png" alt="Logo">
          </router-link>
        </div>
        <span v-if="cartNotification || wishlistNotification" class="notification-dot"></span>
        <span class="menu-icon" @click="toggleDropdown">
          <i class="fa-solid fa-bars"></i>
        </span>
        <div class="dropdown-menu" v-if="showDropdown">
          <div class="search">
            <div class="search-wrapper">
              <input type="text" placeholder="Search..." v-model="searchQuery" ref="searchInput">
              <button v-if="searchQuery" ref="clearButton" class="clear-search" @click="clearSearch">&#x2716;</button>
            </div>
            <search-results-dialog v-if="showResults" :filteredProducts="filteredProducts" @clear-search="clearSearch" />
          </div>
          <ul class="nav-list">
            <li v-if="userRole === 'ROLE_ADMIN' || userRole === 'ROLE_SUPER_ADMIN'">
              <router-link :to="{ name: 'adminpage' }">
                <div class="icon-container">
                  <i class="fa-solid fa-user-gear" @click="hideDropdown"></i>
                </div>
              </router-link>
            </li>
            <li v-if="userRole === 'ROLE_USER'">
              <router-link :to="{ name: 'user-profile' }">
                <div class="icon-container">
                  <i class="fa-solid fa-user" @click="hideDropdown"></i>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'liked-products' }">
                <div class="like-container-count">
                  <i class="fa fa-heart" @click="hideDropdown"></i>
                  <span v-if="likeItemsCount > 0" class="like-item-count">{{ likeItemsCount }}</span>
                </div>
              </router-link>
            </li>
            <li>
              <router-link :to="{ name: 'cart' }">
                <div class="cart-container-count">
                  <i class="fa fa-shopping-cart" ref="cartIcon" @click="hideDropdown"></i>
                  <span v-if="cartItemsCount > 0" class="cart-item-count">{{ cartItemsCount }}</span>
                </div>
              </router-link>
            </li>
            <li>
              <div class="login-logout">
                <router-link v-if="$store.state.token === ''" :to="{ name: 'login' }" custom
                  @click.native="hideDropdown">Login/Register</router-link>
                <router-link v-if="$store.state.token !== ''" v-bind:to="{ name: 'logout' }" custom
                  @click.native="hideDropdown">Logout</router-link>
              </div>
            </li>
          </ul>
        </div>
        <div class="under-test-note">This website is under testing, payment will not be accepted at this time.</div>
      </nav>
      <div class="content-container">
        <router-view></router-view>
      </div>
    </div>
    <div v-if="!isLoginPage && !isRegisterPage">
      <footer-page></footer-page>
    </div>
    <section id="help-panel">
      <help-section></help-section>
    </section>
  </div>
</template>

<script>
import FooterPage from './views/FooterPage.vue'
import { mapState } from 'vuex';
import { eventBus } from '@/main';
import AuthService from './services/AuthService';
import { debounce } from 'lodash';
import SearchResultsDialog from './components/SearchResultsDialog.vue'
import HelpSection from './components/HelpSection.vue';
import { router } from '@/router';

export default {
  components: {
    FooterPage, SearchResultsDialog, HelpSection
  },
  data() {
    return {
      currentIndex: 0,
      liveRate: {},
      isActive: false,
      products: [],
      searchQuery: '',
      filteredProducts: [],
      showResults: false,
      showDropdown: false,
      showSearchBar: false,
      isSmallScreen: false,
    };
  },
  computed: {
    ...mapState(['cartItemsCount', 'likeItemsCount']),
    cartNotification() {
      return this.cartItemsCount > 0;
    },
    wishlistNotification() {
      return this.likeItemsCount > 0;
    },
    isLoginPage() {
      return this.$route.name === 'login';
    },
    isRegisterPage() {
      return this.$route.name === 'register';
    },
    isResetLink() {
      return this.$route.name === 'reset-link';
    },
    isPasswordReset() {
      return this.$route.name === 'password-reset';
    },
    isPageNotFound() {
      return this.$route.name === 'page-not-found';
    },
    userRole() {
      if (this.$store.state.user && this.$store.state.user.authorities) {
        const authorities = this.$store.state.user.authorities;
        if (authorities.length > 0) {
          return authorities[0].name;
        }
      }
      return null;
    },
  },
  created() {
    eventBus.$on('itemAddedToCart', () => {
      this.cartItemCount++;
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.handleLocationSuccess,
        this.handleLocationError
      );
    } else {
      console.log("Geolocation is not supported by your browser.");
    }

    router.beforeEach((to, from, next) => {
      if (this.showDropdown) {
        this.hideDropdown();
      }
      next();
    });
  },
  methods: {
    handleClickOutside(event) {
      if (!event.target.closest('router-link')) {
        this.clearSearch();
      }

    },
    clearSearch() {
      this.searchQuery = '';
    },
    handleResize() {
      this.isSmallScreen = window.innerWidth <= 550;
    },
    toggleDropdown() {
      if (this.showDropdown) {
        this.hideDropdown();
      } else {
        document.addEventListener('click', this.handleClickOutside);
        document.addEventListener('keyup', this.handleKeyUp);
        this.showDropdown = true;

      }
    },

    handleWindowClick(event) {
      const cartIcon = this.$refs.cartIcon;
      const likeIcon = this.$refs.likeIcon;

      if (
        !event.target.closest('.menu-icon') &&
        event.target !== cartIcon &&
        event.target !== likeIcon &&
        !event.target.closest('.count-badge') &&
        !event.target.closest('.dropdown-menu')
      ) {
        this.hideDropdown();
      }
    },
    hideDropdown() {
      document.removeEventListener('click', this.handleClickOutside);
      document.removeEventListener('keyup', this.handleKeyUp);
      console.log('hide dropdown called')
      this.showDropdown = false;
    },

    async performSearch(newQuery) {
      try {
        const response = await AuthService.searchProduct(newQuery);
        this.products = response.data;
        this.filteredProducts = this.products;
        this.fetchProductsError = null;
      } catch (error) {
        console.error(error);
        this.fetchProductsError = 'Failed to fetch products.';
      }
    },
    handleLocationSuccess(position) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;

      latitude;
      longitude;
    },
    handleLocationError(error) {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          console.log("User denied the request for Geolocation.");
          break;
        case error.POSITION_UNAVAILABLE:
          console.log("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          console.log("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          console.log("An unknown error occurred.");
          break;
        default:
          console.log("An error occurred while getting user location.");
      }
    }
  },
  mounted() {
    this.handleResize();
    window.addEventListener('resize', this.handleResize);

    if (this.$store.state.token !== '') {
      this.$store.dispatch('setCartItemsCount').then(() => {
      });
    }
    if (this.$store.state.token !== '') {
      this.$store.dispatch('setLikeItemsCount').then(() => {
      });
    }

    window.addEventListener('click', this.handleWindowClick);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('click', this.handleWindowClick);
  },
  watch: {
    cartItemsCount(newCount) {
      console.log(newCount);
    },
    likeItemsCount(newCount) {
      console.log(newCount);
    },
    searchQuery: debounce(function (newQuery) {
      console.log('Perform search:', newQuery);
      if (newQuery.trim() === '') {
        this.filteredProducts = this.products;
      } else {
        this.performSearch(newQuery);
      }
      this.showResults = newQuery.trim() !== '';
    }, 300),
  },

};
</script>

<style scoped>
@import '@fortawesome/fontawesome-free/css/all.css';

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
}

header {
  top: 0;
  left: 0;
  width: 100%;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  position: fixed;
}

.cart-item-count {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: rgb(248, 7, 7);
  color: white;
  border-radius: 50%;
  font-size: 12px;
}

.like-item-count {
  position: absolute;
  top: -10px;
  right: -10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background-color: red;
  color: white;
  border-radius: 50%;
  font-size: 12px;
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e4c364;
}

.logo {
  display: flex;
  justify-content: start;
  height: auto;
  padding: 0.3rem;
  align-items: start;
}

.banner {
  display: flex;
  justify-content: center;
  height: auto;
}

.logo img {
  max-height: 50px;
}

.banner img {
  max-height: 50px;
}

.search {
  flex-basis: 33.33%;
  display: flex;
  justify-content: center;
}

.search input {
  width: 100%;
  padding: 10px;
  padding-right: 90px;
  border: none;
  border-radius: 5px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
}

.nav-list {
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-list li a {
  display: flex;
  align-items: end;
  justify-content: end;
  text-decoration: none;
  color: inherit;
  margin-right: 1.4rem;
}

i {
  font-size: 1.6rem;
  line-height: 1;
  height: 24px;
  color: #000000;
}

.cart-container-count {
  position: relative;
  margin-top: 0.1rem;
}

.like-container-count {
  position: relative;
  margin-top: 0.1rem;
}

.login-logout {
  font-weight: bold;
  color: #000000;
}

.search-wrapper {
  position: relative;
}

.clear-search {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background: none;
  border: none;
  color: #777;
  font-size: 16px;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.clear-search:hover {
  opacity: 1;
}

.clear-search.clicked {
  transform: translateY(-50%) rotate(180deg);
}

.under-test-note {
  background-color: #f70b0b;
  color: #ffffff;
  margin-top: 65px;
  width: 100%;
  text-align: center;
  font-size: 10px;
  padding: 1px;
}

@media screen and (max-width: 830px) {
  .search input {
    width: 93%;
  }

  .under-test-note {
    margin-top: 60px;
  }
}

@media screen and (max-width: 550px) {
  nav {
    display: flex;
    background-color: #e4c364;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9999;
    position: fixed;
  }

  .nav-list {
    display: flex;
    flex-direction: row;
    gap: 4px;
    justify-content: center;
    align-items: center;
    padding: 6px;
    margin-left: 10px;
    margin-top: 10px;
  }

  .header-container {
    flex-direction: column;
  }

  .logo img {
    margin-top: 4px;
    width: 90%;
  }

  .menu-icon i {
    margin-top: 18px;
    cursor: pointer;
    margin-right: 1rem;
  }

  .dropdown-menu .search-bar {
    padding: 10px;
  }

  .dropdown-menu input[type="text"] {
    width: 300px;
    padding: 5px;
    height: 38px;
  }

  .dropdown-menu {
    display: inline;
    align-items: center;
    padding-top: 4px;
    position: absolute;
    top: 100%;
    width: 96%;
    background-color: #e4c364;
    margin-left: 8px;
  }

  .clear-search {
    top: 42%;
    right: 1px;
  }

  .under-test-note {
    position: fixed;
    margin-top: 65px;
  }

  .notification-dot {
    position: absolute;
    top: 18px;
    right: 34px;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
  }
}

</style>