<template>
  <div class="more-products-container">
    <div class="first-image">
      <img src="../assets/img/barsa.jpg">
      <div class="image-overlay">
        <div class="overlay-content">
          <h3>Nepalese Culture</h3>
          <p>Discover the beauty of Nepalese Culture</p>
          <a href="#" class="btn">View Details</a>
        </div>
      </div>
    </div>
    <div class="second-image">
      <img src="../assets/img/limnu.jpg">
      <div class="image-overlay">
        <div class="overlay-content">
          <h3>Limbu</h3>
          <p>Experience the vibrant culture of Limbu</p>
          <a href="#" class="btn">View Details</a>
        </div>
      </div>
    </div>
    <div class="third-image">
      <img src="../assets/img/nepali.jpg">
      <div class="image-overlay">
        <div class="overlay-content">
          <h3>Nepal</h3>
          <p>Embark on an adventure in Nepal</p>
          <a href="#" class="btn">View Details</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.more-products-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}

.first-image,
.second-image,
.third-image {
  position: relative;
  width: 70%;
  overflow: hidden;
  border-radius: 8px;
}

.first-image img,
.second-image img,
.third-image img {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease;
}

.first-image:hover img,
.second-image:hover img,
.third-image:hover img {
  transform: scale(1.1);
}

.image-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  opacity: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.first-image:hover .image-overlay,
.second-image:hover .image-overlay,
.third-image:hover .image-overlay {
  opacity: 1;
}

.overlay-content {
  text-align: center;
  color: #fff;
}

.overlay-content h3 {
  font-size: 28px;
  margin-bottom: 10px;
}

.overlay-content p {
  font-size: 18px;
  margin-bottom: 20px;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #e4c364;
  color: #000;
  text-decoration: none;
  border-radius: 30px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #ccc;
}

@media screen and (max-width: 830px) {
  #more-product-panel {
    width: 96%;
    margin-left: 10px;
  }
}

@media screen and (max-width: 550px) {
  .more-products-container {
    flex-direction: column;
    width: 95%;
    margin-left: 10px;
    gap: 10px;
  }

  .first-image,
  .second-image,
  .third-image {
    width: 100%;
  }
}
</style>
