<template>
    <div class="search-results-container">
      <div class="search-results-dialog" v-if="filteredProducts.length > 0">
        <ul>
          <li v-for="product in filteredProducts.slice(0, 3)" :key="product.productId">
            <div class="product-card">
              <router-link :to="{ name: 'product-details', params: { id: product.productId }}" @click="handleLinkClick">
                <div class="product-info">
                  <div class="search-product-details">
                    <h3>{{ product.name }}</h3>
                    <p>Type: {{ product.productType }}</p>
                    <p>Gold Purity: {{ product.goldPurity }}</p>
                    <p>Gender: {{ product.gender }}</p>
                    <p>Price: {{ product.price }}</p>
                  </div>
                  <div class="search-product-image">
                    <img :src="product.imageUrl" :alt="product.name">
                  </div>
                </div>
              </router-link>
            </div>
          </li>
        </ul>
        <div v-if="filteredProducts.length > 3" class="more-results">
            <router-link :to="{ name: 'search-results-more', params: { filteredProducts: filteredProducts } }">
                View more results ({{ filteredProducts.length }})
            </router-link>
        </div>
      </div>
      <div v-else>
        <p class="no-result-alert">No result found.</p>
      </div>
    </div>
  </template>
  
<script>
export default {
    props: {
        filteredProducts: {
            type: Array,
            required: true,
        },
    },
    methods: {
    handleLinkClick(event) {
      this.$emit('clear-search');
      event.stopPropagation();
    }
  }
};
</script>
  
<style>
.search-results-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-results-dialog {
  position: absolute;
  top: calc(100% + 52px);
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  min-width: 450px;
}

.product-card {
  padding: 10px;
  border-bottom: 1px solid #ccc;
}
.product-card:hover {
  background-color: #f8f4f4;
}
.product-info {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.search-product-details {
  flex-grow: 1;
}

.search-product-image {
  max-width: 170px;
  max-height: 170px;
  object-fit: contain;
  overflow: hidden;
  object-position: center center;;
}

.product-info h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
}

.product-info p {
  margin: 5px 0;
  font-size: 14px;
  color: #666;
}

.more-results {
  padding: 4px;
  text-align: center;
  background-color: #000000;
}

.more-results a {
  color: #e4c364;
}
.no-result-alert {
  font-size: 1rem;
  padding: 5px;
}

@media screen and (max-width: 450px) {
  .search-results-dialog {
  position: absolute;
  top: calc(100% + 5.1rem);
  min-width: 380px;
 }
}
</style>
  