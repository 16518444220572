<template>
  <div v-if="isAuthenticated && orderTracks.length > 0" class="horizontal timeline track-order-container">
    <div class="steps">
      <h1 class="tracker-header">Order Tracker</h1>
      <div v-for="order in orderTracks" :key="order.orderId" class="order-details">
        <span class="order-name-id">{{ order.name }}, Order No: <strong>#{{ order.orderId }}</strong></span>
      </div>
      <div v-for="(status, index) in orderStatuses.slice(0, 4)" :key="status" class="step" :class="{
        current: orderTracks[0]?.orderStatus === status, 
        'step-pending': index === 0,
        'step-in-progress': index === 1,
        'step-ready': index === 2,
        'step-complete': index === 3
      }">
        <span class="order-status">{{ getStatusCaption(status) }}</span>
      </div>
    </div>
    <div class="line"></div>
  </div>
  <div v-else>
    <img class="sales-ad-image" src="https://images.fineartamerica.com/images/artworkimages/mediumlarge/2/golden-mountain-sunrise-panorama-fotovoyager.jpg">
    <div class="sales-ad-text">
      <p class="animate__heartBeat">We are coming Soon!</p>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';

export default {
  data() {
    return {
      orderTracks: [],
      orderStatuses: ['Pending', 'In Progress', 'Ready', 'Completed'],
      isAuthenticated: false,
    };
  },
  methods: {
    async getOrderUpdates() {
      const userId = this.$store.state.user.id;
      const response = await AuthService.trackOrder(userId);
      this.orderTracks = response.data;

      this.getOrderUpdates();
    },
    getStatusCaption(status) {
      switch (status) {
        case 'Pending':
          return 'Pending';
        case 'In Progress':
          return 'Progress';
        case 'Ready':
          return 'Ready';
        case 'Completed':
          return 'Completed';
        default:
          return '';
      }
    },
  },
  created() {
    this.isAuthenticated = this.$store.state.user.id;
    if (this.isAuthenticated) {
      this.getOrderUpdates();
    }
  },
};
</script>

<style>
.track-order-container {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.tracker-header {
  font-size: 13px;
  margin-bottom: 0;
}

.horizontal.timeline {
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-right: 16px;
}

.horizontal.timeline:before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #e4c364;
}

.line {
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #e4c364;
}

.steps {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.order-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.order-name-id {
  font-size: 12px;
}

.step {
  display: block;
  position: relative;
  padding: 0.33em;
  margin: 0 1em;
  box-sizing: content-box;
  background-color: currentColor;
  border: 0.25em solid white;
  border-radius: 50%;
  z-index: 500;
  height: 6px;
  width: 6px;
}

.step-pending {
  color: #e90b29;
}

.step-in-progress {
  color: #3b0be9;
}

.step-ready {
  color: rgb(236, 157, 8);
}

.step:last-child {
  margin-right: 0;
  color: #59d805;
}

.step span {
  position: absolute;
  top: calc(100% + 1px);
  transform: translateX(-50%);
  white-space: nowrap;
  color: #000000;
  opacity: 0.4;
  text-align: center;
}

.step.current:before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 1em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  z-index: -1;
  animation-name: animation-timeline-current;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

.step.current span {
  opacity: 0.8;
}

@keyframes animation-timeline-current {
  from {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }

  to {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}

.sales-ad-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.sales-ad-text {
  position: absolute;
  bottom: 12px;
  left: 12px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #e4c364;
  padding: 4px;
  font-size: 14px;
  border: 1px solid #ffffff;
}

.sales-ad-text p {
  margin: 0;
  font-weight: bold;
}

.track-details {
  font-size: 12px;
  margin-top: 5px;
}

.order-status {
  font-size: 10px;
  font-weight: 700;
}

@media screen and (max-width: 830px) {
  .tracker-header {
    font-size: 14px;
  }

  .order-name-id {
    font-size: 10px;
  }

  .track-details {
    font-size: 8px;
  }

  .order-status {
    font-size: 9px;
  }

  .step {
    margin: 0 10px;
  }
}

@media screen and (max-width: 450px) {
  .tracker-header {
    font-size: 12px;
  }

  .order-name-id {
    font-size: 10px;
  }

  .track-details {
    font-size: 8px;
  }

  .order-status {
    font-size: 9px;
  }

  .step {
    margin: 0 10px;
  }
}

</style>