<template>
  <div>
    <h1 class="rani-header">Rings Collections</h1>
    <div class="product-grid">
      <router-link v-for="ring in rings" :key="ring.productId" :to="{ name: 'product-details', params: { id: ring.productId } }" class="product-box">
        <div class="product-image-container">
          <img :src="ring.imageUrl" :alt="ring.name" class="product-image"/>
        </div>
        <p class="product-price">${{ ring.price }}</p>
        <p class="product-name">{{ ring.name }}</p>
        <div class="product-rating">
          <template v-if="ring.averageRating > 0">
            <span class="star" v-for="star in averageRatingStars(ring.averageRating)" :key="star">&#9733; </span>
            ({{ ring.totalRaters }})
          </template>
          <template v-else>
            <span>No ratings yet</span>
          </template>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';

export default {
  data() {
    return {
      rings: [],
    };
  },
  created() {
    AuthService.getRingsData().then((response) => {
      this.rings = response.data;
    });
  },
  methods: {
    averageRatingStars(averageRating) {
      const roundedRating = Math.round(averageRating);
      return Array(roundedRating).fill('');
    },
  },
};
</script>

<style scoped>
.product-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 1384px;
  margin: 0 auto;
  padding: 0 20px;
}

.product-box {
  position: relative;
  width: calc(25% - 10px); 
  background-color: #e4c364;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.product-price {
  font-weight: bold;
  font-size: 16px;
  margin-top: 6px;
  font-weight: bolder;
  color: #000000;
}

.product-name {
  font-size: 14px;
  margin: 1px;
  color: #000000;
}

.product-image-container {
  display: flex;
  height: 300px;
}

.product-image {
  max-width: 100%;
  height: auto;
}

.product-rating {
  position: absolute;
  bottom: 40px; 
  right: 10px; 
  color: #000000;
}

.star {
  color: #000000;
  font-size: 20px;
}

h1 {
  text-align: center;
  font-size: x-large;
  margin: 1.6rem;
}
.rani-header:before {
    content: '';
    position: absolute;
    width: 45px;
    height: 15px;
    border-bottom: 4px solid;
    top: 22px;
    margin-left: -60px;
}

.rani-header:after {
    content: '';
    position: absolute;
    width: 45px;
    height: 15px;
    border-bottom: 4px solid;
    top: 23px;
    margin-left: 15px;
}

.rani-header {
    font-family: Muli-Semi-Bold;
    color: #000;
    position: relative;
    text-transform: capitalize;
    text-align: center;
    font-size: 3rem;
    margin-top: 4rem;
    margin-bottom: 0.4rem;
}
</style>
