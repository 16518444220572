<template>
  <div class="container">
    <section id="gold-rates">
      <div class="live-rates">
        <div class="live-exchange-rates">
          <img class="usa-flag" src="../assets/img/usflag.png">
          <p class="usd-currency"><strong>$1</strong></p>
          <i class="fa-solid fa-arrow-right"></i>
          <img class="nepal-flag" src="../assets/img/nepalflag.png">
          <p class="npr-currency">NPR: <strong>132.00</strong></p>
          <!-- <p>United States Dollar: <strong>{{ formatDecimal(exchangeRate.uSD) }} USD</strong></p>
          <p>Nepalese Rupees: <strong>{{ formatDecimal(exchangeRate.nPR) }} NPR</strong></p> -->
        </div>
        <div class="live-gold-rates">
          <p class="karat">24K</p>
          <i class="fa-solid fa-arrow-right"></i>
          <div class="price">
            <p><strong> ${{ formatDecimal(goldRate.ask / 31.1 + 1) }}</strong>/gm</p>
            <p><strong> ${{ formatDecimal((goldRate.ask / 31.1 + 1) * 11.7) }}</strong>/Tola</p>
            <p><strong> ${{ formatDecimal((goldRate.ask + 1)) }}</strong>/Ounce</p>
          </div>
          <img class="gold-image" src="../assets/img/golds.png">
          <div class="price">
            <p><strong> ${{ formatDecimal(goldRate.bid / 31.1 - 3) }}</strong>/gm</p>
            <p><strong> ${{ formatDecimal((goldRate.bid / 31.1 - 3) * 11.7) }}</strong>/Tola</p>
            <p><strong> ${{ formatDecimal((goldRate.bid / 31.1 - 3) * 28.35) }}</strong>/Ounce</p>
          </div>
          <i class="fa-solid fa-arrow-left"></i>
          <p class="karat">22K</p>
        </div>
      </div>
      <div class="sales-ad">
        <track-orders></track-orders>
      </div>
    </section>
    <div id="left-panel" class="left-panel">
      <!-- <div v-if="!isSmallScreen()" class="three-d-slides">
        <image-slides></image-slides>
      </div> -->
      <div class="three-d-slides">
        <div class="custom-order-panel">
          <h1 class="custom-order-heading">Custom order of your choice <i class="fa-solid fa-face-smile"></i></h1>
          <div class="custom-txt">
            <div class="shop-now">
            </div>
            <div>
              <p class="custom-order-description-txt">Design is yours, We make what you like and what you prefer.
                If you want a custom order then please click the button below and you will get the price based on your gold
                weight.</p>
            </div>
          </div>
          <router-link v-bind:to="{ name: 'custom-purchase' }" class="custom-order-btn-panel">Let's place custom
            order</router-link>
        </div>
      </div>
      <div id="slides">
        <div class="image_slides">
          <div class="slider-arrow left-arrow">
            <span class="arrow" @click="scrollToPreviousSlide">&#10094;</span>
          </div>
          <img src="https://static.malabargoldanddiamonds.com/media/bsimages/Dallas_banner.jpg" alt="Image 2" />
          <div class="slider-arrow right-arrow">
            <span class="arrow" @click="scrollToNextSlide">&#10095;</span>
          </div>
        </div>
        <div class="image_slides">
          <div class="slider-arrow left-arrow">
            <span class="arrow" @click="scrollToPreviousSlide">&#10094;</span>
          </div>
          <img src="https://static.malabargoldanddiamonds.com/media/bsimages/Bespoke_Website-banner_1500x480.jpg"
            alt="Image 3" />
          <div class="slider-arrow right-arrow">
            <span class="arrow" @click="scrollToNextSlide">&#10095;</span>
          </div>
        </div>

        <div class="image_slides">
          <div class="slider-arrow left-arrow">
            <span class="arrow" @click="scrollToPreviousSlide">&#10094;</span>
          </div>
          <img src="https://static.malabargoldanddiamonds.com/media/bsimages/US-Gold-Scheme_Website-banner_1500x480.jpg"
            alt="Image 4" />
          <div class="slider-arrow right-arrow">
            <span class="arrow" @click="scrollToNextSlide">&#10095;</span>
          </div>
        </div>

        <div class="image_slides">
          <div class="slider-arrow left-arrow">
            <span class="arrow" @click="scrollToPreviousSlide">&#10094;</span>
          </div>
          <img src="https://static.malabargoldanddiamonds.com/media/bsimages/Viraaz-Collection-web.jpg" alt="Image 1" />
          <div class="slider-arrow right-arrow">
            <span class="arrow" @click="scrollToNextSlide">&#10095;</span>
          </div>
        </div>
        <div class="image_slides">
          <div class="slider-arrow left-arrow">
            <span class="arrow" @click="scrollToPreviousSlide">&#10094;</span>
          </div>
          <img src="https://static.malabargoldanddiamonds.com/media/bsimages/zoul.png" alt="Image 1" />
          <div class="slider-arrow right-arrow">
            <span class="arrow" @click="scrollToNextSlide">&#10095;</span>
          </div>
        </div>
        <div class="image_slides">
          <div class="slider-arrow left-arrow">
            <span class="arrow" @click="scrollToPreviousSlide">&#10094;</span>
          </div>
          <img src="https://static.malabargoldanddiamonds.com/media/bsimages/INTL-Nrityanjali-Collection-web.jpg"
            alt="Image 1" />
          <div class="slider-arrow right-arrow">
            <span class="arrow" @click="scrollToNextSlide">&#10095;</span>
          </div>
        </div>
        <div class="slider-arrow right-arrow">
          <span class="arrow" @click="scrollToNextSlide">&#10095;</span>
        </div>
      </div>
    </div>
    <div class="right-panel">
      <router-link :to="{ name: 'rani-haar' }">
        <div class="image_container">
          <img src="../assets/img/ranihaar.jpg" alt="Image 1" />
        </div>
      </router-link>
      <router-link :to="{ name: 'rings' }">
        <div class="image_container">
          <img src="../assets/img/ring.jpg" alt="Image 2" />
        </div>
      </router-link>
      <router-link :to="{ name: 'bracelets' }">
        <div class="image_container">
          <img src="../assets/img/braclet.jpg" alt="Image 3" />
        </div>
      </router-link>
      <router-link :to="{ name: 'mangal-sutra' }">
        <div class="image_container">
          <img src="../assets/img/braclet.jpg" alt="Image 4" />
        </div>
      </router-link>
      <router-link :to="{ name: 'necklace' }">
        <div class="image_container">
          <img src="../assets/img/necklace.jpg" alt="Image 5" />
        </div>
      </router-link>
      <router-link :to="{ name: 'necklace' }">
        <div class="image_container">
          <img src="../assets/img/ranihaar.jpg" alt="Image 6" />
        </div>
      </router-link>
    </div>
    <div id="more-collections" class="more-collections">
      <h1 class="more-collections-header">More Collections</h1>
      <router-link :to="{ name: 'rani-haar' }">
        <div class="image_container">
          <img src="../assets/img/ranihaar.jpg" alt="Image 1" />
        </div>
      </router-link>
      <router-link :to="{ name: 'rings' }">
        <div class="image_container">
          <img src="../assets/img/ring.jpg" alt="Image 2" />
        </div>
      </router-link>
      <router-link :to="{ name: 'bracelets' }">
        <div class="image_container">
          <img src="../assets/img/braclet.jpg" alt="Image 3" />
        </div>
      </router-link>
    </div>
    <section id="more-product-panel" class="more-product-panel">
      <more-porducts></more-porducts>
    </section>
    <section id="main-panel" class="main-panel">
      <main-recommendation></main-recommendation>
    </section>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';
import TrackOrders from './TrackOrders.vue';
//import ImageSlides from '@/components/ImageSlides.vue';
import MainRecommendation from './MainRecommendation.vue';
import MorePorducts from './MorePorducts.vue';

export default {
  components: { TrackOrders, MainRecommendation, MorePorducts },
  data() {
    return {
      exchangeRate: {
        nPR: 0,
      },
      currentSlide: 0,
      goldRate: {
        ask: 0,
        bid: 0
      },
    };
  },
  computed: {
    slides() {
      return Array.from(document.getElementsByClassName('image_slides'));
    },
  },
  created() {
    AuthService.getExchangeRate()
      .then((response) => {
        this.exchangeRate = response.data;
      })
      .catch((error) => {
        if (error.response.status === 429) {
          this.errorMessage = "Too many requests. Please try again later.";
        } else {
          this.errorMessage = "An error occurred while fetching the exchange rate.";
        }
      });
    this.getLiveGoldRates();
  },

  methods: {
    getLiveGoldRates() {
      AuthService.getLiveGoldRates()
        .then((response) => {
          const goldRateDto = response.data;

          this.goldRate.spreadProfile = goldRateDto.spreadProfile;
          this.goldRate.bidSpread = goldRateDto.bidSpread;
          this.goldRate.askSpread = goldRateDto.askSpread;
          this.goldRate.bid = goldRateDto.bid;
          this.goldRate.ask = goldRateDto.ask;
        })
        .catch((error) => {
          if (error.response.status === 429) {
            this.errorMessage = "Too many requests. Please try again later.";
          } else {
            this.errorMessage = "An error occurred while fetching the gold rate.";
          }
        });
    },
    formatDecimal(value) {
      return value.toFixed(2);
    },
    isSmallScreen() {
      return window.innerWidth <= 768;
    },
    scrollToSlide(slideIndex) {
      const slidesContainer = document.getElementById('slides');

      if (slidesContainer) {
        slidesContainer.scrollTo({
          left: slidesContainer.offsetWidth * slideIndex,
          behavior: 'smooth'
        });

        this.currentSlide = slideIndex;
      }
    },

    scrollToNextSlide() {
      const slidesContainer = document.getElementById('slides');

      if (slidesContainer) {
        const slides = slidesContainer.getElementsByClassName('image_slides');

        let currentSlide = this.currentSlide + 1;
        if (currentSlide >= slides.length) {
          currentSlide = 0;
        }

        this.scrollToSlide(currentSlide);

        clearInterval(this.interval);
        this.interval = setInterval(this.scrollToNextSlide, 3000);
      }
    },
    scrollToPreviousSlide() {
      let currentSlide = this.currentSlide - 1;
      if (currentSlide < 0) {
        currentSlide = this.slides.length - 1;
      }

      this.scrollToSlide(currentSlide);
    },
  },
  mounted() {
    setInterval(this.scrollToNextSlide, 3000);
  },
};
</script>

<style>
#gold-rates {
  display: flex;
  text-align: center;
  grid-template-columns: auto 1fr;
  grid-template-areas: "live-rates image";
  grid-area: gold-rates;
  padding-bottom: 4px;
  background-color: #faf7f7;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  margin-top: 1rem;
}

.sales-ad {
  grid-area: image;
  width: 40%;
  margin: 3px;
  align-items: start;
  height: 60px;
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: right;
}

#left-panel {
  width: 100%;
  margin: 0;
  padding: 0;
  height: 510px;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr 2fr;
}

.image_slides {
  flex: 0 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image_slides img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#main-panel {
  display: grid;
  grid-area: main-panel;
}

#more-collections {
  display: grid;
  grid-area: more-collections;
}

#more-product-panel {
  display: grid;
  grid-area: more-product-panel;
}

.container {
  display: grid;
  width: 100%;
  overflow: hidden;
  grid-template-columns: 1fr 1.1fr;
  grid-template-rows: auto 1fr;
  align-items: flex-start;
  gap: 1rem;
  grid-template-areas:
    "gold-rates gold-rates"
    "left-panel right-panel"
    "more-product-panel more-product-panel"
    "more-collections more-collections"
    "main-panel main-panel";
  box-sizing: border-box;
  align-items: flex-start;
  margin-bottom: 2rem;
  margin-top: 80px;
}

.live-rates {
  grid-area: live-rates;
  display: flex;
  flex: 1;
  gap: 2rem;
  justify-content: start;
  align-items: center;
  margin-left: 1rem;
}

.live-gold-rates {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 0;
  gap: 14px;
  margin-left: 1.6rem;
}

.live-gold-rates .gold-image {
  width: 70px;
}

.live-exchange-rates {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
  padding: 8px;
  border: 1px solid #e4c364;
  background-color: #ffffff;
}

.live-exchange-rates .usa-flag {
  width: 30px;
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
}

.live-exchange-rates .nepal-flag {
  width: 20px;
  height: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.live-exchange-rates p {
  margin-right: 10px;
  font-size: 16px;
}

.price p {
  margin: 0;
  font-size: 10px;
}

.karat {
  font-weight: bold;

}

.live-exchange-rates-live p {
  font-size: 13px;
}

.right-panel {
  width: 100%;
  display: grid;
  height: auto;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr 1fr;
  gap: 16px;
  grid-template-areas:
    "rani-haar rings bracelets"
    "tilari chockers necklace";
  margin: 0;
}

.more-collections {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  gap: 16px;
  grid-template-areas:
    "rani-haar rings bracelets";
  margin-top: 1.4rem;
}

.more-collections img:hover {
  border: 1px solid #e4c364;
}

.advertise {
  grid-area: advertise;
  display: flex;
  justify-content: center;
  align-items: center;
}


#slides {
  position: relative;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  display: flex;
  width: fit-content;
  height: 100%;
}

#slides::-webkit-scrollbar {
  display: none;
}

.image_slides {
  scroll-snap-align: start;
  flex: 0 0 100%;
  position: relative;
}

.right-panel {
  overflow-x: hidden;
}

.slider-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 40px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 1;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.left-arrow {
  left: 10px;
}

.right-arrow {
  right: 10px;
}

.slider-arrow:hover {
  opacity: 1;
}

.arrow {
  font-size: 24px;
  color: #333;
}

.arrow:hover {
  color: #000;
}

.arrow {
  font-size: 24px;
  color: #333;
}

slider-dots {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #bbb;
  margin: 0 4px;
  cursor: pointer;
}

.dot.active {
  background-color: #555;
}

.live-gold-rates .live-exchange-rates {
  font-family: 'Nanum Myeongjo', serif;
}

.donate-ad {
  background-color: rgba(16, 160, 52, 0.7);
  ;
  color: #ffffff;
  padding: 10px;
  font-weight: 600;
  border-radius: 4px;
}

.live {
  background-color: rgb(235, 9, 9);
  color: #ffffff;
  padding: 4px;
  border-radius: 50%;
}

@keyframes beat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.live {
  animation: beat 1s infinite;
}

.more-collections-header {
  font-size: 2rem;
  font-weight: 700;
  border: 1px solid #e4c364;
  background-image: url("https://media.istockphoto.com/id/1193750118/photo/beautiful-asian-woman-carrying-colorful-bags-shopping-online-with-mobile-phone.jpg?s=612x612&w=0&k=20&c=j1SpSX7c3qzBrUT5f7HRoOfxQnPxZY_c6yb3AvXA5f8=");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #ffffff;
  text-align: center;
  margin: 0;
  padding: 0;
}

.image_container {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 97%;
  box-shadow: 0px 15px 35px -5px rgba(50, 88, 130, 0.32);
  border-radius: 15px;
  transition: transform .2s;
}

.image_container:hover {
  transform: translateY(-3px);
  box-shadow: 0 4px 10px rgba(7, 7, 7, 0.2);
}

.custom-order-panel {
  background-image: url('../assets/img/jewellery.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.custom-order-panel h1 {
  font-size: 18px;
  font-weight: 700;
  border: 1px solid #e4c364;
  width: 50%;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 2px;
}

.custom-order-btn-panel {
  margin-top: 10px;
  color: #e4c364;
  border: 1px solid #e4c364;
  background-color: rgba(0, 0, 0);
  border-radius: 4px;
  font-weight: 700;
  padding: 6px;
  width: 40%;
}

.custom-order-btn-panel:hover {
  background-color: #e4c364;
  color: #000000;
  border: 1px solid #000000;
}

.custom-order-description-txt {
  font-size: 14px;
  color: #000000;
  width: 100%;
}

.custom-txt {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.shop-now {
  width: 130%;
}
@media screen and (max-width: 830px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas:
      "gold-rates"
      "left-panel"
      "right-panel"
      "more-product-panel"
      "more-collections"
      "main-panel";
    margin-top: 91px;
    gap: 1px;
    margin-left: 1rem;
  }

  #gold-rates {
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 96%;
    margin-left: 8px;
  }

  .sales-ad {
    width: 60%;
    height: auto;
    margin-top: 5px;
  }

  .live-rates {
    flex-direction: column;
    display: flex;
    gap: 14px;
    justify-content: start;
    margin-left: 0;
    margin-top: 10px;
    align-items: start;
  }

  .right-panel {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: repeat(6, auto);
    grid-template-areas:
      "rani-haar rings bracelets"
      "mangal-sutra necklace necklace-2";
    gap: 10px;
    width: 95%;
    margin-left: 12px;
  }

  #left-panel {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    height: 400px;
  }

  .more-collections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
      "rani-haar"
      "rings"
      "bracelets";
    gap: 10px;
    width: 96%;
    margin-left: 10px;
  }

  #slides {
    width: 96%;
    margin-left: 10px;
    height: 230px;
  }

  .slider-arrow {
    display: none;
  }

  .image_slides img {
    height: auto;
    max-width: 100%;
  }

  .three-d-slides {
    width: 96%;
    margin-left: 10px;
  }

  .live-exchange-rates-live p {
    margin-top: 10px;
    font-size: 10px;
  }

  .track-order-container {
    text-align: center;
    margin-bottom: 1rem;
  }

  .live-exchange-rates {
    margin-left: 4.2rem;
  }

  .live-gold-rates {
    margin-bottom: 0;
    gap: 4px;
    margin-right: 24px;
  }

  .image_container {
    height: 97%;
  }
}

@media screen and (max-width: 450px) {
  .container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto 1fr auto;
    grid-template-areas:
      "gold-rates"
      "left-panel"
      "right-panel"
      "more-product-panel"
      "more-collections"
      "main-panel";
    margin-top: 91px;
    margin-left: 0;
    gap: 10px;
  }

  #gold-rates {
    flex-direction: column;
    align-items: center;
    margin: 0;
    width: 96%;
    margin-left: 8px;
  }

  .sales-ad {
    width: 95%;
    height: auto;
    margin-top: 5px;
  }

  .live-rates {
    flex-direction: column;
    display: flex;
    gap: 14px;
    justify-content: start;
    margin-left: 0;
    margin-top: 10px;
    align-items: start;
  }

  .right-panel {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(6, auto);
    grid-template-areas:
      "rani-haar rings"
      "bracelets mangal-sutra"
      "necklace necklace-2";
    gap: 10px;
    width: 94%;
    margin-left: 12px;
  }

  #left-panel {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
    grid-template-rows: 1fr;
    height: 370px;
  }

  .more-collections {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(4, auto);
    grid-template-areas:
      "rani-haar"
      "rings"
      "bracelets";
    gap: 10px;
    width: 94%;
    margin-left: 10px;
  }

  #slides {
    width: 96%;
    margin-left: 10px;
    height: 120px;
  }

  .slider-arrow {
    display: none;
  }

  .image_slides img {
    height: auto;
    max-width: 100%;
  }

  .three-d-slides {
    width: 96%;
    margin-left: 10px;
  }

  .live-exchange-rates-live p {
    margin-top: 10px;
    font-size: 10px;
  }

  .track-order-container {
    text-align: center;
    margin-bottom: 1rem;
  }

  .live-exchange-rates {
    margin-left: 4.2rem;
  }

  .live-gold-rates {
    margin-bottom: 0;
    gap: 4px;
    margin-right: 24px;
  }

  .image_container {
    height: 97%;
  }

  .custom-order-btn-panel {
    width: 80%;
  }

  .custom-order-panel h1 {
    width: 90%;
  }

  .shop-now {
    width: 180%;
  }
}
</style>