import AuthService from "@/services/AuthService";

export default {
  state: {
    likeItems: [],
    likeItemsCount: 0,
  },
  mutations: {
    ADD_TO_LIKE(state, item) {
      state.likeItems.push(item);
    },
    SET_LIKE_ITEMS(state, items) {
      state.likeItems = items;
    },
    SET_LIKE_ITEMS_COUNT(state, count) {
      state.likeItemsCount = count;
    },
    removeFromLike(state, item) {
      const index = state.likeItems.findIndex((likeItem) => likeItem.id === item.id);
      if (index !== -1) {
        state.likeItems.splice(index, 1);
      }
    },
    async setLikeItemsCount(state, userId) {
      try {
        const count = await AuthService.getCountLikedItem(userId);
        state.likeItemsCount = count.data;
      } catch (error) {
        console.log('Error while fetching like items count:', error);
      }
    },      
  },
  actions: {
    addToLike({ commit, state }, item) {
      commit('ADD_TO_LIKE', item);
      commit('SET_LIKE_ITEMS', item);
      const count = state.likeItemsCount + 1;
      commit('setLikeItemsCount', count);
    },
  },
};
