<template>
  <div class="completed-order-container">
    <h1 class="sales-header">Sales Report</h1>
    <div class="filter-container">
        <label>From:</label>
            <input type="date" v-model="filterForm" class="filter-date"/>
        <label>To</label>
            <input type="date" v-model="filterTo" class="filter-date"/>
    </div>
    <div class="sales-table-section">
      <table class="sales-table">
        <thead>
            <tr>
                <th>Employers Name</th>
                <th>Order Number</th>
                <th>Order Status</th>
                <th>Order Date</th>
                <th>Order Type</th>
                <th>Product Name</th>
                <th>Quantity</th>
                <th>Product Type</th>
                <th>Order Cost</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="order in filteredReports" :key="order.orderId">
               <td>{{ order.firstname }} {{ order.lastname }}</td>
               <td>{{ order.orderId }}</td>
               <td>{{ order.orderStatus }}</td>
               <td>{{ order.orderDate }}</td>
               <td>{{ order.orderType }}</td>
               <td>{{ order.name }}</td>
               <td>{{ order.quantity }}</td>
               <td>{{ order.productType }}</td>
               <td>{{ order.orderCost }}</td>
            </tr>
            <tr>
                <td colspan="8" class="total-label">Total</td>
                <td>{{ totalCost.toFixed(2) }}</td>
            </tr>
        </tbody>
    </table>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';
export default {
data(){
    return {
        salesData: [],
        filterForm: '',
        filterTo: '',
        empName: {}
    }
  },
  created(){
    const userId = this.$store.state.user.id;
    AuthService.completedOrders()
    .then((response) => {
        this.salesData = response.data;
    })
    .catch((error) => {
        console.log("Failed to load orders", error)
    });
    AuthService.getEmployeeName(userId)
    .then((response) => {
      this.empName = response.data;
    })
    .catch((error) => {
      console.log("Failed to load names", error)
    });
  },
  computed: {
      filteredReports() {
        let filtered = this.salesData;
  
        if (this.filterForm) {
          filtered = filtered.filter((report) => {
            const reportDate = new Date(report.orderDate);
            const filterFromDate = new Date(this.filterForm);
            return reportDate >= filterFromDate;
          });
        }
  
        if (this.filterTo) {
          filtered = filtered.filter((report) => {
            const reportDate = new Date(report.orderDate);
            const filterToDate = new Date(this.filterTo);
            return reportDate <= filterToDate;
          });
        }
  
        filtered = filtered.filter((report) => {
          return report.orderDate !== "Invalid Date";
        });
  
        return filtered.slice(0);
      },
  
      totalCost() {
        return this.filteredReports.reduce((total, report) => {
          return total + report.orderCost;
        }, 0);
      },
    },
};
</script>

<style>
.completed-order-container {
  margin-top: 65px;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-bottom: 10px;
}

.completed-order-container {
  margin-top: 78px;
}

.sales-table-section {
  overflow: scroll;
  scrollbar-color: #d5ac68 #f1db9d;
  scrollbar-width: thin;
  display: flex;
  justify-content: center;
}
.sales-table-section::-webkit-scrollbar-thumb {
  height: 5px;
  background-color: #000000;
}

.sales-table-section::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.sales-table-section::-webkit-scrollbar {
  height: 5px;
}

.sales-table-section::-webkit-scrollbar-thumb:hover {
  background-color: #000000;
}

.sales-table-section::-webkit-scrollbar:vertical {
  display: none;
}

.sales-header {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  margin-top: 34px;
}
.sales-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  width: max-content;
}

.sales-table th,
.sales-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  border-right: 1px solid #ddd; 
}

.sales-table th {
  background-color: #f5f5f5;
}

.sales-table tr:hover {
  background-color: #f9f9f9;
}

.sales-table tbody tr:nth-child(even) {
  background-color: #fff;
}

.sales-table tbody tr:last-child td {
  border-bottom: none;
}

.sales-table td:nth-child(1),
.sales-table td:nth-child(12) {
  font-weight: bold;
}

.sales-table td:nth-child(1) {
  color: #000000;
}

.sales-table td:nth-child(11) {
  width: 100px;
}

.sales-table td:nth-child(12) {
  width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sales-table th:last-child,
.sales-table td:last-child {
  border-right: none;
}

.filter-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.filter-container label {
  margin-right: 10px;
}

.filter-date {
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 18px;
  font-weight: bold;
  background-color: #f5f5f5;
  margin-right: 10px;
}

.filter-date:focus {
  outline: none;
  box-shadow: 0 0 0 2px #0077cc;
}

@media screen and (max-width: 450px) {
  .filter-container {
    margin-left: 14px;
  }

  .sales-table-section {
    justify-content: start;
}
}
</style>