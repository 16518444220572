<template>
  <div class="search-results-more-container">
    <h2 class="more-results-header">More Results</h2>
    <div class="results-grid">
      <div v-for="product in filteredProducts" :key="product.productId" class="product-card-more">
        <router-link :to="{ name: 'product-details', params: { id: product.productId } }">
          <div class="product-info-more">
            <div class="product-details-more">
              <p><strong>{{ product.name }}</strong></p>
              <p>Type: {{ product.productType }}</p>
              <p>Gold Purity: {{ product.goldPurity }}</p>
              <p>Gender: {{ product.gender }}</p>
              <p class="view-more-price">
                <strong>Price:</strong>
                <span class="price-integer-more"> {{ Math.floor(product.price) }}</span>
                <span class="price-decimal-more">.{{ (product.price % 1).toFixed(2).slice(-2) }}</span>
              </p>
            </div>
            <div class="product-image-more">
              <img :src="product.imageUrl" :alt="product.name">
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
  
<script>
export default {
  props: {
    filteredProducts: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    console.log(this.filteredProducts);
  },
};
</script>
  
<style scoped>
.search-results-more-container {
  margin-top: 70px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 1340px;
  margin-left: auto;
  margin-right: auto;
}

.more-results-header:before {
  content: '';
  position: absolute;
  width: 45px;
  height: 15px;
  border-bottom: 4px solid;
  top: 22px;
  margin-left: -60px;
}

.more-results-header:after {
  content: '';
  position: absolute;
  width: 45px;
  height: 15px;
  border-bottom: 4px solid;
  top: 23px;
  margin-left: 15px;
}

.more-results-header {
  font-family: Muli-Semi-Bold;
  color: #000;
  position: relative;
  text-transform: capitalize;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 0.4rem;
}

.results-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  justify-content: center;
  gap: 1rem;
}

.product-card-more {
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-info-more {
  padding: 15px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
  color: #000000;
  display: flex;
  width: 450px;
  height: 200px;
  border-radius: 10px;
}

.product-card-more:hover .product-info-more {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.product-details-more {
  flex-grow: 1;
  margin-right: 20px;
}

.product-image-more {
  width: 180px;
  height: 170px;
  overflow: hidden;
}

.product-image-more img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.product-card-more:hover .product-image-more img {
  transform: scale(1.05);
}

.price-integer-more {
  font-size: 2rem;
  font-weight: 700;
  color: #d73e4d;
}

.price-decimal-more {
  font-size: 1rem;
  font-weight: 700;
  color: #d73e4d;
}

.product-details-more p {
  font-size: 1.1rem;
}

@media screen and (max-width: 450px) {
  .search-results-more-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 370px;
    margin-top: 80px;
  }

  .more-results-header {
    font-size: 2rem;
  }

  .more-results-header:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 15px;
    border-bottom: 4px solid;
    top: 12px;
    margin-left: -14px;
  }

  .more-results-header:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 15px;
    border-bottom: 4px solid;
    top: 12px;
    margin-left: 10px;
  }

  .product-info-more {
    width: 100%;
    height: 200px;
  }
   
  .price-integer-more {
    font-size: 1rem;
    font-weight: 600;
  }
}
</style>
