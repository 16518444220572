<template>
  <div class="donation-container">
    <div class="donation-main">
      <h1 class="donation-header">Donation Center</h1>
      <p class="sub-tittle">Your little help can change someone's life</p>
      <div class="donation-icon">
        <i class="fa-solid fa-hand-holding-dollar"></i>
      </div>
      <div class="button-container">
        <button class="donate-learn-btn">Learn More</button>
        <button class="donate-donate-btn">Donate Now</button>
      </div>
    </div>
    <div class="donation-main-container">
      <div class="left-donation-panel">
        <h>Top Stories</h>
        <p>The fight for change isn’t just a moment — it’s a movement. And we need people like you to help us forge the path.  
          “I have been giving a portion of my monthly income to World Relief since I was a freshman in college (36 years ago). 
          I am not physically able to come alongside the hurting all around the world, but I can financially 
          support this organization who does. It is my way of showing God’s love to others and thanking 
          Him for the great gifts He has given me.” - Marie Holst, Minnesota 
        </p>
      </div>
      <div class="right-donation-panel">
        <h1>Top Stories</h1>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.donation-header:before {
  content: '';
  position: absolute;
  width: 45px;
  height: 15px;
  border-bottom: 4px solid;
  top: 22px;
  margin-left: -60px;
}

.donation-header:after {
  content: '';
  position: absolute;
  width: 45px;
  height: 15px;
  border-bottom: 4px solid;
  top: 23px;
  margin-left: 15px;
}

.donation-header {
  font-family: Muli-Semi-Bold;
  color: #000;
  position: relative;
  text-transform: capitalize;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 0.4rem;
}
.sub-tittle {
  text-align: center;
  margin-top: -1.6rem;
  margin-left: 1rem;
  color: #09ad09;
  font-size: 12px;
}
.donation-icon {
  text-align: center;
  font-size: 3rem;
  color: #09ad09;
  margin-top: 6px;
}
.donation-main-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: center;
  padding: 15px;
  border-radius: 5px;
  margin-left: 4rem;
  margin-right: 4rem;
}
.left-donation-panel h {
  font-size: 2rem;
  font-weight: 600;
}
.donation-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('https://cdn.dnaindia.com/sites/default/files/styles/full/public/2021/08/12/990458-organ-donation-day-2021.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom center;
}

.donation-main {
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.donate-learn-btn,
.donate-donate-btn {
  font-size: 1rem;
  padding: 4px 2rem;
}

.donate-learn-btn {
  background-color: #ff9f00;
  margin-left: 1rem;
  border: #ff9f00;
  color: #ffffff;
  font-weight: 600;
}

.donate-donate-btn {
  background-color: #09ad09;
  margin-left: 1rem;
  border: #09ad09;
  color: #ffffff;
  font-weight: 600;
}
</style>