import AuthService from "@/services/AuthService";

export default {
  state: {
    cartItems: [],
    cartItemsCount: 0,
  },
  mutations: {
    ADD_TO_CART(state, item) {
      state.cartItems.push(item);
    },
    SET_CART_ITEMS(state, items) {
      state.cartItems = items;
    },
    CLEAR_CART(state) {
      state.cartItems = [];
    },
    SET_CART_ITEMS_COUNT(state, count) {
      state.cartItemsCount = count;
    },
    removeFromCart(state, item) {
      const index = state.cartItems.findIndex((cartItem) => cartItem.id === item.id);
      if (index !== -1) {
        state.cartItems.splice(index, 1);
      }
    },
    async setCartItemsCount(state, userId) {
      try {
        const count = await AuthService.getCartItemsCount(userId);
        state.cartItemsCount = count.data;
      } catch (error) {
        console.log('Error while fetching cart items count:', error);
      }
    },      
  },
  actions: {
    addToCart({ commit, state }, item) {
      commit('ADD_TO_CART', item);
      commit('SET_CART_ITEMS', item);
      const count = state.cartItemsCount + 1;
      commit('setCartItemsCount', count);
    },
    clearCart({ commit }) {
      commit('CLEAR_CART');
      return Promise.resolve();
    },
  },
};
