<template>
  <div class="password-reset-container">
    <form @submit.prevent="submitEmail" class="password-reset-form">
      <label for="email">Enter your email to reset password</label>
      <input type="email" id="email" class="email-input" v-model="email" placeholder="example@example.com" required autofocus>
      <button type="submit" class="email-submit-btn">Submit</button>
    </form>
    <div class="email-success-message" v-if="emailSuccessMessage">
      <p class="email-success-txt">Password reset link was sent to your email. Please check your email to reset your password.</p>
      <button class="email-client-btn" @click="redirectToEmailClient">Open Email <i class="fa-solid fa-envelope"></i></button>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';

export default {
  data() {
    return {
      email: '',
      emailSuccessMessage: false
    };
  },
  methods: {
    submitEmail(event) {
      event.preventDefault();

      AuthService.forgotPassword(this.email)
        .then(response => {
          if (response.status === 200) {
            this.email = '';
            console.log('Reset email sent successfully');
            this.emailSuccessMessage = true;
          } else {
            console.log('Failed to send reset email');
          }
        })
        .catch(error => {
          if (error.response) {
            console.log('Server responded with an error:', error.response.data);
          } else if (error.request) {
            console.log('No response received from the server');
          } else {
            console.log('Error setting up the request:', error.message);
          }
        });
    },
    redirectToEmailClient() {
      const mailtoUrl = `mailto:${this.email}`;
      window.location.href = mailtoUrl;
    }
  }
}
</script>

<style>
.password-reset-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(172, 0, 22, 1);
  height: 100vh;
}

.password-reset-form {
  max-width: 320px;
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
  border: 2px solid #000000;
  background-color: #e4c364;
  color: #000000;
}

.email-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-top: 10px;
}

.email-submit-btn {
  width: 100%;
  padding: 10px;
  background-color: #000000;
  color: #e4c364;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-weight: 700;
}

.email-submit-btn:hover {
  background-color: #e4c364;
  color: #000000;
  border: 1px solid #000000;
}

.email-success-message {
  font-weight: 700;
  text-align: center;
  margin-top: 10px;
  width: 21%;
}

.email-success-txt {
  background-color: #e4c364;
  padding: 10px;
  border-radius: 3px;
  color: #000000;
  font-size: 1rem;
}

.email-client-btn {
  background-color: #000000;
  color: #e4c364;
  border: none;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
  font-weight: 700;
  margin-top: 10px;
  width: 61%;
}

.email-client-btn:hover {
  background-color: #e4c364;
  color: #000000;
  border: 1px solid #000000;
}

@media screen and (max-width: 450px) {
  .email-success-message {
    width: 90%;
}
}
</style>
