<template>
  <div class="footer-wrapper">
    <footer class="footer-container" v-if="!isPageNotFound">
      <div class="main-section">
        <div class="left-section">
          <div class="jewelry-nepal-section">
            <h3>Jewelry Nepal</h3>
            <p>About Us</p>
            <p>Our Store</p>
            <p>Blog</p>
            <p>Careers</p>
          </div>
          <div class="about-section">
            <h3>Promises</h3>
            <p>Easy Exchange</p>
            <p>3 days return policy</p>
            <p>Guaranteed Buy Sale</p>
            <p>Zero Deduction Exchange</p>
          </div>
          <div class="contact-section">
            <h3>Our Lines</h3>
            <p>Offers</p>
            <p>Appointment</p>
            <p>Scheme Payment</p>
          </div>
          <div class="donation-section">
            <h3>Policies</h3>
            <p>Refund</p>
            <p>Shipping</p>
            <p>Privacy</p>
            <p>Exchange</p>
            <p>Buy Sale</p>
          </div>
          <div class="left-developer">
            <p class="dev-title">Developers</p>
            <div class="dev-container">
              <div class="dev-first">
                <img class="dev-profile" src="../assets/img/dev_pic.png">
                <div class="dev-info">
                  <p class="dev-name">Tula Subba</p>
                  <p><a href="https://github.com/subbat9710" target="_blank"><i class="fa-brands fa-github"></i></a></p>
                </div>
              </div>
              <!-- <div class="dev-second">
                <img class="dev-profile" src="../assets/img/dev_pic.png">
                <div class="dev-info">
                  <p class="dev-name">Netra Pokhrel</p>
                  <p><a href="https://github.com/subbat9710" target="_blank"><i class="fa-brands fa-github"></i></a></p>
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="right-section">
          <div class="map-section">
            <div class="g-map">
              <GmapMap :center="center" :zoom="zoom" style="width: 100%; height: 180px">
                <GmapMarker :position="markerPosition" :clickable="true" :draggable="false" :label="label"></GmapMarker>
              </GmapMap>
            </div>
          </div>
          <div class="social-media">
            <a href="https://www.facebook.com/jewellerynepal/" target="_blank">
              <i class="fa-brands fa-facebook"></i>
            </a>
            <a href="https://www.instagram.com/jewelry.Nepal/?fbclid=IwAR0lbyn9rRNhzBICN8x6fNUagaZZYlTFp8pwlHx9R5kf3c3esASjJ04p1uE"
              target="_blank">
              <i class="fa-brands fa-instagram"></i>
            </a>
            <a href="https://twitter.com/jewelry_nepal?s=21&t=p3S2IT-owJHkzo18mGpu1g" target="_blank">
              <i class="fa-brands fa-square-twitter"></i>
            </a>
            <a href="https://www.tiktok.com/@jewelry.nepal" target="_blank">
              <i class="fa-brands fa-tiktok"></i>
            </a>
          </div>
        </div>
      </div>
      <div class="copy-right">
        © {{ new Date().getFullYear() }} Jewelry Nepal Llc. All Rights Reserved
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      center: { lat: 40.36903, lng: -79.98390 },
      zoom: 14,
      markerPosition: {
        lat: 40.36903,
        lng: -79.98390,
      },
      label: {
        text: "Jewelry Nepal LLC",
        color: "#e4c364",
        fontWeight: "bold",
        fontSize: "14px",
      }
    };
  },
  computed: {
    isPageNotFound() {
      return this.$route.name === 'page-not-found';
    },
  }
};
</script>

<style>
.footer-wrapper {
  display: flex;
  flex-direction: column;
}

.footer-container {
  flex: 0 0 auto;
  background-color: #000000;
  color: #e4c364;
  text-align: center;
  padding: 20px;
}

.main-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

.right-section {
  display: grid;
  grid-template-rows: 1fr;
}

.left-section {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-left: 3rem;
}

.jewelry-nepal-section h3,
.about-section h3,
.contact-section h3,
.donation-section h3 {
  text-decoration: underline;
  text-decoration-skip-ink: none;
  text-underline-offset: 3px;
  font-weight: 800;
}

.jewelry-nepal-section p,
.about-section p,
.contact-section p,
.donation-section p {
  font-size: 13px;
}

.social-media {
  display: flex;
  justify-content: end;
  margin-right: 19rem;
  margin-top: 1rem;
  gap: 1rem;
}

.copy-right {
  font-size: 13px;
}

.g-map {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-left: 2rem;
}

.marker-label {
  background-color: #e4c364;
  color: #000000;
  padding: 5px;
  font-weight: bold;
  border-radius: 5px;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  white-space: nowrap;
  z-index: 1;
}

.social-media .fa-facebook {
  font-size: 24px;
  color: #087bea;
}

.social-media .fa-instagram {
  font-size: 24px;
  color: #eb4a51;
}

.social-media .fa-square-twitter {
  font-size: 24px;
  color: #1da1f2;
}

.social-media .fa-tiktok {
  font-size: 24px;
  color: #ffffff;
}

.social-media i {
  transition: transform 0.3s;
}

.social-media i:hover {
  transform: scale(1.3);
}

.dev-profile {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 5px;
}

.dev-name {
  font-size: 10px;
  color: #ffffff;
  font-weight: 700;
}

.dev-title {
  font-size: 12px;
  color: #ffffff;
}

.dev-info {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.fa-github {
  font-size: 14px;
  margin-bottom: 10px;
  color: #ffffff;
}

.dev-container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
@media screen and (max-width: 830px) {
  .footer-wrapper {
    width: 104%;
  }
}

@media screen and (max-width: 450px) {
  .main-section {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .left-section {
    grid-row: 1;
    grid-column: 1;
    margin-left: 0;
  }

  .right-section {
    grid-row: 2;
    grid-column: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .social-media {
    margin-right: 0;
  }

  .g-map {
    align-items: center;
    justify-content: center;
    width: 180px;
    margin-left: 0;
    margin-right: 2rem;
  }
}</style>