<template>
  <h1>Logout</h1>
</template>

<script>
export default {
  created() {
    //this.$store.dispatch("clearCart"); // Clear the cart
    this.$store.commit("LOGOUT"); // Perform the logout
    this.$router.push("/login"); // Redirect to the login page
  },
};
</script>
