<template>
  <div class="main-product-recommendation">
    <div class="shop-recommend-ad">
      <h3 class="main-recommend-header"><i class="fa-sharp fa-solid fa-bag-shopping"></i> Shop by Top choice</h3>
    </div>
    <div class="navigation-buttons">
      <button class="main-prev-button" @click="scrollProducts('prev')"><i class="fa-solid fa-angles-left"></i></button>
      <button class="main-next-button" @click="scrollProducts('next')"><i class="fa-solid fa-angles-right"></i></button>
    </div>
    <div class="main-recommend-products-container" ref="productsContainer">
      <div v-for="product in filteredProducts" :key="product.productId" class="main-recommend-product">
        <router-link :to="{ name: 'product-details', params: { id: product.productId } }">
          <div class="main-recommend-product-details">
            <p class="product-name-main"><strong>Name:</strong> {{ product.name }}</p>
            <p><strong>Price:</strong> ${{ product.price }}</p>
            <p><strong>Product Type:</strong> {{ product.productType }}</p>
            <p><strong>Gold Purity:</strong> {{ product.goldPurity }}</p>
            <div class="average-rating">
              <span v-if="product.averageRating !== null">
                <span v-for="star in Math.floor(product.averageRating)" :key="star">&#9733;</span>
                ({{ product.totalRaters }})
              </span>
              <span v-else>
                N/A
              </span>
            </div>
          </div>
          <div class="main-recommend-product-image">
            <img :src="product.imageUrl" :alt="product.name">
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
  
<script>
import AuthService from '@/services/AuthService'

export default {
  data() {
    return {
      products: [],
      cartItems: [],
      likeItems: [],
      scrollPosition: 0,
      containerWidth: 0
    }
  },
  created() {
    AuthService.getProductRecommendations().then((response) => {
      this.products = response.data;
    })

    //CartItems
    const userId = this.$store.state.user.id;
    AuthService.getCartItems(userId)
      .then((response) => {
        this.cartItems = response.data;
      })
      .catch((error) => {
        console.log('Error fetching cart items:', error);
      });

    //LikeItems
    AuthService.getLikeProductLists(userId)
      .then((response) => {
        this.likeItems = response.data;
      })
      .catch((error) => {
        console.log('Error fetching wishlist items:', error);
      });
  },
  mounted() {
    this.containerWidth = this.$refs.productsContainer.offsetWidth;
  },
  computed: {
    filteredProducts() {
      return this.products.filter((product) => {
        const isInCart = this.cartItems.some((cartItem) => cartItem.name === product.name);
        const isInWishlist = this.likeItems.some((likeItem) => likeItem.name === product.name);

        return !isInCart && !isInWishlist;
      });
    },
  },
  methods: {
    scrollProducts(direction) {
      const scrollStep = 300;
      const container = this.$refs.productsContainer;

      if (direction === 'prev') {
        this.scrollPosition -= scrollStep;
        if (this.scrollPosition < 0) {
          this.scrollPosition = 0;
        }
      } else if (direction === 'next') {
        this.scrollPosition += scrollStep;
        const maxScroll = container.scrollWidth - container.clientWidth;
        if (this.scrollPosition > maxScroll) {
          this.scrollPosition = maxScroll;
        }
      }

      container.scrollTo({
        left: this.scrollPosition,
        behavior: 'smooth'
      });
    }
  }
}
</script>

<style>
.main-product-recommendation {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.main-recommend-products-container {
  display: flex;
  width: 100%;
  transition: transform 0.3s;
  overflow-x: scroll;
  white-space: nowrap;
  overflow-x: auto;
  padding: 10px;
}

.main-recommend-product {
  position: relative;
  flex: 0 0 auto;
  width: 250px;
  margin-right: 22px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.main-recommend-product:hover .main-recommend-product-details {
  transform: translateY(0);
  opacity: 1;
}

.main-recommend-product-details {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
  transform: translateY(100%);
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
}

.main-recommend-products-container::-webkit-scrollbar {
  display: none;
}

.navigation-buttons {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 1rem;
  z-index: 1;
}

.main-prev-button,
.main-next-button {
  padding: 5px 9px;
  font-size: 1rem;
  color: #3a3838;
  background-color: #e4c364;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.main-prev-button:hover,
.main-next-button:hover {
  background-color: #3a3838;
  color: #e4c364;
}

.main-recommend-header {
  font-size: 2rem;
  font-weight: 700;
  color: #000000;
  margin-top: 10px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Verdana, sans-serif;
  padding: 4px;
}

.main-recommend-product-details p {
  color: #ffffff;
  font-size: 1rem;
  margin-bottom: 5px;
}

.average-rating {
  color: #e4c364;
  margin-bottom: 5px;
  font-weight: 600;
}

.product-name-main {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.main-recommend-product-image img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  height: 260px;
}

.main-prev-button {
  position: absolute;
  left: 0;
}

.main-next-button {
  position: absolute;
  right: 0;
}

@media screen and (max-width: 450px) {
  .main-recommend-product {
    flex: 0 0 auto;
    width: 173px;
  }

  .main-recommend-header {
    text-align: center;
    font-weight: 700;
  }

  .main-recommend-product-image img {
    height: 180px;
  }
}
</style>

