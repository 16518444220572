<template>
  <div class="like-product-container">
    <h1 class="like-header">Your Favorite Product</h1>
    <div v-if="likeItems.length === 0" class="empty-like-message">
      <img class="empty-like-image" src="https://cdn.pixabay.com/animation/2022/08/23/03/32/03-32-04-108_512.gif">
      <p><strong>You don't have any Favorite items yet.</strong></p>
      <p>Looks like you have not added anything to your favorite cart. Go ahead and explore top categories</p>
      <button class="continue-shopping" @click="$router.push({ name: 'main-page' })">Continue Shopping</button>
    </div>
    <div v-for="likeItem in likeItems" :key="likeItem.id" class="liked-items">
      <div class="item-details">
        <p>Product Name: <strong>{{ likeItem.name }}</strong></p>
        <p>Price: <strong>$ {{ likeItem.price }}</strong></p>
        <p>Quantity: <strong>{{ likeItem.quantity }}</strong></p>
        <p>Product Type: <strong>{{ likeItem.type }}</strong></p>
      </div>
      <div class="wishlist-button">
        <button class="like-cart-button" @click="addProductToCart(likeItem)" :class="{ clicked: likeItem.isClicked }">
          <span v-if="likeItem.isClicked" class="added active">Added to cart</span>
          <span v-else class="add-to-cart active">Add to cart</span>
          <i class="fas fa-shopping-cart"></i>
          <i class="fas fa-box"></i>
        </button>
        <button class="delete-like-button" @click="removeItem(likeItem)">
          <span class="add-to-delete">Remove Item</span>
        </button>
      </div>
    </div>
    <product-recommendation></product-recommendation>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService';
import ProductRecommendation from './ProductRecommendation.vue';
import router from '@/router';

export default {
  components: {
    ProductRecommendation
  },
  data() {
    return {
      likeItems: [],
      cartItems: [],
    }
  },
  created() {
    const userId = this.$store.state.user.id;
    AuthService.getLikeProductLists(userId)
      .then((response) => {
        this.likeItems = response.data.map((likeItem) => ({
          ...likeItem,
          userId: userId,
          isClicked: false
        }));
        this.$store.commit('SET_LIKE_ITEMS', this.likeItems);
        this.updateLikeItemsCount();
        this.getCartItems();
      }).catch((error) => {
        console.log('Error fetching like items:', error)
      });
  },
  computed: {
    likeItemsCount() {
      return this.$store.state.likeItemsCount;
    }
  },
  methods: {
    getCartItems() {
      const userId = this.$store.state.user.id;
      AuthService.getCartItems(userId)
        .then((response) => {
          const cartItems = response.data;
          this.likeItems.forEach((likeItem) => {
            const existingItem = cartItems.find(item => item.name === likeItem.name);
            if (existingItem) {
              likeItem.isClicked = true;
            }
          });
        })
        .catch((error) => {
          console.log('Error fetching cart items:', error);
        })
    },
    getItemClickedState(itemId) {
      const cartItems = this.$store.state.cart.cartItems;
      console.log('cart items like:', cartItems);
      return cartItems.some(item => item.id === itemId);
    },

    setItemClickedState(itemId, isClicked) {
      const clickedItems = JSON.parse(localStorage.getItem('clickedItems')) || {};
      clickedItems[itemId] = isClicked;
      localStorage.setItem('clickedItems', JSON.stringify(clickedItems));
    },
    removeItem(likeItem) {
      const userId = this.$store.state.user.id;
      const itemId = likeItem.id;

      AuthService.removeLikeItem(itemId, userId)
        .then(() => {
          const index = this.likeItems.findIndex((item) => item.id === itemId);
          if (index !== -1) {
            this.$store.commit('removeFromLike', likeItem);
            this.updateLikeItemsCount();
          }
        })
        .catch((error) => console.log(error));

    },
    addProductToCart(likeItem) {
      if (this.getItemClickedState(likeItem.id)) {
        return;
      }

      if (this.$store.state.user.id) {
        const itemName = likeItem.name;
        const itemPrice = likeItem.price;
        const itemType = likeItem.type;
        const existingItem = this.$store.state.cart.cartItems.find(item => item.name === itemName);

        if (existingItem) {
          existingItem.isClicked = true;
          return;
        }

        const userId = this.$store.state.user.id;
        const cartData = {
          itemsList: [
            {
              type: itemType,
              name: itemName,
              quantity: 1,
              price: itemPrice
            }
          ],
          userId: userId
        };

        likeItem.isClicked = true;

        AuthService.addToCart(cartData)
          .then(response => {
            response.data;
            const item = {
              type: itemType,
              name: itemName,
              quantity: 1,
              price: itemPrice,
              isClicked: true
            };

            this.$store.commit('ADD_TO_CART', item);
            this.$store.dispatch('setCartItemsCount', userId);
          })
          .catch(error => {
            console.log('Error adding to cart:', error);
          });
      } else {
        sessionStorage.setItem('redirectURL', this.$route.path);
        router.push({ name: 'login' });
      }
    },
    updateLikeItemsCount() {
      const count = this.likeItems.length;
      this.$store.dispatch('setLikeItemsCount', count);
    },
  },
}
</script>

<style>
.like-product-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1384px;
  margin: 0 auto;
  padding: 0 20px;
}

.like-header:before {
  content: '';
  position: absolute;
  width: 45px;
  height: 15px;
  border-bottom: 4px solid;
  top: 22px;
  margin-left: -60px;
}

.like-header:after {
  content: '';
  position: absolute;
  width: 45px;
  height: 15px;
  border-bottom: 4px solid;
  top: 23px;
  margin-left: 15px;
}

.like-header {
  font-family: Muli-Semi-Bold;
  color: #000;
  position: relative;
  text-transform: capitalize;
  text-align: center;
  font-size: 3rem;
  margin-bottom: 0.4rem;
  margin-top: 70px;
}

.empty-like-message {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.empty-like-message i {
  font-size: 10rem;
}

.liked-items {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 40%;
  padding: 10px;
  background-color: #e4c364;
  color: #000000;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.wishlist-button {
  display: flex;
  flex-direction: column;
}

/*add to cart button animation*/
.like-cart-button {
  position: relative;
  width: 200px;
  height: 2.4rem;
  border: 0;
  border-radius: 10px;
  background-color: #000000;
  outline: none;
  cursor: pointer;
  color: #e4c364;
  transition: 0.3s ease-in-out;
  overflow: hidden;
}

.like-cart-button:hover {
  background-color: #000000;
  border: 1px solid #e4c364;
}

.like-cart-button:active {
  transform: scale(0.9);
}

.like-cart-button .fa-shopping-cart {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: -10%;
  font-size: 1.6em;
  transform: translate(-50%, -50%);
}

.like-cart-button .fa-box {
  position: absolute;
  z-index: 3;
  top: -20%;
  left: 52%;
  font-size: 1.2em;
  transform: translate(-50%, -50%);
}

.like-cart-button span {
  position: absolute;
  z-index: 3;
  left: 50%;
  top: 50%;
  font-size: 1.2em;
  color: #fff;
  transform: translate(-50%, -50%);
}

.like-cart-button span.add-to-cart {
  opacity: 1;
}

.like-cart-button span.added {
  opacity: 0;
}

.like-cart-button.clicked .fa-shopping-cart {
  animation: cart 1.5s ease-in-out forwards;
}

.like-cart-button.clicked .fa-box {
  animation: box 1.5s ease-in-out forwards;
}

.like-cart-button.clicked span.add-to-cart {
  animation: txt1 1.5s ease-in-out forwards;
}

.like-cart-button.clicked span.added {
  animation: txt2 1.5s ease-in-out forwards;
}

@keyframes cart {
  0% {
    left: -10%;
  }

  40%,
  60% {
    left: 50%;
  }

  100% {
    left: 110%;
  }
}

@keyframes box {

  0%,
  40% {
    top: -20%;
  }

  60% {
    top: 40%;
    left: 52%;
  }

  100% {
    top: 40%;
    left: 112%;
  }
}

@keyframes txt1 {
  0% {
    opacity: 1;
  }

  20%,
  100% {
    opacity: 0;
  }
}

@keyframes txt2 {

  0%,
  80% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

/*delete button */
.delete-like-button {
  position: relative;
  padding: 10px;
  width: 200px;
  height: 2.4rem;
  border: 0;
  border-radius: 10px;
  background-color: #f02a2a;
  outline: none;
  cursor: pointer;
  color: #fff;
  transition: 0.3s ease-in-out;
  overflow: hidden;
  margin-right: 1rem;
  margin-top: 4px;
}

.delete-like-button:hover {
  background-color: #9b2639;
}

.empty-like-image {
  width: 40%;
}

@media screen and (max-width: 830px) {
  .liked-items {
    width: 80%;
  }
}

@media screen and (max-width: 450px) {

  .like-header {
    font-size: 2rem;
    margin-top: 80px;
  }

  .like-header:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 15px;
    border-bottom: 4px solid;
    top: 12px;
    margin-left: -14px;
  }

  .like-header:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 15px;
    border-bottom: 4px solid;
    top: 12px;
    margin-left: 10px;
  }

  .liked-items {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .wishlist-button {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 10px;
    align-items: center;
  }

  .continue-shopping {
    width: 90%;
  }

  .delete-like-button {
    margin-right: 0;
  }
}
</style>