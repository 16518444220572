<template>
  <div class="cart-container">
    <h1 class="cart-section">Your Cart Item List</h1>
    <div v-if="cartItems.length === 0" class="empty-cart-message">
      <img src="../assets/img/emptycart.png" class="centered-image">
      <p><strong>Your cart is empty.</strong></p>
      <p>Looks like you have not added anything to your cart. Go ahead and explore top categories</p>
      <button class="continue-shopping" @click="$router.push({ name: 'main-page' })">Continue Shopping</button>
    </div>

    <div v-for="cartItem in cartItems" :key="cartItem.id" class="cart-item">
      <div class="item-details">
        <p>Product Name: <strong>{{ cartItem.name }}</strong></p>
        <p>Price: <strong>$ {{ cartItem.price }}</strong></p>
        <p>Quantity: <strong>{{ cartItem.quantity }}</strong></p>
        <p>Product Type: <strong>{{ cartItem.type }}</strong></p>
      </div>
      <button @click="removeCartItem(cartItem)" class="remove-button">Remove Item</button>
    </div>
    <div v-if="cartItems.length > 0" class="cart-total">
      <p>Product Total: ${{ calculateTotal() }}</p>
    </div>
    <button v-if="cartItems.length > 0" type="submit" class="place-order" @click="$router.push({ name: 'checkout' })">Place Order</button>
    <product-recommendation></product-recommendation>
  </div>
</template>
  
<script>
import AuthService from '@/services/AuthService';
import ProductRecommendation from './ProductRecommendation.vue';

export default {
  components: {
    ProductRecommendation
  },
  data() {
    return {
      cartItems: [],
    };
  },
  created() {
    const userId = this.$store.state.user.id;

    AuthService.getCartItems(userId)
      .then((response) => {
        this.cartItems = response.data.map((cartItem) => ({
          ...cartItem,
          userId: userId,
        }));
        this.$store.commit('SET_CART_ITEMS', this.cartItems);
        this.updateCartItemsCount();
      })
      .catch((error) => {
        console.log('Error fetching cart items:', error);
      });
  },
  computed: {
    cartItemsCount() {
      return this.$store.state.cartItemsCount;
    },
  },
  methods: {
    removeCartItem(cartItem) {
      const userId = this.$store.state.user.id;
      const itemId = cartItem.id;

      AuthService.removeItemfromCart(itemId, userId)
        .then(() => {
          const index = this.cartItems.findIndex((item) => item.id === itemId);
          if (index !== -1) {
            this.cartItems.splice(index, 1);
            this.$store.commit('removeFromCart', cartItem);
            this.updateCartItemsCount();
          }
        })
        .catch((error) => console.log(error));
    },
    calculateTotal() {
      const userId = this.$store.state.user.id;
      const userCartItems = this.cartItems.filter((cartItem) => cartItem.userId === userId);
      let total = 0;

      for (const cartItem of userCartItems) {
        total += cartItem.price * cartItem.quantity;
      }

      return total;
    },
    updateCartItemsCount() {
      const count = this.cartItems.length;
      this.$store.dispatch('setCartItemsCount', count);
    },
  },
};
</script>
  
  
<style>
.cart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1384px;
  margin: 0 auto;
  padding: 0 20px;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  width: 40%;
  padding: 10px;
  background-color: #e4c364;
  color: #000000;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.item-details {
  flex: 1;
}

.remove-button {
  position: relative;
  padding: 10px;
  width: 170px;
  height: 2.4rem;
  border: 0;
  border-radius: 10px;
  background-color: #f02a2a;
  outline: none;
  cursor: pointer;
  color: #fff;
  transition: 0.3s ease-in-out;
  overflow: hidden;
  margin-right: 1rem;
  margin-top: 4px;
}

.remove-button:hover {
  background-color: #9b2639;
}

.cart-total {
  margin-top: 1rem;
  font-weight: bold;
}

.place-order {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 4px;
  font-weight: bold;
  background-color: #000000;
  color: #e4c364;
  border: none;
  cursor: pointer;
  width: 14%;
  margin: 1rem;
  transition: background-color 0.3s;
}

.place-order:hover {
  background-color: #e4c364;
  color: #000000;
  border: 1px solid #000000;
}

.cart-section:before {
  content: '';
  position: absolute;
  width: 45px;
  height: 15px;
  border-bottom: 4px solid;
  top: 22px;
  margin-left: -60px;
}

.cart-section:after {
  content: '';
  position: absolute;
  width: 45px;
  height: 15px;
  border-bottom: 4px solid;
  top: 23px;
  margin-left: 15px;
}

.cart-section {
  font-family: Muli-Semi-Bold;
  color: #000000;
  font-size: 3rem;
  position: relative;
  text-transform: capitalize;
  text-align: center;
  margin-top: 70px;
}

.empty-cart-message {
  justify-content: center;
  align-items: center;
  text-align: center;
}
.centered-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  transition: opacity 0.3s ease;
}

.centered-image:hover {
  transform: scale(1.05);
}

.continue-shopping {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 4px;
  font-weight: bold;
  background-color: #000000;
  color: #e4c364;
  border: none;
  cursor: pointer;
  width: 40%;
  margin: 1rem;
  transition: background-color 0.3s;
}
.continue-shopping:hover {
  background-color: #e4c364;
  color: #000000;
  border: 1px solid #000000;
}

@media screen and (max-width: 830px) {
  .cart-item {
    width: 80%;
  }

  .place-order {
    width: 40%;
  }
}

@media screen and (max-width: 450px) {
  .cart-section {
    font-size: 2rem;
    margin-top: 80px;
  }

  .cart-section:before {
    content: '';
    position: absolute;
    width: 8px;
    height: 15px;
    border-bottom: 4px solid;
    top: 12px;
    margin-left: -14px;
  }

  .cart-section:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 15px;
    border-bottom: 4px solid;
    top: 12px;
    margin-left: 10px;
  }

  .cart-item {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .cart-item button {
    width: 100px;
  }

  .place-order {
    width: 100%;
  }

  .continue-shopping {
    width: 90%;
  }

  .remove-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
  }

  .remove-button {
    width: auto;
  }
}
</style>
  