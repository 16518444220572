<template>
  <div id="login">
    <div class="image-container-login">
      <router-link class="banner" to="/">
        <img class="banner" src="../assets/img/bannerimg.png">
      </router-link>
    </div>
    <div class="form-container">
      <form @submit.prevent="login" id="login-form">
        <h1 class="login-header">Please Sign In</h1>
        <div role="alert" v-if="invalidCredentials" class="login-alert">
          {{ invalidCredentialsMessage }}
        </div>
        <div class="login-alert" role="alert" v-if="registrationQuery">
          {{ registrationMessage }}
        </div>
        <div class="form-input-group">
          <input type="text" id="username" v-model="user.username" placeholder="Username" required autofocus />
        </div>
        <div class="form-input-group">
          <input type="password" id="password" v-model="user.password" placeholder="Password" required autofocus />
        </div>
        <button class="signin" type="submit">Sign in</button>
        <p class="register-link">
          <router-link :to="{ name: 'register' }">Need an account? Sign up.</router-link>
        </p>
        <p class="forgot-password-link">
          <router-link :to="{ name: 'reset-link' }">Forgot Password?</router-link>
        </p>
      </form>
    </div>
  </div>
</template>

<script>
import authService from "../services/AuthService";
import VueDeviceDetector from 'vue-device-detector';

export default {
  name: "logIn",
  mixins: [VueDeviceDetector],
  data() {
    return {
      user: {
        username: "",
        password: ""
      },
      invalidCredentials: false
    };
  },
  computed: {
    invalidCredentialsMessage() {
      // Encode the error message to prevent XSS attacks
      return this.invalidCredentials ? this.encodeHTML('Invalid username and password!') : '';
    },
    registrationQuery() {
      // Encode the query parameter to prevent XSS attacks
      return this.encodeHTML(this.$route.query.registration);
    },
    registrationMessage() {
      // Encode the message to prevent XSS attacks
      return this.encodeHTML('Thank you for registering, please sign in.');
    }
  },
  methods: {
    login() {
      // Sanitize and validate the username and password inputs
      const sanitizedUsername = this.sanitizeString(this.user.username);
      const sanitizedPassword = this.sanitizeString(this.user.password);

      // Validate the sanitized inputs
      if (!sanitizedUsername || !sanitizedPassword) {
        return; // Stop further execution if validation fails
      }

      const deviceName = this.getDeviceName();

      if ("geolocation" in navigator) {
        navigator.permissions.query({ name: 'geolocation' }).then((result) => {
          if (result.state === 'granted') {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;

                this.performLogin(sanitizedUsername, sanitizedPassword, latitude, longitude, deviceName);
              },
              (error) => {
                console.error("Error retrieving user's location:", error);
              }
            );
          } else {
            // Location access is blocked or unavailable
            this.performLogin(sanitizedUsername, sanitizedPassword, null, null, deviceName);
          }
        });
      } else {
        this.showLocationAccessMessage();
      }
    },
    async performLogin(username, password, latitude, longitude, deviceName) {
      if (!latitude || !longitude) {
        // Get the user's IP address
        fetch('https://api.ipify.org/?format=json')
          .then((response) => response.json())
          .then((data) => {
            const ipAddress = data.ip;

            // Make a request to the IP geolocation API service
            fetch(`https://ipapi.co/${ipAddress}/json/`)
              .then((response) => response.json())
              .then((locationData) => {
                // Extract the latitude and longitude from the location data
                const latitude = locationData.latitude;
                const longitude = locationData.longitude;

                // Perform the login with the obtained location
                this.performLogin(username, password, latitude, longitude, deviceName);
              })
              .catch((error) => {
                console.error('Error obtaining user location:', error);
                this.performLogin(username, password, null, null, deviceName);
              });
          })
          .catch((error) => {
            console.error('Error obtaining user IP address:', error);
            this.performLogin(username, password, null, null, deviceName);
          });
        return;
      }

      authService.login({
        username,
        password,
        latitude,
        longitude,
        deviceName
      })
        .then(response => {
          if (response.status === 200) {
            this.$store.commit("SET_AUTH_TOKEN", response.data.token);
            this.$store.commit("SET_USER", response.data.user);
            const role = this.$store.state.user.authorities[0].name;
            if (role === "ROLE_ADMIN") {
              this.$router.push("/admin");
            } else {
              const redirectURL = sessionStorage.getItem('redirectURL');
              sessionStorage.removeItem('redirectURL');

              if (redirectURL && !redirectURL.startsWith("http")) {
                this.$router.push(redirectURL);
              } else {
                this.$router.push("/");
              }
            }
          }
        })
        .catch(error => {
          const response = error.response;

          if (response.status === 401) {
            this.invalidCredentials = true;
          }
        });
    },

    sanitizeString(input) {
      // Perform necessary sanitization logic, e.g., removing HTML tags, special characters, etc.
      const sanitizedInput = input.replace(/<[^>]+>/g, '');
      return sanitizedInput;
    },

    encodeHTML(input) {
      // Encode the input string to prevent XSS attacks
      const textarea = document.createElement('textarea');
      textarea.textContent = input;
      return textarea.innerHTML;
    },
    getDeviceName() {
      const userAgent = navigator.userAgent;
      if (userAgent.includes("Windows")) {
        return "Window";
      } else if (userAgent.includes("Mac")) {
        return "Mac";
      } else if (userAgent.includes("Android")) {
        return "Android";
      } else if (userAgent.includes("iOS")) {
        return "iOS";
      } else {
        return "Unknown";
      }
    },
  }
};
</script>
  
<style scoped>
.login-header {
  font-size: 2rem;
  text-align: center;
  margin-top: -1rem;
  color: #000000;
}

#login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: rgba(172, 0, 22, 1);
  margin: 0;
  padding: 0;
}

.image-container-login {
  flex: 1;
  display: flex;
  justify-content: end;
  align-items: center;
}

.form-container {
  flex: 1;
  display: flex;
  justify-content: start;
  align-items: start;
}

.banner {
  width: 90%;
  height: auto;
  display: flex;
  justify-content: end;
}

#login-form {
  background-color: #e4c364;
  margin: 0 auto;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 40%;
  border: 2px solid #000000;
}

.form-input-group {
  margin-bottom: 8px;
}

label {
  margin-bottom: 0.5rem;
  color: #000000;
}

input[type="text"],
input[type="password"] {
  width: 100%;
  padding: 0.5rem;
  font-size: 1rem;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.signin {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 4px;
  font-weight: bold;
  background-color: #000000;
  color: #e4c364;
  border: none;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s;
}

.signin:hover {
  background-color: #e4c364;
  color: #000000;
  border: 1px solid;
  border-collapse: #000000;
}

.login-alert {
  text-align: center;
  color: rgb(164, 36, 36);
  background-color: #f8d7da;
  padding: 4px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  margin-bottom: 1rem;
  font-size: 16px;
}

.register-link {
  margin-top: 0.2rem;
  text-align: center;
  color: #000000;
  font-size: 16px;
}

.forgot-password-link {
  text-align: center;
  font-size: 16px;
}

@media screen and (max-width: 450px) {
  #login {
    flex-direction: column;
    align-items: center;
  }

  .image-container-login {
    justify-content: left;
    align-items: center;
    width: 100%;
    margin-top: 10rem;
  }

  .form-container {
    width: 90%;
    justify-content: center;
    align-items: center;
  }

  #login-form {
    width: 100%;
    margin-bottom: 15rem;
  }
}

@media screen and (max-width: 380px) {
  #login {
    flex-direction: column;
    align-items: center;
  }

  .image-container {
    justify-content: left;
    align-items: center;
    width: 100%;
    margin-bottom: -16rem;
  }

  .form-container {
    width: 90%;
    justify-content: center;
    align-items: center;
  }

  #login-form {
    width: 100%;
    margin-bottom: 3rem;
  }
}
</style>