import Vue from 'vue'
import Router from 'vue-router'
//import Home from '../views/HomePage.vue'
import Login from '../views/LogIn.vue'
import Logout from '../views/LogOut.vue'
import Register from '../views/RegisterUser.vue'
import store from '../store/index'
import MainPage from '../components/MainPage.vue'
import RaniHaar from '../components/RaniHaar.vue'
import RingPage from '../components/RingPage.vue'
import BraceletsPage from '../components/BraceletsPage.vue'
import NecklacePage from '../components/NecklacePage.vue'
import ReviewPage from '../components/ReviewPage.vue'
import ProductDetails from '../components/ProductDetails.vue'
import CartPage from '../components/CartPage.vue'
import ProcessCheckout from '../components/ProcessCheckout.vue'
import AdminPage from '../components/AdminPage.vue'
import PendingOrders from '../components/PendingOrders.vue'
import CompletedOrders from '../components/CompletedOrders.vue'
import LikedProducts from '../components/LikedProducts.vue'
import PhilantropyService from '../components/PhilantropyService.vue'
import ImageSlides from '../components/ImageSlides.vue'
import AddProduct from '../components/AddProduct.vue'
import ProductPage from '../components/ProductPage.vue'
import SearchResultsMore from '../components/SearchResultsMore.vue'
import MangalSutra from '../components/MangalSutra.vue'
import EmployeeManagement from '../components/EmployeeManagement.vue'
import ResetLink from '../views/SendResetLink.vue'
import PasswordReset from '../views/PasswordReset.vue'
import SendEmailToUser from '../components/SendEmail.vue'
import UserProfile from '../components/UserProfile.vue'
import PageNotFound from '../views/PageNotFound.vue'
import CustomPurchase from '../components/CustomPurchase.vue'

Vue.use(Router)

/**
 * The Vue Router is used to "direct" the browser to render a specific view component
 * inside of App.vue depending on the URL.
 *
 * It also is used to detect whether or not a route requires the user to have first authenticated.
 * If the user has not yet authenticated (and needs to) they are redirected to /login
 * If they have (or don't need to) they're allowed to go about their way.
 */

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'main-page',
      component: MainPage,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        requiresAuth: false
      }
    },   
    {
      path: "/logout",
      name: "logout",
      component: Logout,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/register",
      name: "register",
      component: Register,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/rest-link",
      name: "reset-link",
      component: ResetLink,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/password-reset",
      name: "password-reset",
      component: PasswordReset,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/rani-haar",
      name: "rani-haar",
      component: RaniHaar,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/rings",
      name: "rings",
      component: RingPage,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/bracelets",
      name: "bracelets",
      component: BraceletsPage,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/necklace",
      name: "necklace",
      component: NecklacePage,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/mangal-sutra",
      name: "mangal-sutra",
      component: MangalSutra,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/product-details/:id", // Add ":id" to capture the productId in the URL
      name: "product-details",
      component: ProductDetails,
      meta: {
        requiresAuth: false
      }
    }, 
    {
      path: "/main-page",
      name: "mainpage",
      component: MainPage,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/reviews/:id",
      name: "reviews",
      component: ReviewPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/cart",
      name: "cart",
      component: CartPage,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/admin",
      name: "adminpage",
      component: AdminPage,
      meta: {
        requiresAdmin: true
      }
    },
    {
      path: "/checkout",
      name: "checkout",
      component: ProcessCheckout,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/pending-orders",
      name: "pending-orders",
      component: PendingOrders,
      meta: {
        requiresAdmin: true
      }
    },
    {
      path: "/completed-orders",
      name: "completed-orders",
      component: CompletedOrders,
      meta: {
        requiresAdmin: true
      }
    },
    {
      path: "/liked-products",
      name: "liked-products",
      component: LikedProducts,
      meta: {
        requiresAuth: true
      },
    },
    {
      path: "/donations",
      name: "donations-service",
      component: PhilantropyService,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/slides",
      name: "image-slides",
      component: ImageSlides,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/add-product",
      name: "add-product",
      component: AddProduct,
      meta: {
        requiresAdmin: true
      }
    },
    {
      path: "/product-page",
      name: "product-page",
      component: ProductPage,
      meta: {
        requiresAuth: false
      }
    },
    {
      path: "/employee-mgm",
      name: "employee-management",
      component: EmployeeManagement,
      meta: {
        requiresSuperAdmin: true
      }
    },
    {
      path: "/send-email",
      name: "send-email",
      component: SendEmailToUser,
      meta: {
        requiresSuperAdmin: true
      }
    },
    {
      path: "/user-profile",
      name: "user-profile",
      component: UserProfile,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "*",
      name: "page-not-found",
      component: PageNotFound,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: "/custom-purchase",
      name: "custom-purchase",
      component: CustomPurchase,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: '/search-results-more',
      name: 'search-results-more',
      component: SearchResultsMore,
      props: (route) => ({
        filteredProducts: route.params.filteredProducts,
      }),
      meta: {
        requiresAuth: false
      }
    },

  ]
})

router.beforeEach((to, from, next) => {
  // Determine if the route requires Authentication
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth);

  // If it does and they are not logged in, send the user to "/login"
  if (requiresAuth && store.state.token === '') {
    next("/login");
  } else {
    // Else let them go to their next destination
    next();
  }
});

router.beforeEach(async (to, from, next) => {
  const requiresAdmin = to.matched.some(x => x.meta.requiresAdmin);

  if (requiresAdmin) {
    if (store.state.token === '') {
      next("/login");
    } else {
      const userRole = store.state.user.authorities[0].name;
      //console.log('User Role', userRole);
      if (userRole === 'ROLE_ADMIN' || userRole === 'ROLE_SUPER_ADMIN') {
        next();
      } else {
        next('/');
      }
    }
  } else {
    next();
  }
});

router.beforeEach(async (to, from, next) => {
  const requiresSuperAdmin = to.matched.some(x => x.meta.requiresSuperAdmin);

  if (requiresSuperAdmin) {
    if (store.state.token === '') {
      next("/login");
    } else {
      const userRole = store.state.user.authorities[0].name;
      //console.log('User Role', userRole);
      if (userRole === 'ROLE_SUPER_ADMIN') {
        next();
      } else {
        next('/');
      }
    }
  } else {
    next();
  }
});

export default router;
