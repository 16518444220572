<template>
  <div class="order-container">
    <h1 class="orders-header">List of orders Received</h1>
    <div class="order-section">
      <table class="table-container-pending">
        <table class="order-table">
          <thead>
            <tr>
              <th>Customer Name</th>
              <th>Order Number</th>
              <th>Product Name</th>
              <th>Order Cost</th>
              <th>Order Status</th>
              <th>Order Date</th>
              <th>Order Type</th>
              <th>Quantity</th>
              <th>Product Type</th>
              <th>Price</th>
              <th>Image</th>
              <th>Instructions</th>
              <th>Change Status</th>
            </tr>
            <td></td>
            <td>
              <input type="number" id="numberSearch" placeholder="Order No" v-model="orderNumberSearch" />
              <br><button v-on:click="orderNumberSearch = ''">Clear</button>
            </td>
            <td></td>
            <td></td>
            <td>
              <select v-model="filterStatus" name="filterStatus" id="dropdown">
                <option value="All">All</option>
                <option value="Pending">Pending</option>
                <option value="In Progress">In Progress</option>
                <option value="Completed">Completed</option>
                <option value="Ready">Ready</option>
                <option value="Cancelled">Cancelled</option>
              </select>
            </td>
            <td>
              <div v-show="this.invalidTime">Understand linear time much?</div><strong>From:</strong> <input type="date"
                id="timeFrom" v-model="filterForm" /><br><strong>To:</strong> <input type="date" id="timeTo"
                v-model="filterTo" />
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </thead>
          <tbody>
            <tr v-for="order in filteredOrders" :key="order.orderId">
              <td>{{ order.firstname }} {{ order.lastname }}</td>
              <td>{{ order.orderId }}</td>
              <td>{{ order.name }}</td>
              <td>${{ order.orderCost }}</td>
              <td>{{ order.orderStatus }}</td>
              <td>{{ order.orderDate }}</td>
              <td style="position: relative;">
                {{ order.orderType }}
                <i class="fa-solid fa-house" style="position: absolute; bottom: 0; right: 0; padding: 4px;"
                  @click="showAddressPopup(order)"></i>
                <div v-show="order.showAddress" class="address-popup">
                  <div class="address-container">
                    <p>Home Type: <strong>{{ order.address.homeType }}</strong></p>
                    <p>Street Address: <strong>{{ order.address.streetAddress }}</strong></p>
                    <p>Apt. No: <strong>{{ order.address.apartmentNumber }}</strong></p>
                    <p>State: <strong>{{ order.address.state }}</strong></p>
                    <p>City: <strong>{{ order.address.city }}</strong></p>
                    <p>Zip Code: <strong>{{ order.address.zipCode }}</strong></p>
                  </div>
                  <button class="pending-order-close-button" @click="hideAddressPopup(order)">Close</button>
                </div>
              </td>
              <td>{{ order.quantity }}</td>
              <td>{{ order.productType }}</td>
              <td>${{ order.price }}</td>
              <td>
                <img :src="order.imageUrl" alt="Image">
              </td>
              <td>{{ order.instruction }}</td>
              <td>
                <div class="status-buttons">
                  <button class="cancel-button" v-on:click="markCancelled(order)"
                    v-show="order.orderStatus != 'Cancelled'">Cancel</button>
                  <button class="ready-button" v-on:click="markReady(order)"
                    v-show="order.orderStatus != 'Ready'">Ready</button>
                  <button class="completed-button" v-on:click="markCompleted(order)"
                    v-show="order.orderStatus != 'Completed'">Completed</button>
                  <button class="in-progress-button" v-on:click="markInProgress(order)"
                    v-show="order.orderStatus != 'In Progress'">In Progress</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </table>
    </div>
  </div>
</template>

<script>
import AuthService from '@/services/AuthService'
import axios from 'axios';

export default {
  data() {
    return {
      orders: [],
      filterStatus: "Pending",
      order2: false,
      orderNumberSearch: "",
      filterForm: "",
      filterTo: "",
      address: {},
    }
  },
  computed: {
    filteredOrders() {
      if (this.filterStatus === "All") {
        return this.orders;
      } else {
        return this.orders.filter(order => {
          const orderId = parseInt(this.orderNumberSearch); // Convert to number
          const matchOrderId = isNaN(orderId) || order.orderId === orderId;

          return order.orderStatus === this.filterStatus &&
            matchOrderId &&
            (this.filterForm === "" || this.filterTo === "" || (order.orderDate >= this.filterForm && order.orderDate <= this.filterTo));
        });
      }
    }
  },
  created() {
    AuthService.getOrders()
      .then((response) => {
        this.orders = response.data.map(order => ({
          ...order,
          showAddress: false,
          address: {}
        }));
      })
      .catch((error) => {
        console.log("Failed to get orders", error);
      });
  },
  methods: {
    resetForm() {
      this.filterStatus = "Pending";
      this.orderNumberSearch = "";
      this.timeFrom = "";
      this.timeTo = "";
    },
    showAddressPopup(order) {
      if (!order.address.homeType) {
        this.getAddressByOrderId(order.orderId, order);
      }
      order.showAddress = true;
    },
    hideAddressPopup(order) {
      order.showAddress = false;
    },
    getAddressByOrderId(orderId, order) {
      AuthService.getAddress(orderId)
        .then((response) => {
          order.address = response.data;
        })
        .catch((error) => {
          console.log("Failed to get address", error);
        });
    },
    markCancelled(order) {
      const userId = this.$store.state.user.id;
      axios.put(`/api/order/update-status?orderId=${order.orderId}&updateStatus=Cancelled&userId=${userId}`)
        .then((response) => {
          this.order2 = response.data;
          if (this.order2) {
            order.orderStatus = "Cancelled";
          }
        })
    },
    markReady(order) {
      const userId = this.$store.state.user.id;
      axios.put(`/api/order/update-status?orderId=${order.orderId}&updateStatus=Ready&userId=${userId}`)
        .then((response) => {
          this.order2 = response.data;
          if (this.order2) {
            order.orderStatus = "Ready";
          }
        })
    },
    markCompleted(order) {
      const userId = this.$store.state.user.id;
      axios.put(`/api/order/update-status?orderId=${order.orderId}&updateStatus=Completed&userId=${userId}`)
        .then((response) => {
          this.order2 = response.data;
          if (this.order2) {
            order.orderStatus = "Completed";
          }
        })
    },
    markInProgress(order) {
      const userId = this.$store.state.user.id;
      axios.put(`/api/order/update-status?orderId=${order.orderId}&updateStatus=In%20Progress&userId=${userId}`)
        .then((response) => {
          this.order2 = response.data;
          if (this.order2) {
            order.orderStatus = "In Progress";
          }
        })
    }
  }
}
</script>

<style>
.table-container-pending {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: fit-content;
}

.order-container {
  margin-top: 80px;
}

.order-section {
  overflow: scroll;
  scrollbar-color: #d5ac68 #f1db9d;
  scrollbar-width: thin;
  margin: 1rem;
}
.order-section::-webkit-scrollbar-thumb {
  height: 5px;
  background-color: #000000;
}

.order-section::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.order-section::-webkit-scrollbar {
  height: 5px;
}

.order-section::-webkit-scrollbar-thumb:hover {
  background-color: #000000;
}

.order-section::-webkit-scrollbar:vertical {
  display: none;
}

.orders-header {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
}

.order-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
  width: max-content;
  margin-bottom: 1rem;
}

.order-table th,
.order-table td,
.order-table tr {
  padding: 10px;
  text-align: left;
  border: 1px solid #e4c364;
  border: 1px solid #e4c364;
}

.order-table th {
  background-color: #f5f5f5;
}

.order-table tr:hover {
  background-color: #f9f9f9;
}

.order-table tbody tr:nth-child(even):hover {
  background-color: #f8eed3;
}

.order-table tbody tr:last-child td {
  border-bottom: #e4c364;
}

.order-table td:nth-child(1),
.order-table td:nth-child(12) {
  font-weight: bold;
}

.order-table td:nth-child(1) {
  color: #000000;
}

.order-table td:nth-child(11) {
  width: 100px;
}

.order-table td:nth-child(12) {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.order-table th:last-child,
.order-table td:last-child {
  border-right: 1px solid #e4c364;
}

.order-table th:first-child,
.order-table td:first-child {
  border-left: 1px solid #e4c364;
  width: 30px;
}

.status-buttons {
  display: flex;
  justify-content: space-between;
}

.cancel-button,
.ready-button,
.completed-button,
.in-progress-button {
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancel-button {
  background-color: #ff6b6b;
  color: #ffffff;
}

.cancel-button:hover {
  background-color: #e25454;
}

.ready-button {
  background-color: #ffb200;
  color: #ffffff;
}

.ready-button:hover {
  background-color: #e69500;
}

.completed-button {
  background-color: #42b983;
  color: #ffffff;
}

.completed-button:hover {
  background-color: #369c71;
}

.in-progress-button {
  background-color: #3388ff;
  color: #ffffff;
}

.in-progress-button:hover {
  background-color: #2975e6;
}

#numberSearch {
  width: 100%;
}

.address-popup {
  position: absolute;
  top: 0;
  left: 100%;
  width: 280px;
  padding: 10px;
  background-color: white;
  border: 1px solid #e4c364;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.pending-order-close-button {
  margin-top: 10px;
  padding: 6px 12px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.address-container p {
  font-size: 1rem;
}

#timeFrom {
  margin-bottom: 5px;
  color: #000000;
  text-align: center;
}

#timeTo {
  margin-bottom: 5px;
  color: #000000;
  text-align: center;
}

</style>